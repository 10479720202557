import React from "react";

import {
    Card,
    CardBody,
    Progress,
    Badge
  } from "reactstrap";

const createIndicator = (key, props) => {
    return (
        <div
            key={key}
            style={{
                position: 'absolute',
                left: 'calc('+props.left+' - 2px)',
                marginTop: '10px',
                transform: 'translateY(-50%)',
            }}
        >
            <small 
                className="text-dark"
                style={{
                    position: 'relative',
                    whiteSpace: 'nowrap',
                    left: props.transformX,
                    top: props.transformY,
                }}
            >
                {props.label}
            </small> 
            <div
                style={{
                    position: 'relative',
                    transform: 'translateX(-50%)'
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        borderLeft: 'solid black',
                        height: '20px',
                    }}
                >
                </div>
            </div>
        </div>
    )
}

function round(value, step) {
    step || (step = 1.0);
    var inv = 1.0 / step;
    return Math.round(value * inv) / inv;
}

function currency(val, currency) {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formatter.format(val.toFixed(0));
}

function calcTranslate(value, max) {
  return value < 1 ? '0' : (value >= max ? 'calc(-100% + 2px)' : 'calc(-50% + 1px)')
}

export const PerformanceCard = (props) => {
    const safe_value = Math.min(props.max, props.value);
    const markers = []
    const indicators = []
    if (props.required) indicators.push({
        left: (100*props.required / props.max) + '%',
        transformY: Math.abs((props.required - safe_value) / Math.max(1, props.max)) < 0.1 ? '-15px' : '0px',
        transformX: calcTranslate(props.required, props.max),
        label: 'tour ready',
        val: props.required
    })
    indicators.push({
        left: (100*safe_value / props.max) + '%',
        transformX: calcTranslate(safe_value, props.max),
        label: 'you',
        val: safe_value
    })
    if (props.continuous) {
      for (const ind of indicators) {
        markers.push({
          label: props.currency ? currency(ind.val, props.currency) : ind.val,
          style: {
            position: 'absolute',
            transform: 'translateX('+ind.transformX+')'
          },
          containerStyle: {
            position: 'relative',
            left: ind.left
          }
        })
      }
    }
    else if (props.relative) {
      for (const ind of indicators) {
        markers.push({
          label: parseFloat(ind.left.slice(0, -1)).toFixed(0)+'%',
          style: {
            position: 'absolute',
            transform: 'translateX('+ind.transformX+')'
          },
          containerStyle: {
            position: 'relative',
            left: ind.left
          }
        })
      }
    }
    else {
      for (let i = 0; i <= props.max; i+=parseFloat(props.increment)) {
        markers.push({
            label: i.toString(),
            style: { 
                position: 'absolute', 
                left: (100*i / props.max) + '%',
                transform: i % 1 !== 0 ? 'translateX(-50%) scale(0.75, 0.75)' : 'translateX(-50%)',
                fontWeight: round(safe_value, props.increment) === i || round(props.required, props.increment) === i ? 'bold' : 'normal'
            }
        })
      }
    }
    return (
        <Card className="mt-2 card-profile shadow">
            <CardBody className="" style={{
              overflow: 'hidden'
            }}>
                <div className='d-flex flex-row justify-content-between'>
                  <div className='d-flex flex-row'>
                    <div className='d-flex flex-column'>
                      <span className="h3 text-dark align-middle">{props.title}</span>
                      {props.description && <span className="p text-dark align-middle">{props.description}</span>}
                    </div>
                    { props.experimental ? 
                    <Badge
                        color='info'
                        className='ml-2 mb-auto'
                    >
                        under review
                    </Badge>
                : null }
                  </div>
                  <div className='d-flex flex-row'>
                    <i
                      className="fas fa-md fa-info-circle text-dark float-right"
                      style={{
                          transform: 'translate(0, 3px)'
                      }}
                      onClick={(e) => props.info(e)}
                    />
                  </div>
                </div>
                <div
                    style={{ 
                        position: 'relative',
                        height: '20px',
                        marginLeft: '2px'
                    }}
                >
                    {
                        indicators.map((indicator, i) => createIndicator(i, indicator))
                    }
                </div>
                <Progress className='mt-2' multi>
                  {
                    (props.relative || props.continuous) ? (
                        <>
                          <Progress
                            bar
                            max={props.max}
                            value={safe_value}
                            barClassName={ "bg-purple" }
                          />
                        </>
                    ) : (
                      <>
                        <Progress
                          bar
                          max={props.max}
                          value={safe_value}
                          barClassName={ safe_value >= props.required ? "bg-green" : "bg-orange" }
                        />
                        <Progress
                          bar
                          max={props.max}
                          value={Math.max(0, props.required - safe_value)}
                          barClassName="bg-light"
                        />
                      </> 
                    )
                  }
                </Progress>
                <div
                  style={{ 
                    position: 'relative',
                    height: '5px',
                    marginLeft: '2px',
                    marginRight: '2px'
                  }}
                >
                    {
                        markers.map((marker, i) => 
                          <div key={i} style={marker.containerStyle}>
                            <small 
                                className="text-dark align-middle" 
                                key={i} 
                                style={marker.style}
                              >
                                {marker.label}
                            </small>
                          </div>
                        )
                    }
                </div>
            </CardBody>
        </Card>
    )
}

export default PerformanceCard;