import { authReducer } from './auth/reducers';
import { firestoreReducer } from './firestore/reducers';
import { routerReducer } from 'react-router-redux';

import { combineReducers } from 'redux';

import * as firebase from "firebase/app";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDK3GH1adzoMJhKC-d3bfQ4uo0QbdGZtio",
  authDomain: "tdc-hub.firebaseapp.com",
  databaseURL: "https://tdc-hub.firebaseio.com",
  projectId: "tdc-hub",
  storageBucket: "tdc-hub.appspot.com",
  messagingSenderId: "410762093257",
  appId: "1:410762093257:web:bbc1ed55c4e1582b702318",
  measurementId: "G-R8P99PPGN5"
};
firebase.initializeApp(firebaseConfig);
firebase.firestore().settings({ experimentalAutoDetectLongPolling: true });

const appReducer = combineReducers({
  auth: authReducer,
  routing: routerReducer,
  firestore: firestoreReducer
})

export const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined
  }
  return appReducer(state, action)
}
