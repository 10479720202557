/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import Header from "components/Headers/Header.js";

import { connect } from 'react-redux';
import { updateAthlete } from 'store/firestore/actions';
import UserDetails from "components/UserDetails";

const mapState = state => state;

const mapDispatch = {
  updateAthlete,
};

class AppSettings extends React.Component {

  render() {
    return (
      <>
        <Header title='Profile Settings' />
        {/* Page content */}
        <Container className="" style={{ maxWidth: '1200px' }} fluid>
          <Row>
            <Col xs="12">
              <Card className="mt-2 shadow">
                <CardBody>
                  <UserDetails
                    initialValues={this.props.firestore.athlete}
                    onSave={user_details => this.props.updateAthlete(user_details, false)}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default connect(mapState, mapDispatch)(AppSettings);