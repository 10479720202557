/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Table,
  Modal,
  Label,
  Spinner,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';
import { DateTimePicker } from 'react-widgets';

import { connect } from 'react-redux';
import {
  selectEvent,
  getAllAthletes,
  getEvent,
  updateExternalAthlete,
  getAllRoles,
  updateUserRoles,
  setTeam,
  deleteAthlete,
  updateEventRoles,
  getViewingAthleteFiles,
  downloadAthleteReadinessReport,
} from 'store/firestore/actions';

import {
  access_policies,
  getPoliciesFromRoles,
} from 'store/firestore/reducers';

const mapState = (state) => state;

const mapDispatch = {
  getAllAthletes,
  selectEvent,
  getEvent,
  updateUserRoles,
  updateExternalAthlete,
  getAllRoles,
  setTeam,
  deleteAthlete,
  updateEventRoles,
  getViewingAthleteFiles,
  downloadAthleteReadinessReport,
};

const local_states = [
  'None',
  'ACT',
  'NSW',
  'NT',
  'QLD',
  'SA',
  'TAS',
  'VIC',
  'WA',
  'International',
];

const athleteDisplayName = (athlete) =>
  (athlete.firstname !== '' ? athlete.firstname + ' ' : '') + athlete.lastname;

const filesummary = (fileDict) => {
  const fileArray = Object.values(fileDict || {});
  const summary = {};
  for (const f of fileArray) {
    if (summary[f.fileId]) {
      if (summary[f.fileId].modified_on.toDate() < f.modified_on.toDate()) {
        summary[f.fileId] = f;
      }
    } else {
      summary[f.fileId] = f;
    }
  }
  return Object.values(summary).sort((a, b) =>
    a.modified_on.toDate() < b.modified_on.toDate() ? 1 : -1
  );
};

class ManageAthletes extends React.Component {
  constructor(props) {
    super(props);
    props.getAllAthletes();
    // .then(() => props.selectEvent(props.firestore.selected_event.id));
    props.getAllRoles();
    this.state = {
      filter_toggles: {},
      active_filters: {},
      filters: {},
      edit_effort_contribution_athlete: undefined,
      addFilter: (filter, value) => {
        this.setState({
          active_filters: {
            ...this.state.active_filters,
            [filter]: value,
          },
        });
      },
      removeFilter: (filter) => {
        const new_filters = this.state.active_filters;
        delete new_filters[filter];
        this.setState({
          active_filters: new_filters,
        });
      },
      search_query: '',
      athletes: props.firestore.athletes,
      viewing_athlete: false,
      viewing_roles: false,
      loading_event_roles: {},
      can_edit_athlete_modal:
        this.props.firestore.policies.edit_training_rides ||
        this.props.firestore.policies.edit_skill_and_fitness ||
        this.props.firestore.policies.delete_athlete ||
        this.props.firestore.policies.edit_access_roles,
      can_view_athlete_modal:
        this.props.firestore.policies.edit_training_rides ||
        this.props.firestore.policies.edit_skill_and_fitness ||
        this.props.firestore.policies.delete_athlete ||
        this.props.firestore.policies.edit_access_roles ||
        this.props.firestore.policies.view_next_of_kin,
      athlete_updates: {},
      role_updates: {},
      team_dropdown: false,
      team_column_dropdown: {},
      updateAthlete: (update) => {
        if (update === false) {
          this.setState({
            athlete_updates: {},
            role_updates: {},
            viewing_athlete: false,
          });
        } else {
          this.setState({
            athlete_updates: {
              ...this.state.athlete_updates,
              ...update,
            },
          });
          this.setState({
            viewing_athlete: {
              ...this.state.viewing_athlete,
              ...update,
            },
          });
        }
      },
      checkRole: (role_name) => {
        const roles =
          this.props.firestore.roles[this.state.viewing_athlete.athlete_key];
        if (role_name in this.state.role_updates)
          return this.state.role_updates[role_name];
        else if (roles && role_name in roles) return roles[role_name];
        else return false;
      },
      deleteViewingAthlete: () => {
        this.props.deleteAthlete(this.state.viewing_athlete.athlete_key);
        this.state.updateAthlete(false);
      },
      saveChangesToAthleteAndRoles: () => {
        if (Object.keys(this.state.athlete_updates).length > 0) {
          // check skill_level
          const athlete_updates = this.state.athlete_updates;
          if (
            'skill_level' in athlete_updates &&
            athlete_updates.skill_level !== ''
          ) {
            if (
              !parseInt(athlete_updates.skill_level) &&
              athlete_updates.skill_level !== '0'
            )
              return alert('Skill level must be an integer.');
            if (parseInt(athlete_updates.skill_level) < 0)
              return alert('Skill level must be positive.');
            if (
              parseInt(athlete_updates.skill_level) >
              parseInt(this.props.firestore.app_settings.max_skill_level)
            )
              return alert(
                'Skill level must be less than or equal to ' +
                  this.props.firestore.app_settings.max_skill_level.toString() +
                  '.'
              );
          }
          // check rider_level
          if (
            'rider_level' in athlete_updates &&
            athlete_updates.rider_level !== ''
          ) {
            if (
              !parseInt(athlete_updates.rider_level) &&
              athlete_updates.rider_level !== '0'
            )
              return alert('Fitness level must be an integer.');
            if (parseInt(athlete_updates.rider_level) < 0)
              return alert('Fitness level must be positive.');
            if (
              parseInt(athlete_updates.rider_level) >
              parseInt(this.props.firestore.app_settings.max_fitness_level)
            )
              return alert(
                'Fitness level must be less than or equal to ' +
                  this.props.firestore.app_settings.max_fitness_level.toString() +
                  '.'
              );
          }
          props.updateExternalAthlete(
            this.state.athlete_updates,
            this.state.viewing_athlete.athlete_key
          );
        }
        if (Object.keys(this.state.role_updates).length > 0) {
          props.updateUserRoles(
            this.state.role_updates,
            this.state.viewing_athlete.athlete_key
          );
        }
        this.state.updateAthlete(false);
      },
    };
  }

  static getDerivedStateFromProps(props, state) {
    const team_lookup = {};
    for (const team in props.firestore.selected_event.group_data.teams) {
      for (const member in props.firestore.selected_event.group_data.teams[team]
        .members) {
        team_lookup[member] = {
          id: team,
          name: props.firestore.selected_event.group_data.teams[team].name,
        };
      }
    }
    const levels = [...Array(17).keys()].map((k) => k / 2);
    const teamed_athletes = props.firestore.athletes
      .map((athlete) => {
        const hub_roles = Object.keys(
          props.firestore.roles[athlete.athlete_key] || {}
        ).filter((r) => props.firestore.roles[athlete.athlete_key][r]);
        const predicted_fitness_level =
          Math.round((athlete.predicted_rider_level || 1) * 2) / 2;
        const skill_level = Math.round((athlete.skill_level || 1) * 2) / 2;
        const fitness_level = Math.round((athlete.rider_level || 1) * 2) / 2;
        const average_rider_level = Math.round(skill_level + fitness_level) / 2;
        const number_of_recent_training_rides = parseInt(
          athlete.calcTrainingRides(
            props.firestore.app_settings.training_ride_params.ride_history
          )
        );
        return {
          ...athlete,
          average_rider_level,
          assessed_skill_level: skill_level,
          assessed_fitness_level: fitness_level,
          predicted_fitness_level,
          number_of_recent_training_rides,
          min_average_rider_level: levels.filter(
            (l) => l <= average_rider_level
          ),
          max_average_rider_level: levels.filter(
            (l) => l >= average_rider_level
          ),
          team_id: team_lookup[athlete.athlete_key]?.id,
          team: team_lookup[athlete.athlete_key]?.name || 'Not assigned',
          tour_allocated: team_lookup[athlete.athlete_key] ? 'Yes' : 'No',
          hub_role: hub_roles,
          recently_rode_with_me: athlete.recently_rode_with.includes(
            props.firestore.athlete.athlete_key
          )
            ? 'Yes'
            : 'No',
          connected_to_strava: athlete.access_token
            ? 'Connected'
            : 'Not Connected',
          new_to_hub:
            (new Date().getTime() -
              athlete.athlete_created_on_hub_date.toDate().getTime()) /
              (1000 * 3600 * 24) <
            28
              ? 'Yes'
              : 'No',
          event_role: props.firestore.app_settings.event_roles
            .filter((r) =>
              (
                props.firestore.selected_event.event_roles[
                  athlete.athlete_key
                ] || []
              ).includes(r.id)
            )
            .map((r) => r.text),
        };
      })
      .sort((a, b) =>
        a.firstname.toLowerCase().concat(a.lastname.toLowerCase()) >
        b.firstname.toLowerCase().concat(b.lastname.toLowerCase())
          ? 1
          : -1
      );
    const filterable_metrics = [
      'tour_allocated',
      'event_role',
      'athlete_state',
      'average_rider_level',
      'assessed_fitness_level',
      'assessed_skill_level',
      'new_to_hub',
      'connected_to_strava',
      'training_hub',
      'team',
      'recently_rode_with_me',
      'hub_role',
      'supervisor',
      'admin',
      'number_of_recent_training_rides',
      'first_aid_trained',
    ];
    const filters = {};
    for (const athlete in teamed_athletes) {
      for (const key of filterable_metrics) {
        if (!(key in teamed_athletes[athlete])) continue;
        const val = teamed_athletes[athlete][key];
        if (val === null) continue;
        const transform = (v) => (v?.toString());
        if (key in filters)
          Array.isArray(val)
            ? val.map((v) => filters[key].add(transform(v)))
            : filters[key].add(transform(val));
        else
          filters[key] = Array.isArray(val)
            ? new Set(transform(val))
            : new Set([transform(val)]);
      }
    }

    const init_filters = {};
    for (const filter in filters) {
      init_filters[filter] = {
        values: [...filters[filter]].sort((a, b) => (a > b ? 1 : -1)),
      };
    }

    const str_athletes = teamed_athletes.map((athlete) =>
      JSON.stringify({
        firstname: athlete.firstname,
        lastname: athlete.lastname,
        team: athlete.team,
        admin: athlete.admin,
        training_hub: athlete.training_hub,
        gender: athlete.gender,
        email: athlete.email,
      })
        .replace(/['"]+/g, '')
        .toLowerCase()
    );
    return {
      athletes: teamed_athletes,
      str_athletes: str_athletes,
      filters: init_filters,
    };
  }

  editEventRoles(athlete_key) {
    if (this.props.firestore.policies.edit_event_roles) {
      this.setState({
        viewing_roles: athlete_key,
      });
    }
  }

  getAthleteEventRoles(athlete_key) {
    return this.props.firestore.app_settings.event_roles.map((role) => ({
      ...role,
      enabled: (
        this.props.firestore.selected_event.event_roles[athlete_key] || []
      ).includes(role.id),
    }));
  }

  prettyString(v) {
    const str = v?.toString();
    return str?.charAt(0).toUpperCase() + str?.replace(/_/g, ' ').slice(1);
  }

  querySearch() {
    const terms = this.state.search_query.toLowerCase().split(' ');
    const filters = Object.keys(this.state.active_filters).map((key, i) => {
      return { filter: key, value: this.state.active_filters[key] };
    });
    if (terms.length + filters.length === 0) return this.state.athletes;
    return this.state.athletes
      .filter((athlete, i) =>
        terms
          .map((t) => this.state.str_athletes[i].includes(t))
          .every((result) => result)
      )
      .filter((athlete, i) => {
        const transform = (v) => (typeof v === 'number' ? v : v?.toString());        
        return filters
          .map(
            (f) =>
              // is array
              (Array.isArray(athlete[f.filter]) &&
                athlete[f.filter].includes(transform(f.value))) ||
              // else
              (athlete[f.filter] &&
                athlete[f.filter].toString() === transform(f.value))
          )
          .every((result) => result)
        }
      );
  }

  swapAthleteTeam(athlete, new_team_id) {
    const updates = {};
    if (athlete.team_id) {
      updates[athlete.team_id] = {
        members: {
          [athlete.athlete_key]: firebase.firestore.FieldValue.delete(),
        },
      };
    }
    if (new_team_id) {
      updates[new_team_id] = {
        members: {
          [athlete.athlete_key]: {
            name: athleteDisplayName(athlete),
            profile_image: athlete.profile_medium,
          },
        },
      };
    }
    Object.keys(updates).map((team_id) => {
      return this.props.setTeam(
        this.props.firestore.selected_event.group_id,
        team_id,
        this.props.firestore.selected_event.id,
        updates[team_id]
      );
    });
  }

  render() {
    return (
      <>
        <Header
          title="Manage Athletes"
          dropdown
          selected={this.props.firestore.selected_event.name}
          onSelect={(key) => this.props.selectEvent(key)}
          items={this.props.firestore.events}
          syncAction={() => this.props.getAllAthletes(true)}
        />
        {/* Page content */}
        <Container className="" style={{ maxWidth: '100%' }} fluid>
          <Row>
            <Col xs="12">
              <Card className="mt-2 shadow">
                <InputGroup>
                  <Input
                    className="text-dark"
                    placeholder="Search for athletes"
                    onChange={(e) =>
                      this.setState({
                        search_query: e.target.value,
                      })
                    }
                    value={this.state.search_query}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      {this.querySearch().length} athletes
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Card>
              {Object.keys(this.state.filters).length > 0 ? (
                <Card className="mt-2 pl-4 shadow d-flex flex-row flex-wrap justify-content-start">
                  {Object.keys(this.state.filters).map((filter, i) => (
                    <UncontrolledDropdown
                      key={i}
                      className="mr-auto"
                      isOpen={this.state.filter_toggles[filter]}
                      toggle={() =>
                        this.setState({
                          filter_toggles: {
                            ...this.state.filter_toggles,
                            [filter]: !this.state.filter_toggles[filter],
                          },
                        })
                      }
                    >
                      <DropdownToggle
                        className="btn btn-outline-transparent shadow-none"
                        color="white"
                      >
                        <span className="h6 font-weight-normal text-dark ml--2">
                          {this.prettyString(filter)}
                          <i
                            className="ni ni-bold-down pl-2 mr--2"
                            style={{
                              transform: 'translate(0, 3px)',
                            }}
                          />
                        </span>
                      </DropdownToggle>
                      <DropdownMenu>
                        {this.state.filters[filter].values.map((value, j) => (
                          <DropdownItem
                            key={j}
                            onClick={(e) => this.state.addFilter(filter, value)}
                          >
                            {this.prettyString(value)}
                          </DropdownItem>
                        ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  ))}
                  {['average_rider_level'].map((f) =>
                    [`min_${f}`, `max_${f}`].map((filter, i) => (
                      <UncontrolledDropdown
                        key={i}
                        className="mr-auto"
                        isOpen={this.state.filter_toggles[filter]}
                        toggle={() =>
                          this.setState({
                            filter_toggles: {
                              ...this.state.filter_toggles,
                              [filter]: !this.state.filter_toggles[filter],
                            },
                          })
                        }
                      >
                        <DropdownToggle
                          className="btn btn-outline-transparent shadow-none"
                          color="white"
                        >
                          <span className="h6 font-weight-normal text-dark ml--2">
                            {this.prettyString(filter)}
                            <i
                              className="ni ni-bold-down pl-2 mr--2"
                              style={{
                                transform: 'translate(0, 3px)',
                              }}
                            />
                          </span>
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.filters[f].values.map((value, j) => (
                            <DropdownItem
                              key={j}
                              onClick={(e) =>
                                this.state.addFilter(filter, parseFloat(value))
                              }
                            >
                              {this.prettyString(value)}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    ))
                  )}
                  <div style={{ flexGrow: '2' }}></div>
                </Card>
              ) : null}
              {Object.keys(this.state.active_filters).length > 0 ? (
                <Card className="mt-2 shadow d-flex flex-wrap flex-row justify-content-start p-2">
                  {Object.keys(this.state.active_filters).map((filter, i) => (
                    <Badge
                      key={i}
                      className="user-select-none m-2"
                      color="success"
                      onClick={(e) => this.state.removeFilter(filter)}
                      style={{ userSelect: 'none' }}
                    >
                      {filter + ': ' + this.state.active_filters[filter] + ' '}{' '}
                      <i className="fas fa-times"></i>
                    </Badge>
                  ))}
                </Card>
              ) : null}
              <Card className="mt-2 shadow">
                {this.state.athletes.length < 1 ? (
                  <Spinner color="orange" className="mx-auto my-4" />
                ) : (
                  <div
                    style={{
                      display: 'block',
                      width: '100%',
                      overflowX: 'auto',
                      paddingBottom: '600px',
                      marginBottom: '-600px',
                    }}
                  >
                    <Table className="align-items-center table-flush text-center">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Photo</th>
                          <th scope="col"></th>
                          <th scope="col">Roles</th>
                          <th scope="col">Member</th>
                          {this.props.firestore.policies
                            .view_email_and_mobile ? (
                            <th scope="col">Email</th>
                          ) : null}
                          <th scope="col">Team</th>
                          <th
                            scope="col"
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <Button
                              className="float-right py-1 px-2 shadow-none mb-1"
                              color="white"
                              onClick={() =>
                                this.props.downloadAthleteReadinessReport(
                                  this.querySearch()
                                    .filter((a) => a?.team !== 'Not assigned')
                                    .map((a) => a?.athlete_key)
                                )
                              }
                            >
                              Download filtered
                              <i className="fas fa-download ml-2"></i>
                            </Button>
                            <Button
                              className="float-right py-1 px-2 shadow-none"
                              color="white"
                              onClick={() =>
                                this.props.downloadAthleteReadinessReport(
                                  undefined
                                )
                              }
                            >
                              Download all
                              <i className="fas fa-download ml-2"></i>
                            </Button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.querySearch().map((athlete, i) => (
                          <tr key={i}>
                            <td>
                              <img
                                src={athlete.profile_medium}
                                alt="Invalid logo"
                                style={{
                                  height: '30px',
                                  width: '30px',
                                  borderRadius: '15px',
                                }}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = require('assets/img/strava/user.png');
                                }}
                                className="mb-auto"
                              />
                            </td>
                            <td></td>
                            <td
                              className="d-flex text-dark"
                              onClick={() =>
                                this.editEventRoles(athlete.athlete_key)
                              }
                            >
                              <div
                                style={{
                                  minWidth: '100%',
                                  height: '50px',
                                  maxWidth: '120px',
                                  overflow: 'hidden',
                                }}
                                className="d-flex justify-content-center bg-secondary rounded align-items-center p-2"
                              >
                                {(
                                  this.props.firestore.roles[
                                    athlete.athlete_key
                                  ] || {}
                                ).supervisor && (
                                  <i className="fas fa-binoculars fa-lg text-dark mx-1" />
                                )}
                                {this.getAthleteEventRoles(athlete.athlete_key)
                                  .filter((r) => r.enabled)
                                  .sort((a, b) => a.icon - b.icon)
                                  .map((role, i) => (
                                    <i
                                      key={i}
                                      className={
                                        role.icon + role.color + ' fa-lg mx-1'
                                      }
                                    />
                                  ))}
                              </div>
                            </td>
                            <td className="text-dark">
                              {athleteDisplayName(athlete)}
                            </td>
                            {this.props.firestore.policies
                              .view_email_and_mobile ? (
                              <td className="text-dark">{athlete.email}</td>
                            ) : null}
                            <td>
                              {this.props.firestore.policies.edit_teams ? (
                                <UncontrolledDropdown
                                  isOpen={
                                    this.state.team_column_dropdown[
                                      athlete.athlete_key
                                    ] === true
                                  }
                                  toggle={() =>
                                    this.setState({
                                      team_column_dropdown: {
                                        ...this.state.team_column_dropdown,
                                        [athlete.athlete_key]:
                                          !this.state.team_column_dropdown[
                                            athlete.athlete_key
                                          ],
                                      },
                                    })
                                  }
                                >
                                  <DropdownToggle
                                    className="btn btn-outline-transparent shadow-none float-right"
                                    color="white"
                                  >
                                    <span className="font-weight-normal text-dark ml--2">
                                      {athlete.loading ? '...' : athlete.team}
                                      {/* <i
                                          className="ni ni-bold-down pl-2 mr--2"
                                          style={{ transform: 'translate(0, 3px)' }}
                                        /> */}
                                    </span>
                                  </DropdownToggle>
                                  <DropdownMenu
                                    style={{
                                      overflowY: 'visible',
                                    }}
                                  >
                                    {Object.keys(
                                      this.props.firestore.selected_event
                                        .group_data.teams || {}
                                    )
                                      .filter(
                                        (team_id) =>
                                          this.props.firestore.selected_event
                                            .group_data.teams[team_id]
                                            .deleted !== true
                                      )
                                      .map((team_id, i) => (
                                        <DropdownItem
                                          key={i}
                                          onClick={(e) =>
                                            this.swapAthleteTeam(
                                              athlete,
                                              team_id
                                            )
                                          }
                                        >
                                          {
                                            this.props.firestore.selected_event
                                              .group_data.teams[team_id].name
                                          }
                                        </DropdownItem>
                                      ))}
                                    <DropdownItem
                                      onClick={(e) =>
                                        this.swapAthleteTeam(athlete, false)
                                      }
                                    >
                                      {'Not assigned'}
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              ) : (
                                athlete.team
                              )}
                            </td>
                            <td>
                              <Button
                                className="btn-transparent float-right"
                                color="white"
                                onClick={(e) => {
                                  this.setState({
                                    viewing_athlete: athlete,
                                  });
                                  this.props.getViewingAthleteFiles(
                                    athlete.athlete_key
                                  );
                                }}
                              >
                                View
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal isOpen={this.state.viewing_roles !== false}>
          <Card>
            <CardHeader>
              <h2 className="text-dark mb-0">Edit event roles</h2>
            </CardHeader>
            <CardBody className="ml-4">
              {this.getAthleteEventRoles(this.state.viewing_roles).map(
                (role, i) => (
                  <Row key={i}>
                    <Col>
                      <Label check className="my-2 text-dark">
                        <i
                          className={role.icon + role.color + ' fa-lg mr-2'}
                        ></i>
                        {role.text}
                      </Label>
                      <Button
                        className="float-right shadow-none"
                        color="white"
                        onClick={(e) => {
                          this.setState({
                            loading_event_roles: {
                              ...this.state.loading_event_roles,
                              [role.id]: true,
                            },
                          });
                          this.props
                            .updateEventRoles(
                              this.state.viewing_roles,
                              this.props.firestore.selected_event.id,
                              role.id,
                              !role.enabled
                            )
                            .then((e) =>
                              this.setState({
                                loading_event_roles: {
                                  ...this.state.loading_event_roles,
                                  [role.id]: false,
                                },
                              })
                            );
                        }}
                      >
                        {this.state.loading_event_roles[role.id] ? (
                          <Spinner color="dark" size="sm" />
                        ) : (
                          <i
                            className={
                              'far fa-lg ' +
                              (role.enabled ? 'fa-check-square' : 'fa-square')
                            }
                          />
                        )}
                      </Button>
                    </Col>
                  </Row>
                )
              )}
            </CardBody>
            <Button onClick={() => this.setState({ viewing_roles: false })}>
              Done
            </Button>
          </Card>
        </Modal>
        <Modal
          isOpen={this.state.viewing_athlete !== false}
          style={{ maxWidth: 'min(100vw, 1000px)' }}
        >
          <Card style={{ width: '100%' }}>
            <CardHeader>
              <Row>
                <Col>
                  <div className="d-flex flex-row">
                    <Input
                      style={{ fontSize: '24px' }}
                      className="text-dark mr-2"
                      placeholder="First Name"
                      onChange={(e) =>
                        this.state.updateAthlete({
                          firstname: e.target.value,
                        })
                      }
                      value={this.state.viewing_athlete.firstname}
                    />
                    <Input
                      style={{ fontSize: '24px' }}
                      className="text-dark"
                      placeholder="Last Name"
                      onChange={(e) =>
                        this.state.updateAthlete({
                          lastname: e.target.value,
                        })
                      }
                      value={this.state.viewing_athlete.lastname}
                    />
                  </div>
                </Col>
                <Col>
                  <img
                    className="float-right"
                    src={this.state.viewing_athlete.profile_medium}
                    alt=""
                    style={{
                      height: '30px',
                      width: '30px',
                      borderRadius: '15px',
                    }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = require('assets/img/strava/user.png');
                    }}
                  />
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <h3 className="text-dark">Self-assessed skill level (sign up)</h3>
              <Input
                className="text-dark"
                placeholder=""
                value={
                  this.state.viewing_athlete.self_nominated_rider_level || ''
                }
                readOnly={true}
              />
              <h3 className="text-dark mt-2"> TdC assessed skill level </h3>
              <Input
                className="text-dark"
                placeholder="1"
                value={this.state.viewing_athlete.skill_level || ''}
                onChange={(e) =>
                  this.state.updateAthlete({
                    skill_level: e.target.value,
                  })
                }
                readOnly={!this.props.firestore.policies.edit_skill_and_fitness}
              ></Input>
              <h3 className="text-dark mt-2"> TdC assessed fitness Level </h3>
              <Input
                className="text-dark"
                placeholder="1"
                value={this.state.viewing_athlete.rider_level || ''}
                onChange={(e) =>
                  this.state.updateAthlete({
                    rider_level: e.target.value,
                  })
                }
                readOnly={!this.props.firestore.policies.edit_skill_and_fitness}
              ></Input>
              <h3 className="text-dark mt-2"> Grassrootz Athlete Id </h3>
              <Input
                className="text-dark"
                placeholder=""
                value={this.state.viewing_athlete.grassrootz_athlete_id || ''}
                onChange={(e) =>
                  this.state.updateAthlete({
                    grassrootz_athlete_id: e.target.value,
                  })
                }
              />
              <h3 className="text-dark mt-2">
                Predicted fitness level (Strava)
              </h3>
              <Input
                className="text-dark"
                placeholder=""
                value={this.state.viewing_athlete.predicted_fitness_level || ''}
                readOnly={true}
              />
              <h3 className="text-dark mt-2">
                Recent TDC Approved Training Rides
              </h3>
              <Input
                className="text-dark"
                placeholder="0"
                value={
                  this.state.viewing_athlete
                    ? this.state.viewing_athlete.calcTrainingRides(
                        this.props.firestore.app_settings.training_ride_params
                          .ride_history
                      )
                    : ''
                }
                readOnly={true}
              />
              <h3 className="text-dark mt-2">State</h3>
              <UncontrolledDropdown>
                <DropdownToggle caret tag="span" className="text-dark">
                  {this.state.viewing_athlete.athlete_state || 'Not specified'}
                </DropdownToggle>
                <DropdownMenu>
                  {local_states.map((value, j) => (
                    <DropdownItem
                      key={j}
                      onClick={(e) =>
                        this.state.updateAthlete({
                          athlete_state: value,
                        })
                      }
                    >
                      {this.prettyString(value)}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
              <h3 className="mt-2 text-dark"> Recent training rides </h3>
              {this.state.viewing_athlete
                ? this.state.viewing_athlete
                    .listTrainingRides(
                      this.props.firestore.app_settings.training_ride_params
                        .ride_history
                    )
                    .map((ride_date, i) => (
                      <p key={i} className="mb-1 ml-2 text-dark">
                        {ride_date}
                      </p>
                    ))
                : null}
              {this.props.firestore.policies.edit_training_rides ? (
                <>
                  <div className="d-flex flex-row w-100">
                    <DateTimePicker
                      style={{ height: '40px' }}
                      className="float-left"
                      value={this.state.new_training_ride_date}
                      onChange={(val) =>
                        this.setState({
                          new_training_ride_date: val,
                        })
                      }
                    />
                    <Button
                      style={{ height: '40px' }}
                      className="ml-2 float-right"
                      color="success"
                      onClick={() => {
                        const new_date = new Date(
                          this.state.new_training_ride_date.getTime() -
                            this.state.new_training_ride_date.getTimezoneOffset() *
                              60000
                        )
                          .toISOString()
                          .split('T')[0];
                        if (
                          this.state.viewing_athlete
                            .listTrainingRides(
                              this.props.firestore.app_settings
                                .training_ride_params.ride_history
                            )
                            .indexOf(new_date) === -1
                        )
                          this.state.updateAthlete({
                            manual_training_rides: [
                              ...this.state.viewing_athlete
                                .manual_training_rides,
                              new_date,
                            ],
                          });
                      }}
                    >
                      Add
                    </Button>
                  </div>
                  <div className="d-flex flex-row w-100">
                    <Label className="ml-4 text-dark" check>
                      <Input
                        type="checkbox"
                        color="danger"
                        checked={
                          this.state.viewing_athlete.training_ride_exempt
                        }
                        onChange={(e) => {
                          this.state.updateAthlete({
                            training_ride_exempt: e.target.checked,
                          });
                        }}
                      />
                      Training ride exempt
                    </Label>
                  </div>
                </>
              ) : null}
              {this.props.firestore.policies.view_email_and_mobile && (
                <>
                  <h3 className="text-dark mt-2"> Mobile </h3>
                  <p className="text-dark">
                    {this.state.viewing_athlete.mobile}
                  </p>
                </>
              )}
              {this.props.firestore.policies.view_next_of_kin && (
                <>
                  <h3 className="text-dark mt-2"> Next of Kin </h3>
                  <div className="d-flex flex-row justify-content-between">
                    <p className="text-dark">
                      {this.state.viewing_athlete.nok_firstname +
                        ' ' +
                        this.state.viewing_athlete.nok_lastname}
                    </p>
                    <p className="text-dark">
                      {this.state.viewing_athlete.nok_mobile}
                    </p>
                  </div>
                </>
              )}
              {this.props.firestore.policies.view_athlete_files && (
                <>
                  <h3 className="text-dark mt-2"> Uploaded Files </h3>
                  {Object.keys(this.props.firestore.viewing_athlete_files || {})
                    .length === 0 ? (
                    <Card className="card text-dark mt-1 border-0">
                      No files uploaded
                    </Card>
                  ) : (
                    filesummary(this.props.firestore.viewing_athlete_files).map(
                      (f) => (
                        <Card className="card text-dark mt-1 border-0">
                          <div className="d-flex flex-row justify-content-between">
                            {f?.fileTitle}
                            <h4 className="text-dark">
                              {f?.modified_on.toDate().toLocaleString()}
                            </h4>
                          </div>
                        </Card>
                      )
                    )
                  )}
                </>
              )}
              {this.props.firestore.policies.edit_access_roles ? (
                <>
                  <Card className="border-danger text-dark mt-3 p-2">
                    <h3 className="mt-2 text-dark"> TDC Hub Access </h3>
                    <p>
                      Please note that this access applies accross all events
                      and is not exclusive to this event.
                    </p>
                    {Object.keys(access_policies).map((k) => (
                      <>
                        <Label className="ml-4" check key={k}>
                          <Input
                            type="checkbox"
                            color="danger"
                            checked={this.state.checkRole(k)}
                            onChange={(e) => {
                              this.setState({
                                role_updates: {
                                  ...this.state.role_updates,
                                  [k]: e.target.checked,
                                },
                              });
                            }}
                          />{' '}
                          {k}
                        </Label>
                      </>
                    ))}
                    <h3 className="mt-4 text-dark"> Access </h3>
                    <ul style={{ marginBottom: 0 }}>
                      {Object.keys(
                        getPoliciesFromRoles({
                          ...this.props.firestore.roles[
                            this.state.viewing_athlete.athlete_key
                          ],
                          ...this.state.role_updates,
                        })
                      )
                        .sort()
                        .map((p) => (
                          <li key={p}>{p}</li>
                        ))}
                    </ul>
                    {this.props.firestore.policies.delete_athlete ? (
                      <Button
                        className="btn-outline-danger ml-auto shadow-none"
                        onClick={(e) =>
                          window.confirm(
                            'Are you sure you want to permanently delete ' +
                              athleteDisplayName(this.state.viewing_athlete) +
                              ' (' +
                              (this.state.viewing_athlete.email || 'no email') +
                              ') ?'
                          )
                            ? this.state.deleteViewingAthlete()
                            : null
                        }
                        color="danger"
                      >
                        Delete athlete
                      </Button>
                    ) : null}
                  </Card>
                </>
              ) : null}
            </CardBody>
            <CardFooter>
              <Button onClick={(e) => this.state.updateAthlete(false)}>
                Cancel
              </Button>
              <Button
                className="float-right"
                onClick={(e) => this.state.saveChangesToAthleteAndRoles()}
                color="success"
              >
                Save
              </Button>
            </CardFooter>
          </Card>
        </Modal>
      </>
    );
  }
}

export default connect(mapState, mapDispatch)(ManageAthletes);
