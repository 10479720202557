import React from 'react';

import {
  Dropdown as DefaultDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

const Dropdown = (props) => {
  const [open, setOpen] = React.useState(false);
  return (
    <DefaultDropdown
      className="mr-auto"
      isOpen={open} 
      toggle={() => setOpen(!open)} 
    >
      <DropdownToggle
        ref={props.toggleRef}
        className="btn btn-outline-transparent shadow-none"
        color="white"
      >
        { props.value }
        <i
          className="ni ni-bold-down pl-2 mr--2"
          style={{ transform: 'translate(0, 3px)' }}
        />
      </DropdownToggle>
      <DropdownMenu
        style={{ minWidth: '1px'}}
      >
        {
          props.list.map((value, j) => (
            <DropdownItem 
              key={j} 
              onClick={e => props.onClick(value)} 
              className='d-flex pr-4'
            >
              { props.labels ? props.labels[j] : value }
            </DropdownItem>
          ))
        }
      </DropdownMenu>
    </DefaultDropdown>
  )
}

export default Dropdown;