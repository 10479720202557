/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

import { connect } from 'react-redux';
import { register } from 'store/auth/actions';
import { updateAthlete } from 'store/firestore/actions';
import UserDetails from "components/UserDetails";

const mapState = state => state;

const mapDispatch = {
  updateAthlete
};

class Register extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: ''
    };
  }

  handleEmailChange(e) {
    e.preventDefault();
    this.setState({ email: e.target.value });
  }

  handlePasswordChange(e) {
    e.preventDefault();
    this.setState({ password: e.target.value });
  }

  handlePrivacyPolicy(e) {
    this.setState({ privacy: e.target.checked });
  }

  createAccount(user_details) {
    return register(this.state.email, this.state.password)
    .then(
      this.props.updateAthlete(user_details)
    );
  }

  render() {
    return (
      <>
        <Col xs="10">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <h2 className='text-dark'>
                  Register to Tour de Cure Hub
                </h2>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className='text-dark' 
                      placeholder="Email" 
                      type="email" 
                      autoComplete="new-email" 
                      value={this.state.email} 
                      onChange={e => this.handleEmailChange(e)}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className='text-dark' 
                      placeholder="Password" 
                      type="password"
                      autoComplete="new-password" 
                      value={this.state.password} 
                      onChange={e => this.handlePasswordChange(e)}
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
              <UserDetails 
                onSave={(user_details) => this.createAccount(user_details) }
              />
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col className="text-right" xs="12">
              <Button
                className="text-light bg-transparent p-0"
                color="transparent"
                styles={{ color: 'green' }}
                onClick={e => this.props.history.push('/auth/login') }
              >
                <small>Already have an account?</small>
              </Button>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export default connect(mapState, mapDispatch)(Register);
