import React from 'react';

import {
    Button,
    ListGroup,
    ListGroupItem,
    Alert
} from "reactstrap";

export const SettingsList = ({ children, ...props }) => {
    const [open, setOpen] = React.useState(false);
    return (
      <>
        <h4 className='text-dark float-left mt-3'>{ props.title }</h4>
        <Button
          className='btn btn-outline-transparent float-right'
          color='white'
          onClick={ (e) => setOpen(!open) }
        >
          {open ? 'Hide' : 'Show'}
        </Button>
        {
          open && (
            <>
              {
                props.alert && 
                <Alert className='mt-6 mb--5' color='warning' fade={false} >
                  {props.alert}
                </Alert>
              }
              { props.list.length 
                ? <ListGroup 
                    className='mt-6'
                  >
                    {
                      props.list.sort().map((item, i) => (
                        <ListGroupItem key={i} className='text-dark'>
                          {item}
                          <Button
                            className='float-right shadow-none p-0 btn-transparent'
                            color='white'
                            onClick={ () => props.remove(i) }
                          >
                            <i className="fas fa-trash"></i>
                          </Button>
                        </ListGroupItem>
                      ))
                    }
                  </ListGroup>
                : <ListGroup className='mt-6'>
                    <ListGroupItem>No items</ListGroupItem>
                  </ListGroup>
              }
              <div className='d-flex flex-row mt-3'>
                {children}
              </div>
            </>
          )
        }
      </>
    )
  }