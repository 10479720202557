import React, { useState } from 'react';

import {
    Button,
    ListGroup,
    ListGroupItem
} from "reactstrap";

import { FileUploader } from 'components/FileUploader.js'

export const MultiFileUploader = (props) => {

    const [add, setAdd] = useState(null);
    const [show, setShow] = useState(false);

    const generateFileId = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const newFile = (
      <FileUploader
          isNew={true}
          fileInfo={{}}
          onChange={(newFileInfo) => {
              props.onChange({ ...props.files, [generateFileId(12)]: newFileInfo });
              setAdd(null);
          }}
          onDiscard={() => setAdd(null)}
      />
    )

    return (
        <>
          <div className='d-flex flex-column'>
            <div>
              <h4 className='text-dark float-left mt-3'>{props.title}</h4>
              <Button
                className='float-right'
                color='white'
                onClick={() => setShow(!show)}
              >
                { show ? 'Hide' : 'Show' }
              </Button>
            </div>
            {
              show && ( 
                <>
                  <Button
                    className='mt-2 p-2 shadow-none ml-auto'
                    color='white'
                    onClick={() => setAdd(newFile)}
                  >
                    <i className="fas fa-plus fa-lg"/>
                  </Button>
                  <ListGroup className='mt-2'>
                    {
                      add && (
                        <ListGroupItem className='p-0'>
                          { add }
                        </ListGroupItem>
                      )
                    }
                    { 
                      Object.keys(props.files)
                      .filter(key => !props.files[key].deleted)
                      .sort((a, b) => {
                        if (!(props.files[b].modified_on && typeof props.files[a].modified_on === 'function')) return 1;
                        else if (!(props.files[a].modified_on && typeof props.files[a].modified_on === 'function')) return -1;
                        else return props.files[a].modified_on.toDate() - props.files[b].modified_on.toDate()
                      })
                      .map((key) =>
                        <ListGroupItem key={key} className='p-0'>
                          <FileUploader
                            fileInfo={props.files[key]}
                            onChange={(newFileInfo) => props.onChange({ ...props.files, [key]: newFileInfo })}
                          />
                        </ListGroupItem> 
                      )
                    }
                  </ListGroup>
                </>
              )
            } 
          </div>
        </>
    )
}