/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import SignUpLayout from "layouts/SignUp.js";

import * as firebase from "firebase/app";
import "firebase/auth";

import { connect } from 'react-redux';
import { getAppSettings, getAthlete } from 'store/firestore/actions';

const mapState = state => state;

const mapDispatch = { 
  getAppSettings,
  getAthlete
};

class AuthController extends React.Component {

  constructor(props) {
    super(props);
    this.state = { authed: false, signUpComplete: false, loading: true };
    firebase
    .auth()
    .onAuthStateChanged((user) => {
      if (user) {
        props.getAppSettings();
        props.getAthlete();

        if (!user.emailVerified) {
          this.setState({ authed: true, signUpComplete: false, loading: false });        
        } else {
          this.setState({ authed: true, signUpComplete: true, loading: false });
        }
      } else {
        this.setState({ authed: false, signUpComplete: false, loading: false });
      }
    });
  }

  render() {
      return ( 
      this.state.loading ? null :
      <BrowserRouter>
        <Switch>
          {
            this.state.authed ? 
              this.state.signUpComplete ?
              <Route path="/admin" render={props => <AdminLayout {...props} />} />            
              : <Route path="/signup" render={props => <SignUpLayout {...props} />} />            
            : <Route path="/auth" render={props => <AuthLayout {...props} />} />
          }
          {
            this.state.authed ? 
              this.state.signUpComplete ? 
                <Route
                  path="/"
                  component={({ location }) => (
                    <Redirect
                      to={{
                        ...location,
                        pathname: '/admin/index',
                      }}
                    />
                  )}
                />
              : <Redirect from="/" to="/signup/emailverify" />
            : <Redirect from="/" to="/auth/login" />
          }
        </Switch>
      </BrowserRouter>
     )
   }
}

export default connect(mapState, mapDispatch)(AuthController);
