/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
 } from "reactstrap";

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      toggle: () => this.setState({ isOpen: !this.state.isOpen })
    }
  }

  render() {
    return (
      <>
        <div className="header bg-gradient-warning pb-5 pt-5">
          <Container style={{ maxWidth: '100%' }} fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col className="pl-4 py-0" xs="12" lg="6">
                    <h1
                      className="text-white"
                    >
                      {this.props.title}
                    </h1>
                </Col>
                { !this.props.dropdown ? null :
                  <Col className="d-flex justify-content-start justify-content-lg-end w-100" xs="12" lg="6">
                    { !this.props.syncAction ? null :
                      <Button
                        style={{ height: '43px' }}
                        className="text-dark mx-2 shadow-none"
                        color="white"
                        onClick={ e => this.props.syncAction() }
                      >
                        <i className='fa fa-sync-alt fa-lg'/>
                      </Button>
                    }
                    <UncontrolledDropdown isOpen={this.state.isOpen} toggle={this.state.toggle}>
                      <DropdownToggle
                        className="btn btn-outline-transparent shadow-none"
                        color="white"
                      >
                        <span className="font-weight-normal text-dark ml--2">
                          {this.props.selected}
                          <i
                            className="ni ni-bold-down pl-2 mr--2"
                            style={{ transform: 'translate(0, 3px)' }}
                          />
                        </span>
                      </DropdownToggle>
                      <DropdownMenu>
                        {
                          Object.keys(this.props.items).map((key, i) => (
                            <DropdownItem key={i} onClick={e => this.props.onSelect(key)} >{this.props.items[key].name}</DropdownItem>
                          ))
                        }
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Col>
                }
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Header;
