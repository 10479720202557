import strava from 'strava-v3';

let uri = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
// strava.config({
//   'client_id': '44287',
//   'client_secret': '0e8adaeaa7fd0838846ee1d1edeb039dbfee2cc5',
//   'redirect_uri': uri
// });
strava.config({
  'client_id': '44287',
  'client_secret': '68fe67a321d38895c5d4c7b127255eea6dd1df89',
  'redirect_uri': uri
});

export function authStrava() {
    let link = strava.oauth.getRequestAccessURL({ scope: 'activity:read_all' });
    window.location.href = link.toString();
    return { type: null };
}

export function getTokens(code, scope, callback) {
  return strava
  .oauth
  .getToken(code)
  .then((response) => {
    return {
      ...response.athlete,
      access_token: response.access_token,
      refresh_token: response.refresh_token,
      expires_at: response.expires_at,
      scope: scope
    }
  })
  .then(data => callback(data))
  .catch(err => alert('Strava connection failed, please try again.'));
}
