import React from 'react';

import { FileUploaderUser } from 'components/FileUploaderUser.js';

export const MultiFileUploaderUserDocs = (props) => {
  if (Object.keys(props.files).length === 0)
    return (
      <h4 className="text-dark">
        Documents you are required to upload will be displayed here.
      </h4>
    );
  const ids = {};
  for (const [i, f] of Object.entries(props.files).filter(
    ([k, v]) => !v.deleted
  )) {
    ids[f.fileId] = i;
  }
  return (
    <>
      {Object.values(ids).map((key) => (
        <FileUploaderUser
          key={key}
          event={props.event}
          athlete={props.athlete}
          fileInfo={props.files[key]}
          addFile={props.addFile}
          updateFile={props.updateFile}
        />
      ))}
    </>
  );
};
