/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useRef } from 'react';
import * as firebase from 'firebase/app';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  Input,
  Form,
  FormGroup,
  FormText,
  Label,
  Spinner,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ButtonGroup,
  CustomInput,
} from 'reactstrap';
import Dropdown from 'components/Dropdown';
import parsePhoneNumber from 'libphonenumber-js';
import { changeEmailAddress } from 'store/auth/actions';
const AttrInput = (props) => {
  const dropdown = useRef(null);
  const format = (val) => {
    if (props.mobile) {
      const numb = val.replace(/\D/g, '');
      if (numb.length > 8) {
        return parsePhoneNumber(val, 'AU')?.formatInternational();
      } else {
        return val.replace(/\D/g, '');
      }
    } else {
      return val;
    }
  };
  return (
    <FormGroup>
      {props.label ? <Label for={props.attribute}>{props.label}</Label> : null}
      <InputGroup className="input-group-alternative">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>{props.title}</InputGroupText>
        </InputGroupAddon>
        <Input
          placeholder={props.placeholder}
          className={'text-dark'}
          autoComplete={props.attribute}
          type={props.type}
          name={props.attribute}
          id={props.attribute}
          value={
            props.emptyState &&
            props.parent.state[props.attribute] === props.emptyState
              ? null
              : props.parent.state[props.attribute]
          }
          onChange={(e) =>
            props.parent.setState({
              [props.attribute]: format(e.target.value),
            })
          }
          onClick={(e) => props.dropdown && dropdown.current.onClick()}
          required={props.required}
          pattern={props.pattern}
          readOnly={props.readOnly}
          style={
            props.readOnly
              ? {
                  backgroundColor: 'white',
                }
              : {}
          }
        />
        {props.dropdown && (
          <Dropdown
            toggleRef={dropdown}
            list={props.dropdownOptions}
            onClick={(value) =>
              props.parent.setState({
                [props.attribute]: format(value),
              })
            }
          />
        )}
      </InputGroup>
    </FormGroup>
  );
};

const rider_levels = {
  1: [
    'Learning to or can clip in/out',
    'Braking & steering skills',
    'Gear changing',
  ],
  2: [
    'Can ride in all handlebar positions (hoods, flats, drops)',
    'More effective gear changing',
    'Multi riding positions',
    'Ride in close proximity to others (2 up, 4 up, 6 up – mini group)',
    'Singles / doubles movement',
    'Turning & lane changing',
    'Calling obstacles & peloton movement',
  ],
  3: [
    'Effective cadence',
    'Descending, incl. drops riding position',
    'Descends in the drops',
    'Comfortable riding side by-side in a peloton',
    'Spacing control within a peloton',
    'Efficient peloton dynamics',
    'Descending in a peloton',
  ],
  4: [
    'Descending techniques',
    'Eating & drinking while riding',
    'Gear changing according to terrain',
    'Rollers vs climbs',
    'Gears vs gradient vs speed',
    'Climbing as a peloton',
    'Effective anticipation & movement',
  ],
  5: [
    'Confident descending technique',
    'Comfortable handling in all road & weather conditions',
    'Comfortable in a peloton in all riding conditions',
    'Constant fine tuning of peloton dynamics',
  ],
  6: [
    'Higher level of bike handling in all road & weather conditions',
    'Finer skills of ‘engine room’',
    'Finer skills of mid peloton dynamics',
    'Shepherd / mentor riders',
  ],
};

class UserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // removed grassrootz id
      requested_email: '',
      user_types: props.initialValues?.user_types || {},
      email: props.initialValues?.email,
      lastname: props.initialValues?.lastname || '',
      firstname: props.initialValues?.firstname || '',
      mobile: props.initialValues?.mobile || '',
      nok_firstname: props.initialValues?.nok_firstname || '',
      nok_lastname: props.initialValues?.nok_lastname || '',
      nok_mobile: props.initialValues?.nok_mobile || '',
      gender: props.initialValues?.gender || '',
      athlete_state: props.initialValues?.athlete_state || '',
      self_nominated_rider_level:
        props.initialValues?.self_nominated_rider_level || 1,
      ridden_in_peloton: props.initialValues?.ridden_in_peloton || false,
    };
  }

  toggleUserState(type) {
    this.setState({
      user_types: {
        ...this.state.user_types,
        [type]: !this.state.user_types[type],
      },
    });
  }

  saveDetails() {
    if (this.state.firstname === '')
      return alert('Please enter your first name.');
    if (this.state.lastname === '')
      return alert('Please enter your last name.');
    if (this.state.nok_firstname === '')
      return alert('Please enter a first name for your next of kin.');
    if (this.state.nok_lastname === '')
      return alert('Please enter a last name for your next of kin.');
    if (this.state.gender === '') return alert('Please select a gender.');
    if (
      Object.keys(this.state.user_types).filter((t) => this.state.user_types[t])
        .length === 0
    )
      return alert('Please select a participation status.');
    if ([15].indexOf(this.state.mobile.length) === -1)
      return alert('Please enter a valid mobile number.');
    if ([15].indexOf(this.state.nok_mobile.length) === -1)
      return alert('Please enter a valid mobile number for your next of kin.');
    this.setState({ saving: true });
    return this.props
      .onSave({
        ...this.state,
      })
      .then(() => this.setState({ saving: false }));
    // this.props.onSave();
  }

  render() {
    return (
      <Form role="form">
        <h2 className="text-dark">Your Details</h2>
        <Row>
          <Col xs={12} md={6}>
            <AttrInput
              parent={this}
              label="First Name"
              title={<i className="fas fa-user" />}
              placeholder="First Name"
              type="text"
              attribute="firstname"
            />
          </Col>
          <Col xs={12} md={6}>
            <AttrInput
              parent={this}
              label="Last Name"
              title={<i className="fas fa-user" />}
              placeholder="Last Name"
              type="text"
              attribute="lastname"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <AttrInput
              parent={this}
              label="Mobile"
              title={<i className="fas fa-phone" />}
              placeholder="Mobile"
              type="text"
              mobile
              attribute="mobile"
            />
          </Col>
          <Col xs={12} md={6}>
            <AttrInput
              parent={this}
              label="Gender"
              title={<i className="fas fa-tshirt" />}
              placeholder="Gender"
              type="text"
              attribute="gender"
              readOnly
              dropdown
              dropdownOptions={['Male', 'Female', 'Other']}
            />
          </Col>
          <Col xs={12} md={6}>
            <AttrInput
              parent={this}
              label="State"
              title={<i className="fas fa-globe" />}
              placeholder="State"
              type="text"
              attribute="athlete_state"
              readOnly
              dropdown
              emptyState="None"
              dropdownOptions={[
                'ACT',
                'NSW',
                'NT',
                'QLD',
                'SA',
                'TAS',
                'VIC',
                'WA',
                'International',
              ]}
            />
          </Col>
          <Col xs={12} md={6}>
            <AttrInput
              parent={this}
              label="First aid trained"
              title={<i class="fas fa-briefcase-medical" />}
              placeholder="First aid trained"
              type="text"
              attribute="first_aid_trained"
              dropdown
              emptyState=""
              dropdownOptions={['Yes', 'No']}
            />
          </Col>
        </Row>
        <h2 className="text-dark">Next of Kin Details</h2>
        <Row>
          <Col xs={12} md={6}>
            <AttrInput
              parent={this}
              label="Next of Kin First Name"
              placeholder="Next of Kin First Name"
              title={<i className="fas fa-user" />}
              type="text"
              attribute="nok_firstname"
            />
          </Col>
          <Col xs={12} md={6}>
            <AttrInput
              parent={this}
              label="Next of Kin Last Name"
              placeholder="Next of Kin Last Name"
              title={<i className="fas fa-user" />}
              type="text"
              attribute="nok_lastname"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <AttrInput
              parent={this}
              title={<i className="fas fa-phone" />}
              label="Next of Kin Mobile"
              placeholder="Next of Kin Mobile"
              type="text"
              mobile
              attribute="nok_mobile"
            />
          </Col>
          <Col xs={12}>
            <Card className="bg-secondary shadow border-0 mb-4">
              <CardBody>
                <h2 className="mb-2 text-dark">
                  Select your participation status with Tour de Cure
                </h2>
                <p className="ml-1 mb-4 text-dark">
                  Please select all applicable options (can be more than one)
                </p>
                <div className="d-flex justify-content-center">
                  <ButtonGroup vertical={window.innerWidth < 800}>
                    <Button
                      active={this.state.user_types.rider}
                      color="white"
                      onClick={(e) => this.toggleUserState('rider')}
                    >
                      Rider
                    </Button>
                    <Button
                      active={this.state.user_types.walker}
                      color="white"
                      onClick={(e) => this.toggleUserState('walker')}
                    >
                      Walker
                    </Button>
                    <Button
                      active={this.state.user_types.runner}
                      color="white"
                      onClick={(e) => this.toggleUserState('runner')}
                    >
                      Runner
                    </Button>
                    <Button
                      active={this.state.user_types.support}
                      color="white"
                      onClick={(e) => this.toggleUserState('support')}
                    >
                      Support
                    </Button>
                  </ButtonGroup>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {this.state.user_types.rider && (
          <>
            <Row className="my-4">
              <Col xs={12}>
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <Row className="d-flex align-items-center">
                      <Col xs={12}>
                        <FormGroup>
                          <h2 className="text-dark">
                            Self-assessed skill level -{' '}
                            {this.state.self_nominated_rider_level}
                          </h2>
                          <CustomInput
                            type="range"
                            id="rider_level_range"
                            name="rider_level_range"
                            value={Math.floor(
                              ((this.state.self_nominated_rider_level - 1) /
                                5) *
                                100
                            )}
                            onChange={(e) =>
                              this.setState({
                                self_nominated_rider_level:
                                  Math.ceil((e.currentTarget.value / 100) * 5) +
                                  1,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12}>
                        <ul
                          className="text-dark ml--4"
                          style={{
                            listStylePosition: 'outside',
                          }}
                        >
                          {rider_levels[
                            this.state.self_nominated_rider_level
                          ].map((d) => (
                            <li key={d}>{d}</li>
                          ))}
                        </ul>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12}>
                <Card className="text-dark bg-secondary shadow border-0">
                  <CardBody className="text-dark px-lg-5 py-lg-5">
                    <h2 className="text-dark">
                      Have you ridden in a TdC peloton before?
                    </h2>
                    <br />
                    <FormGroup check className="mt--3">
                      <Label check>
                        <Input
                          checked={this.state.ridden_in_peloton}
                          type="radio"
                          onClick={(e) =>
                            this.setState({
                              ridden_in_peloton: true,
                            })
                          }
                          readOnly
                        />{' '}
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          checked={!this.state.ridden_in_peloton}
                          type="radio"
                          onClick={(e) =>
                            this.setState({
                              ridden_in_peloton: false,
                            })
                          }
                          readOnly
                        />{' '}
                        No
                      </Label>
                    </FormGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
        <FormText className="mb-2">
          By clicking submit, you agree for the information provided above to be
          shared with Tour de Cure staff and riders. If you do not wish to share
          your mobile number, you may leave those fields blank. Your next of kin
          will only be contacted in case of an emergency.
        </FormText>
        <div className="d-flex w-100 flex-row justify-content-end">
          <Button color="dark" onClick={() => this.saveDetails()}>
            {this.state.saving ? (
              <Spinner size="sm" color="grey" className="" />
            ) : (
              'Submit'
            )}
          </Button>
        </div>
        <hr />
        {this.state.email && (
          <div className="mt-4">
            <p className="text-dark">
              Your email is <b>{firebase.auth().currentUser.email}</b>.
            </p>
            <p className="text-dark">
              If your email is incorrect, please change it with the form below.
            </p>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-envelope" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder="Email"
                className="text-dark"
                type="email"
                onChange={(e) =>
                  this.setState({ requested_email: e.target.value })
                }
                required
              />
            </InputGroup>
            <Button
              className="mt-3"
              onClick={() =>
                changeEmailAddress(this.state.requested_email).then(
                  (success) => {
                    if (success) window.location.reload();
                  }
                )
              }
            >
              Change Email
            </Button>
          </div>
        )}
      </Form>
    );
  }
}

export default UserDetails;
