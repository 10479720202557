/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from 'react';
import { NavLink as NavLinkRRD, Link } from 'react-router-dom';
// nodejs library to set properties for components
import { PropTypes } from 'prop-types';

import { logout } from 'store/auth/actions';

// reactstrap components
import {
  Collapse,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';

import { connect } from 'react-redux';
import { SponsorLogos } from 'components/SponsorLogos';

var ps;

const mapState = (state) => state;

const mapDispatch = {
  logout,
};

class Sidebar extends React.Component {
  state = {
    collapseOpen: false,
  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };
  // creates the links that appear in the left menu / Sidebar
  createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout != '/auth' && prop.layout != '/signup')
        return (
          this.props.firestore.policies &&
          this.props.firestore.policies[prop.policy] && (
            <NavItem key={key}>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={this.closeCollapse}
                activeClassName="active"
              >
                <i className={prop.icon} />
                {prop.name}
              </NavLink>
            </NavItem>
          )
        );
    });
  };
  render() {
    const { bgColor, routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: '_blank',
      };
    }
    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          {logo ? (
            <NavbarBrand
              className="pt-0"
              {...navbarBrandProps}
              style={{ width: 'calc(100% - 60px)' }}
            >
              <div className="d-flex flex-row justify-content-between">
                <img
                  alt={logo.imgAlt}
                  src={logo.imgSrc}
                  style={{ 
                    objectFit: 'contain',
                    width: '100px',
                  }}
                />
                <div className="mr-md--6">
                  <SponsorLogos />
                </div>
              </div>
            </NavbarBrand>
          ) : null}
          {/* User */}
          <Nav className="align-items-center d-md-none">
            {/* <Media className="align-items-center">
              <span className="avatar avatar-sm rounded-circle">
                <img
                  alt="..."
                  src={require("assets/img/theme/datito.jpg")}
                />
              </span>
            </Media> */}
          </Nav>
          {/* Collapse */}
          <Collapse navbar isOpen={this.state.collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                      <a href={logo.outterLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </a>
                    )}
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            {/* Navigation */}
            <Nav navbar>
              {this.createLinks(routes)}
              <NavItem>
                <NavLink
                  to={''}
                  activeClassName="asdfasdfasdf"
                  tag={NavLinkRRD}
                  className="text-grey"
                  onClick={(e) => this.props.logout()}
                >
                  <i className="ni ni-world text-dark" />
                  Logout
                </NavLink>
              </NavItem>
            </Nav>
            {/* Divider */}
            <hr className="my-3" />
            <h6 className="navbar-heading text-muted">Other Links</h6>
            <Nav className="mb-md-3" navbar>
              <NavItem>
                <NavLink
                  onClick={(e) => window.open('https://tourdecure.com.au/')}
                >
                  <i className="ni ni-world" />
                  Tour de Cure
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={(e) =>
                    window.open('https://tourdecure.com.au/tours/join-a-tour/')
                  }
                >
                  <i className="ni ni-curved-next" />
                  Join a Tour
                </NavLink>
              </NavItem>
              <NavItem hidden>
                <NavLink
                  onClick={(e) =>
                    window.open(
                      'https://tourdecure.com.au/get-involved/make-a-donation/'
                    )
                  }
                >
                  <i className="ni ni-money-coins" />
                  Make a donation
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink href="">
                  <i className="ni ni-chat-round" />
                  Report a bug
                </NavLink>
              </NavItem> */}
            </Nav>
            {/* End of header
              <hr className="my-3" />
              <h6 className="navbar-heading text-muted">Documentation</h6>
              <Nav className="mb-md-3" navbar>
                <NavItem>
                  <NavLink href="https://demos.creative-tim.com/argon-dashboard-react/#/documentation/overview?ref=adr-admin-sidebar">
                    <i className="ni ni-spaceship" />
                    Getting started
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="https://demos.creative-tim.com/argon-dashboard-react/#/documentation/colors?ref=adr-admin-sidebar">
                    <i className="ni ni-palette" />
                    Foundation
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="https://demos.creative-tim.com/argon-dashboard-react/#/documentation/alerts?ref=adr-admin-sidebar">
                    <i className="ni ni-ui-04" />
                    Components
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav className="mb-md-3" navbar>
                <NavItem className="active-pro active">
                  <NavLink href="https://www.creative-tim.com/product/argon-dashboard-pro-react?ref=adr-admin-sidebar">
                    <i className="ni ni-spaceship" />
                    Upgrade to PRO
                  </NavLink>
                </NavItem>
              </Nav>
            */}
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default connect(mapState, mapDispatch)(Sidebar);
