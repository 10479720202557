import * as firebase from 'firebase/app';
import 'firebase/storage';

/*
 * action types
 */

/*
 * action creators
 */

export function createSharePointFolder(folder_name, form_type) {
  const createSharepointFolderWithTags = firebase
    .functions()
    .httpsCallable('createSharepointFolderWithTags');
  return createSharepointFolderWithTags({
    folder_name,
    form_type,
  }).catch((err) => console.error(err));
}

export function uploadFileToSharePoint(
  folder_name,
  file_name,
  file,
  tags = []
) {
  const saveFileToSharepoint = firebase
    .functions()
    .httpsCallable('saveFileToSharepoint');
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      // console.log(reader.result);
      resolve(
        saveFileToSharepoint({
          folder_name,
          file_name: file_name.replaceAll(' ', '_'),
          file: reader.result,
          tags,
        }).catch((err) => console.error(err))
      );
    };
    reader.readAsDataURL(file);
  });
}

export function uploadFile(path, extension, file) {
  return new Promise((resolve, reject) => {
    // Upload file and metadata to the object 'images/mountains.jpg'
    const d = new Date();
    const filepath =
      path +
      '_' +
      d.getHours().toString() +
      ':' +
      d.getMinutes().toString() +
      '-' +
      d.toLocaleDateString().replaceAll('/', '') +
      extension;
    let uploadTask = firebase.storage().ref().child(filepath).put(file);
    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      function (snapshot) {
        // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      function (error) {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        alert(
          'Error uploading file. Your changes have not been saved, please try again.'
        );
      },
      function () {
        // Upload completed successfully, now we can get the download URL
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          resolve(downloadURL);
        });
      }
    );
  });
}
