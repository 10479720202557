/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

import * as firebase from 'firebase/app';
import 'firebase/firestore';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  Container,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';

import { connect } from 'react-redux';

import {
  resendEmailVerification,
  changeEmailAddress,
  logout,
} from 'store/auth/actions';

const mapState = (state) => state;

const mapDispatch = {
  resendEmailVerification,
  changeEmailAddress,
  logout,
};

class EmailVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newEmail: '',
      showChangeEmailForm: false,
    };
    this.changeEmail = this.changeEmail.bind(this);
  }

  resend() {
    resendEmailVerification();
  }

  changeEmail() {
    if (this.state.newEmail !== '') {
      changeEmailAddress(this.state.newEmail);
      this.setState({ showChangeEmailForm: false });
    }
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="" style={{ maxWidth: '1200px' }} fluid>
          <Row>
            <Col xs="12">
              <Card className="mt-2 shadow">
                <CardBody className="px-lg-5 py-lg-5">
                  <h2 className="text-dark">Email Verification</h2>
                  <p className="text-dark">
                    We've sent a verification link to your email (
                    {firebase.auth().currentUser?.email}).
                  </p>
                  <p className="text-dark">
                    If you have not received the verification email, please
                    check your junk and spam folders. If the email is still
                    missing. Please consider changing the email address for your
                    account using the button below. We recommend that you use
                    your personal email: gmail, yahoo, outlook, hotmail etc.
                    with the Hub.
                  </p>
                  <p className="text-dark">Once you have</p>
                  <Button onClick={this.resend}>
                    Re-send the verification email
                  </Button>
                  <Button
                    onClick={(e) =>
                      this.setState({ showChangeEmailForm: true })
                    }
                  >
                    Change your email address
                  </Button>
                  <Button onClick={() => this.props.logout()}>Log out</Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.showChangeEmailForm ? (
            <Row>
              <Col xs="12">
                <Card className="mt-2 shadow">
                  <CardBody className="px-lg-5 py-lg-5">
                    <h2 className="text-dark">Change your email/username</h2>
                    <p>Enter your correct email address below.</p>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-envelope" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        className={'text-dark'}
                        type="email"
                        onChange={(e) =>
                          this.setState({ newEmail: e.target.value })
                        }
                        required
                      />
                    </InputGroup>
                    <Button className="mt-4" onClick={this.changeEmail}>
                      Change Email
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </Container>
      </>
    );
  }
}

export default connect(mapState, mapDispatch)(EmailVerification);
