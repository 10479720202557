/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

// reactstrap components
import { Card, CardBody, Container, Row, Col, Badge } from 'reactstrap';

// javascipt plugin for creating charts
import Chart from 'chart.js';
// react plugin used to create charts
import { Line } from 'react-chartjs-2';

// core components
import Header from 'components/Headers/Header.js';

import { chartOptions, parseOptions } from 'variables/charts.js';

import { connect } from 'react-redux';
import { authStrava, getTokens } from 'store/strava/actions.js';
import {
    updateAthlete,
    selectEvent,
    setTrainingHub,
    getUid,
    getEvent,
} from 'store/firestore/actions.js';

const mapState = (state) => state;

const mapDispatch = {
    authStrava,
    getEvent,
    getTokens,
    updateAthlete,
    selectEvent,
    setTrainingHub,
};

class Events extends React.Component {
    constructor(props) {
        super(props);
        if (window.Chart) {
            parseOptions(Chart, chartOptions());
        }
        const event_keys = Object.keys(this.props.firestore.events);
        if (event_keys.length > 0) props.selectEvent(event_keys[0]);
    }

    calculateTotalPoints(challenge_progress) {
        const uid = getUid();
        if (challenge_progress && uid in challenge_progress) {
            const user_challenge_progress = challenge_progress[uid];
            let total = 0;
            Object.keys(user_challenge_progress).map((key, i) => {
                total = total + user_challenge_progress[key].points;
                return total;
            });
            return total.toFixed(0);
        } else {
            return 0;
        }
    }

    generateChallengeData(challenge) {
        const uid = getUid();
        const challenge_progress = challenge.progress;
        if (challenge_progress && uid in challenge_progress) {
            const user_challenge_progress = challenge_progress[uid];
            const data = Object.keys(user_challenge_progress).map((key, i) => {
                return {
                    x: user_challenge_progress[key].date.toDate(),
                    y: user_challenge_progress[key].points,
                    not_counted: user_challenge_progress[key].not_counted,
                };
            });
            data.sort((a, b) => (a.x > b.x ? 1 : b.x > a.x ? -1 : 0));
            let total = 0;
            const cumulative = data.map((d) => {
                total = total + d.y;
                return { x: d.x, y: total.toFixed(0) };
            });
            const point_colors = data.map((d) => {
                if (d.not_counted) return 'rgba(0, 0, 0, 0.1)';
                // gray not counted
                else return 'rgba(45, 210, 235, 0.2)'; // red counted
            });
            return {
                data: [{ x: challenge.start_date, y: 0 }].concat(cumulative),
                pointBackgroundColor: point_colors,
                pointBorderColor: point_colors,
            };
        } else {
            return {
                data: [],
            };
        }
    }

    prettyDate(d) {
        const mo = d.toLocaleString('en', { month: 'short' });
        const da = d.toLocaleString('en', { day: '2-digit' });
        //const tm = new Intl.DateTimeFormat('en', { timeStyle: 'short' }).format(d);
        const tm = d.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
        return `${tm} ${da} ${mo}`;
    }

    orderChallenges(challenges) {
        const chronological = Object.keys(challenges)
            .map((key) => challenges[key])
            .sort((a, b) => a.end_date - b.end_date);
        const categories = {
            completed: chronological
                .filter((challenge) => new Date() >= challenge.end_date)
                .map((challenge) => {
                    return { ...challenge, status: 'completed' };
                }),
            upcoming: chronological
                .filter((challenge) => new Date() < challenge.start_date)
                .map((challenge) => {
                    return { ...challenge, status: 'upcoming' };
                }),
            active: chronological
                .filter(
                    (challenge) =>
                        challenge.start_date <= new Date() &&
                        new Date() < challenge.end_date
                )
                .map((challenge) => {
                    return { ...challenge, status: 'active' };
                }),
        };
        const ordered_challenges = [].concat(
            categories.active,
            categories.upcoming,
            categories.completed
        );
        return ordered_challenges;
    }

    render() {
        return (
            <>
                <Header
                    title="Challenges"
                    dropdown
                    selected={this.props.firestore.selected_event.name}
                    onSelect={(key) => this.props.selectEvent(key)}
                    items={this.props.firestore.events}
                    syncAction={() =>
                        this.props.getEvent(
                            this.props.firestore.selected_event.id
                        )
                    }
                />
                <Container className="" style={{ maxWidth: '1200px' }} fluid>
                    <Row>
                        <Col xs="12">
                            {Object.keys(
                                this.props.firestore.selected_event.challenges
                            ).length === 0 ? (
                                <Card className="mt-2">
                                    <CardBody>
                                        Please come back when data is available.
                                    </CardBody>
                                </Card>
                            ) : (
                                this.orderChallenges(
                                    this.props.firestore.selected_event
                                        .challenges
                                ).map((challenge, i) => (
                                    <Card
                                        key={i}
                                        className="card-profile shadow mt-2"
                                    >
                                        <CardBody className="pt-4">
                                            <Row>
                                                <Col xs="8">
                                                    <h2 className="text-dark">
                                                        {challenge.name}
                                                    </h2>
                                                </Col>
                                                <Col xs="4">
                                                    <h2 className="text-dark text-right">
                                                        {this.calculateTotalPoints(
                                                            challenge.progress
                                                        )}
                                                        <small
                                                            className="text-right text-dark ml-2"
                                                            style={{
                                                                wordWrap:
                                                                    'normal',
                                                            }}
                                                        >
                                                            points
                                                        </small>
                                                    </h2>
                                                </Col>
                                                <Col xs="12">
                                                    <small className="text-dark">
                                                        {this.prettyDate(
                                                            challenge.start_date
                                                        )}{' '}
                                                        -{' '}
                                                        {this.prettyDate(
                                                            challenge.end_date
                                                        )}
                                                    </small>
                                                    <small className="ml-1">
                                                        {challenge.status ===
                                                        'completed' ? (
                                                            <Badge className="badge-info">
                                                                completed
                                                            </Badge>
                                                        ) : null}
                                                        {challenge.status ===
                                                        'active' ? (
                                                            <Badge className="badge-success">
                                                                active
                                                            </Badge>
                                                        ) : null}
                                                        {challenge.status ===
                                                        'upcoming' ? (
                                                            <Badge className="badge-warning">
                                                                upcoming
                                                            </Badge>
                                                        ) : null}
                                                    </small>
                                                </Col>
                                                <Col xs="12">
                                                    <p className="text-dark mb-0">
                                                        {challenge.description}
                                                    </p>
                                                </Col>
                                                {challenge.status !==
                                                'upcoming' ? (
                                                    <Col
                                                        className="my-2"
                                                        xs="12"
                                                        hidden={
                                                            this.calculateTotalPoints(
                                                                challenge.progress
                                                            ) <= 0
                                                        }
                                                    >
                                                        <Line
                                                            data={{
                                                                datasets: [
                                                                    {
                                                                        ...this.generateChallengeData(
                                                                            challenge
                                                                        ),
                                                                        borderColor:
                                                                            'rgba(45, 210, 235, 0.5)',
                                                                        backgroundColor:
                                                                            'rgba(45, 210, 235, 0.2)',
                                                                        borderWidth: 1,
                                                                    },
                                                                ],
                                                            }}
                                                            options={{
                                                                scales: {
                                                                    xAxes: [
                                                                        {
                                                                            type: 'time',
                                                                            time: {
                                                                                unit: 'day',
                                                                            },
                                                                        },
                                                                    ],
                                                                },
                                                            }}
                                                        />
                                                    </Col>
                                                ) : null}
                                            </Row>
                                        </CardBody>
                                    </Card>
                                ))
                            )}
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default connect(mapState, mapDispatch)(Events);
