import {
} from './actions';

export const initialState = {}
  
export function authReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state
  }
}