/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

// reactstrap components
import { Card, Container, Row, Col, Table, CardBody } from 'reactstrap';
// react plugin used to create charts

// core components
import Header from 'components/Headers/Header.js';

import { connect } from 'react-redux';
import {} from 'store/strava/actions.js';
import { selectEvent, getEvent } from 'store/firestore/actions.js';

const mapState = (state) => state;

const mapDispatch = {
    selectEvent,
    getEvent,
};

class ParticipantLeaderboard extends React.Component {
    generateLeaderboardStats() {
        // for team in teams, gen list of users
        const leaderboard = {};
        const team =
            this.props.firestore.selected_event.group_data.teams[
                this.props.firestore.selected_event.member_team_id
            ];
        for (const athlete in team.members) {
            leaderboard[athlete] = {
                points: 0,
                activities: new Set(),
            };
        }
        for (const challenge_id in this.props.firestore.selected_event
            .challenges) {
            const challenge =
                this.props.firestore.selected_event.challenges[challenge_id];
            for (const athlete in challenge.progress) {
                // for athlete in progress
                if (athlete in team.members) {
                    // for activity in athlete
                    for (const activity in challenge.progress[athlete]) {
                        // add to activity count
                        leaderboard[athlete].activities.add(activity);
                        leaderboard[athlete].points +=
                            challenge.progress[athlete][activity].points;
                    }
                }
            }
        }
        const leaderboard_array = [];
        Object.keys(leaderboard).map((key, i) => {
            leaderboard_array.push({
                name: team.members[key].name,
                logo: team.members[key].profile_image,
                points: leaderboard[key].points.toFixed(0),
                activities: leaderboard[key].activities.size,
            });
            return true;
        });
        leaderboard_array.sort((a, b) => b.points - a.points);
        return leaderboard_array;
    }

    render() {
        return (
            <>
                <Header
                    title="Participant Leaderboard"
                    dropdown
                    selected={this.props.firestore.selected_event.name}
                    onSelect={(key) => this.props.selectEvent(key)}
                    items={this.props.firestore.events}
                    syncAction={() =>
                        this.props.getEvent(
                            this.props.firestore.selected_event.id
                        )
                    }
                />
                <Container className="" style={{ maxWidth: '1200px' }} fluid>
                    <Row>
                        {this.props.firestore.selected_event.member_team_id &&
                        this.props.firestore.selected_event.group_data.teams ? (
                            <>
                                <Col xs="12">
                                    <Card className="mt-2 p-4">
                                        <Row>
                                            <Col align="center">
                                                <img
                                                    className="mr-3"
                                                    src={
                                                        this.props.firestore
                                                            .selected_event
                                                            .group_data.teams[
                                                            this.props.firestore
                                                                .selected_event
                                                                .member_team_id
                                                        ].logo_url
                                                    }
                                                    alt="Invalid logo"
                                                    style={{
                                                        maxHeight: '50px',
                                                        borderRadius: '25px',
                                                    }}
                                                />
                                                <span className="text-dark">
                                                    {
                                                        this.props.firestore
                                                            .selected_event
                                                            .group_data.teams[
                                                            this.props.firestore
                                                                .selected_event
                                                                .member_team_id
                                                        ].name
                                                    }
                                                </span>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col xs="12">
                                    <Card className="mt-2">
                                        <Table
                                            className="align-items-center table-flush text-center"
                                            responsive
                                        >
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">Rank</th>
                                                    <th scope="col">Photo</th>
                                                    <th scope="col">Member</th>
                                                    <th scope="col">Points</th>
                                                    <th scope="col">
                                                        Activities
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.generateLeaderboardStats().map(
                                                    (stat, i) => (
                                                        <tr key={i}>
                                                            <td>{i + 1}</td>
                                                            <td>
                                                                <img
                                                                    src={
                                                                        stat.logo
                                                                    }
                                                                    onError={(
                                                                        e
                                                                    ) => {
                                                                        e.target.onerror =
                                                                            null;
                                                                        e.target.src = require('assets/img/strava/user.png');
                                                                    }}
                                                                    alt="Invalid logo"
                                                                    style={{
                                                                        height: '30px',
                                                                        width: '30px',
                                                                        borderRadius:
                                                                            '15px',
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>{stat.name}</td>
                                                            <td>
                                                                {stat.points}
                                                            </td>
                                                            <td>
                                                                {
                                                                    stat.activities
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </Table>
                                    </Card>
                                </Col>
                            </>
                        ) : (
                            <Col xs="12">
                                <Card className="mt-2">
                                    <CardBody>
                                        Please come back when data is available.
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </Container>
            </>
        );
    }
}

export default connect(mapState, mapDispatch)(ParticipantLeaderboard);
