import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { logout } from 'store/auth/actions';

import * as Papa from 'papaparse';

export const SET_VIEWING_ATHLETE_FILES = 'SET_VIEWING_ATHLETE_FILES';
export const SET_ATHLETE = 'SET_ATHLETE';
export const ADD_TEAM = 'ADD_TEAM';
export const SET_ROLE = 'SET_ROLE';
export const SET_APP_SETTINGS = 'SET_APP_SETTINGS';
export const SET_TRAINING_HUB = 'SET_TRAINING_HUB';
export const SET_LOADING = 'SET_LOADING';
export const ADD_EVENT = 'ADD_EVENT';
export const ADD_CHALLENGE = 'ADD_CHALLENGE';
export const SELECT_EVENT = 'SELECT_EVENT';
export const SET_GROUP = 'SET_GROUP';
export const ADD_GROUP = 'ADD_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const SET_ATHLETES = 'SET_ATHLETES';
export const ADD_TEAM_TO_GROUP = 'ADD_TEAM_TO_GROUP';
export const ADD_MEMBER_TO_TEAM = 'ADD_MEMBER_TO_TEAM';
export const REMOVE_MEMBER_FROM_TEAM = 'REMOVE_MEMBER_FROM_TEAM';
export const REPLACE_ATHLETE = 'REPLACE_ATHLETE';
export const SET_ROLES = 'SET_ROLES';
export const REPLACE_ROLE = 'REPLACE_ROLE';
export const SET_FILES = 'SET_FILES';
export const SET_GRASSROOTZ_DATA = 'SET_GRASSROOTZ_DATA';
export const SET_GRASSROOTZ_FUNDRAISING_LEADERBOARD =
  'SET_GRASSROOTZ_FUNDRAISING_LEADERBOARD';
export const ADD_EFFORT_CONTRIBUTIONS = 'ADD_EFFORT_CONTRIBUTIONS';

export function getUid() {
  return firebase.auth().currentUser ? firebase.auth().currentUser.uid : null;
}

export function isValidGrassrootzId(grassrootz_id) {
  if (grassrootz_id === '' || /^[a-zA-Z0-9-]+$/.test(grassrootz_id)) {
    return true;
  } else {
    alert(
      'Please enter a valid Grassrootz id. Your id should not contain spaces and should be in a form similar to `firstname-lastname`.'
    );
    return false;
  }
}

export const generateUid = () => {
  const CHARS =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let autoId = '';
  for (let i = 0; i < 20; i++) {
    autoId += CHARS.charAt(Math.floor(Math.random() * CHARS.length));
  }
  return autoId;
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const calculateVO2maxFitness = (gender, year_of_birth, vo2max) => {
  let vo2max_fitness_level = 0;
  if (
    Number(vo2max) &&
    Number(year_of_birth) &&
    (gender === 'male' || gender === 'female' || gender === 'other')
  ) {
    const age = new Date().getFullYear() - year_of_birth;
    if (gender === 'male') {
      if (age < 20) {
        if (vo2max < 35) vo2max_fitness_level = 1;
        else if (vo2max < 38.3) vo2max_fitness_level = 2;
        else if (vo2max < 45.1) vo2max_fitness_level = 3;
        else if (vo2max < 50.9) vo2max_fitness_level = 4;
        else if (vo2max < 55.9) vo2max_fitness_level = 5;
        else if (vo2max >= 55.9) vo2max_fitness_level = 6;
      } else if (age < 30) {
        if (vo2max < 33) vo2max_fitness_level = 1;
        else if (vo2max < 36.4) vo2max_fitness_level = 2;
        else if (vo2max < 42.4) vo2max_fitness_level = 3;
        else if (vo2max < 46.4) vo2max_fitness_level = 4;
        else if (vo2max < 52.4) vo2max_fitness_level = 5;
        else if (vo2max >= 52.4) vo2max_fitness_level = 6;
      } else if (age < 40) {
        if (vo2max < 31.5) vo2max_fitness_level = 1;
        else if (vo2max < 35.4) vo2max_fitness_level = 2;
        else if (vo2max < 40.9) vo2max_fitness_level = 3;
        else if (vo2max < 44.9) vo2max_fitness_level = 4;
        else if (vo2max < 49.4) vo2max_fitness_level = 5;
        else if (vo2max >= 49.4) vo2max_fitness_level = 6;
      } else if (age < 50) {
        if (vo2max < 30.2) vo2max_fitness_level = 1;
        else if (vo2max < 33.5) vo2max_fitness_level = 2;
        else if (vo2max < 38.9) vo2max_fitness_level = 3;
        else if (vo2max < 43.7) vo2max_fitness_level = 4;
        else if (vo2max < 48.0) vo2max_fitness_level = 5;
        else if (vo2max >= 48.0) vo2max_fitness_level = 6;
      } else if (age < 60) {
        if (vo2max < 26.1) vo2max_fitness_level = 1;
        else if (vo2max < 30.9) vo2max_fitness_level = 2;
        else if (vo2max < 35.7) vo2max_fitness_level = 3;
        else if (vo2max < 40.9) vo2max_fitness_level = 4;
        else if (vo2max < 45.3) vo2max_fitness_level = 5;
        else if (vo2max >= 45.3) vo2max_fitness_level = 6;
      } else if (age >= 60) {
        if (vo2max < 20.5) vo2max_fitness_level = 1;
        else if (vo2max < 26.0) vo2max_fitness_level = 2;
        else if (vo2max < 32.2) vo2max_fitness_level = 3;
        else if (vo2max < 36.4) vo2max_fitness_level = 4;
        else if (vo2max < 44.2) vo2max_fitness_level = 5;
        else if (vo2max >= 44.2) vo2max_fitness_level = 6;
      }
    } else {
      if (age < 20) {
        if (vo2max < 25) vo2max_fitness_level = 1;
        else if (vo2max < 30.9) vo2max_fitness_level = 2;
        else if (vo2max < 34.9) vo2max_fitness_level = 3;
        else if (vo2max < 38.9) vo2max_fitness_level = 4;
        else if (vo2max < 41.9) vo2max_fitness_level = 5;
        else if (vo2max >= 41.9) vo2max_fitness_level = 6;
      } else if (age < 30) {
        if (vo2max < 23.6) vo2max_fitness_level = 1;
        else if (vo2max < 28.9) vo2max_fitness_level = 2;
        else if (vo2max < 32.9) vo2max_fitness_level = 3;
        else if (vo2max < 36.9) vo2max_fitness_level = 4;
        else if (vo2max < 41) vo2max_fitness_level = 5;
        else if (vo2max >= 41) vo2max_fitness_level = 6;
      } else if (age < 40) {
        if (vo2max < 22.8) vo2max_fitness_level = 1;
        else if (vo2max < 26.9) vo2max_fitness_level = 2;
        else if (vo2max < 31.4) vo2max_fitness_level = 3;
        else if (vo2max < 35.6) vo2max_fitness_level = 4;
        else if (vo2max < 40.0) vo2max_fitness_level = 5;
        else if (vo2max >= 40.0) vo2max_fitness_level = 6;
      } else if (age < 50) {
        if (vo2max < 21) vo2max_fitness_level = 1;
        else if (vo2max < 24.4) vo2max_fitness_level = 2;
        else if (vo2max < 28.9) vo2max_fitness_level = 3;
        else if (vo2max < 32.8) vo2max_fitness_level = 4;
        else if (vo2max < 36.9) vo2max_fitness_level = 5;
        else if (vo2max >= 36.9) vo2max_fitness_level = 6;
      } else if (age < 60) {
        if (vo2max < 20.3) vo2max_fitness_level = 1;
        else if (vo2max < 22.7) vo2max_fitness_level = 2;
        else if (vo2max < 26.9) vo2max_fitness_level = 3;
        else if (vo2max < 31.4) vo2max_fitness_level = 4;
        else if (vo2max < 35.7) vo2max_fitness_level = 5;
        else if (vo2max >= 35.7) vo2max_fitness_level = 6;
      } else if (age >= 60) {
        if (vo2max < 17.5) vo2max_fitness_level = 1;
        else if (vo2max < 20.1) vo2max_fitness_level = 2;
        else if (vo2max < 24.4) vo2max_fitness_level = 3;
        else if (vo2max < 30.2) vo2max_fitness_level = 4;
        else if (vo2max < 31.4) vo2max_fitness_level = 5;
        else if (vo2max >= 31.4) vo2max_fitness_level = 6;
      }
    }
  }
  return vo2max_fitness_level;
};

const createValidProfileImage = (id, profile_medium) => {
  return new Promise((resolve, reject) => {
    const dynamic_links = {
      profile: require('assets/img/strava/user.png'),
      profile_medium: require('assets/img/strava/user.png'),
    };
    if (
      id === '' ||
      !id ||
      !profile_medium ||
      profile_medium === '' ||
      profile_medium === 'avatar/athlete/medium.png'
    ) {
      resolve(dynamic_links);
      return null;
    }
    let img = new Image();
    img.onload = () => resolve({});
    img.onerror = () => resolve(dynamic_links);
    img.src = profile_medium;
  });
};

export function setLoading(loading) {
  return { type: SET_LOADING, loading: loading };
}

export function getGrassrootzFundraisingLeaderboard(event_id) {
  return (dispatch, getState) => {
    const event = getState().firestore.events[event_id];

    const grassrootz_event_id = event?.grassrootz_event_id;
    const grassrootz_campaign_id = event?.grassrootz_campaign_id;

    // do this so that it removes old data and does not confuse users
    dispatch({
      type: SET_GRASSROOTZ_FUNDRAISING_LEADERBOARD,
      event_id: event_id,
    });

    if (!event || !grassrootz_event_id || !grassrootz_campaign_id) return;

    const getGrassrootzEventFundraisingLeaderboard = firebase
      .functions()
      .httpsCallable('getGrassrootzEventFundraisingLeaderboard');

    getGrassrootzEventFundraisingLeaderboard({
      grassrootz_event_id,
      grassrootz_campaign_id,
    }).then((fundraisers) => {
      dispatch({
        type: SET_GRASSROOTZ_FUNDRAISING_LEADERBOARD,
        event_id: event_id,
        data: fundraisers || [{}],
      });
    });
  };
}

export function getGrassrootzData(event_id) {
  return (dispatch, getState) => {
    // const event = grassrootz_profile_url.match(/(?<=\.grassrootz\.com\/).*(?=\/)/);
    // const athlete = grassrootz_profile_url.match(/(?<=\.grassrootz\.com\/.*\/).*/);
    const athlete = getState().firestore.athlete.grassrootz_athlete_id;
    const event = getState().firestore.events[event_id]?.grassrootz_event_id;
    if (!event || !athlete)
      return dispatch({
        type: SET_GRASSROOTZ_DATA,
        event_id: event_id,
        data: { target: 0, raised: -1 },
      });
    const getGrassrootzAthleteEventFundraising = firebase
      .functions()
      .httpsCallable('getGrassrootzAthleteEventFundraising');
    getGrassrootzAthleteEventFundraising({
      event,
      athlete,
    })
      .then((data) => ({
        target: data?.data?.target,
        raised: data?.data?.raised,
      }))
      .then((data) =>
        dispatch({
          type: SET_GRASSROOTZ_DATA,
          event_id: event_id,
          data: data,
        })
      );
  };
}

export function updateAthlete(athlete, first_load = true) {
  return (dispatch) => {
    let uid = getUid();
    if (uid) {
      const email = firebase.auth().currentUser?.email ?? '';
      return firebase
        .firestore()
        .collection('athletes')
        .doc(uid)
        .set({ ...athlete, email }, { merge: true })
        .then(() => setTimeout(dispatch(getAthlete(first_load)), 500))
        .catch(function (error) {
          alert(error.message);
        });
    } else {
      const unsubscribe = firebase.auth().onAuthStateChanged(function (user) {
        if (user) dispatch(updateAthlete(athlete));
        if (user) unsubscribe();
      });
    }
  };
}

export function getRole() {
  let uid = getUid();
  if (uid) {
    return (dispatch) => {
      return firebase
        .firestore()
        .collection('roles')
        .doc(uid)
        .get()
        .then(
          (role) =>
            role.exists && dispatch({ type: 'SET_ROLE', role: role.data() })
        )
        .catch(function (error) {
          console.error(error.message);
        });
    };
  }
}

export function getEvents() {
  let uid = getUid();
  if (uid) {
    return async (dispatch, getState) => {
      // const events = getState().firestore.athlete.events;
      // events.map(async event => await dispatch(getEvent(event)));
      const new_events = await firebase
        .firestore()
        .collection('events')
        .where('end_date', '>', new Date())
        .orderBy('end_date', 'asc')
        .get();
      const old_events = await firebase
        .firestore()
        .collection('events')
        .where('end_date', '<=', new Date())
        .orderBy('end_date', 'desc')
        .limit(5)
        .get();
      return Promise.all(
        [
          new_events.docs.map((event) => dispatch(getEventFromDoc(event))),
          old_events.docs.map((event) => dispatch(getEventFromDoc(event))),
        ].flat()
      );
    };
  }
}

export function getEvent(event_id) {
  let uid = getUid();
  if (uid) {
    return async (dispatch, getState) => {
      const event = firebase.firestore().collection('events').doc(event_id);
      const event_doc = await event.get();
      return dispatch(getEventFromDoc(event_doc));
    };
  }
}

export function getEventFromDoc(event_doc) {
  let uid = getUid();
  if (uid) {
    return async (dispatch, getState) => {
      const event_data = event_doc.data();
      const view_all = getState().firestore.policies.view_all_events;
      const promises = [];
      if (event_data.deleted)
        return dispatch({
          type: ADD_EVENT,
          id: event_doc.id,
          event: { deleted: true },
        });
      if (event_data.group) {
        const group_doc = await event_data.group.get();
        const members_team = await event_data.group
          .collection('teams')
          .where('members.' + uid, '!=', '')
          .get();
        if (view_all || members_team.size > 0) {
          if (members_team.size > 0)
            event_data.member_team_id = members_team.docs[0].id;
          promises.push(
            dispatch({
              type: ADD_EVENT,
              id: event_doc.id,
              event: {
                ...event_data,
                group_data: group_doc.data(),
              },
            })
          );
          const teams = await event_data.group.collection('teams').get();
          teams.docs.map((team_doc) => {
            const team_data = team_doc.data();
            promises.push(
              dispatch({
                type: ADD_TEAM,
                event_id: event_doc.id,
                id: team_doc.id,
                team: team_data,
              })
            );
            return null;
          });
          const challenges = await event_doc.ref.collection('challenges').get();
          challenges.docs.map((challenge_doc) => {
            const challenge_data = challenge_doc.data();
            if (
              'progress' in challenge_data &&
              uid in challenge_data.progress
            ) {
              challenge_data.user_points = Object.keys(
                challenge_data.progress[uid]
              )
                .map((key) => challenge_data.progress[uid][key].points)
                .reduce((a, b) => a + b, 0);
            }
            promises.push(
              dispatch({
                type: ADD_CHALLENGE,
                event_id: event_doc.id,
                id: challenge_doc.id,
                challenge: challenge_data,
              })
            );
            return null;
          });
        }
      }
      promises.push(dispatch(getGrassrootzData(event_doc.id)));
      return Promise.all(promises);
    };
  }
}

export function setEvent(event, key = null) {
  let uid = getUid();
  if (uid) {
    const challenges = event.challenges;
    return (dispatch) => {
      const add_challenges = (event_ref) => {
        const promises = Promise.all(
          Object.keys(challenges).map((key, i) => {
            const challenge = {
              name: challenges[key].name,
              description: challenges[key].description,
              start_date: challenges[key].start_date,
              end_date: challenges[key].end_date,
              deleted: challenges[key].deleted ? true : false,
              metric: challenges[key].metric,
              multiplier: challenges[key].multiplier,
              activity_types: challenges[key].activity_types,
              scope: challenges[key].scope,
            };
            return event_ref
              .collection('challenges')
              .doc(key)
              .set(challenge, { merge: true });
          })
        );
        return promises.then(() => Promise.resolve(event_ref));
      };
      const event_updates = {
        name: event.name,
        start_date: event.start_date,
        end_date: event.end_date,
        deleted: event.deleted ? true : false,
        required_fitness: event.required_fitness,
        required_training_rides: event.required_training_rides,
        required_skill: event.required_skill,
        training_program: event.training_program,
        grassrootz_event_id: event.grassrootz_event_id,
        grassrootz_campaign_id: event.grassrootz_campaign_id,
        group: firebase.firestore().collection('groups').doc(event.group_id),
        files: event.files,
        athlete_files: event.athlete_files,
      };
      if (key) {
        const event_ref = firebase.firestore().collection('events').doc(key);
        return event_ref
          .set(event_updates, { merge: true })
          .then(() => add_challenges(event_ref))
          .then((event_ref) => dispatch(getEvent(event_ref.id)))
          .catch(function (error) {
            alert(error.message);
          });
      } else {
        return firebase
          .firestore()
          .collection('events')
          .add(event_updates)
          .then((event_ref) => add_challenges(event_ref))
          .then((event_ref) => dispatch(getEvent(event_ref.id)))
          .catch(function (error) {
            alert(error.message);
          });
      }
    };
  }
}

export function getAthlete(first_load = true) {
  let uid = getUid();
  if (uid) {
    return async (dispatch) => {
      const athlete = await firebase
        .firestore()
        .collection('athletes')
        .doc(uid)
        .get();
      if (athlete.exists) {
        const validImages = await createValidProfileImage(
          athlete.data().id,
          athlete.data().profile_medium
        );
        dispatch({
          type: SET_ATHLETE,
          athlete: {
            ...athlete.data(),
            ...validImages,
            athlete_key: athlete.id,
          },
        });
        dispatch(getAllFiles(true));
        //const gotTeams = dispatch(getTeams());
        for (const id in { ...athlete.data().grassrootz_urls }) {
          dispatch(getGrassrootzData(id));
        }
        if (first_load) {
          const gotEvents = dispatch(getEvents());
          const gotRole = dispatch(getRole());
          await Promise.all([gotEvents, gotRole]);
          dispatch({ type: SET_LOADING, loading: false });
        }
      } else {
        alert(
          'There was an error synchronising your profile. Please re-register to resolve this issue. You can use the same email address.'
        );
        const user = firebase.auth().currentUser;
        user.delete().finally(() => dispatch(logout()));
      }
    };
  }
}

export function getAppSettings() {
  let uid = getUid();
  if (uid) {
    return async (dispatch) => {
      const app_settings = await firebase
        .firestore()
        .collection('settings')
        .doc('app')
        .get();
      dispatch({
        type: SET_APP_SETTINGS,
        app_settings: app_settings.data(),
      });
    };
  }
}

export function updateAppSettings(updates) {
  let uid = getUid();
  if (uid) {
    return async (dispatch) => {
      await firebase
        .firestore()
        .collection('settings')
        .doc('app')
        .update(updates);
      dispatch(getAppSettings());
    };
  }
}

export function selectEvent(id) {
  return { type: SELECT_EVENT, id: id };
}

export function setTrainingHub(hub) {
  let uid = getUid();
  if (uid) {
    firebase
      .firestore()
      .collection('athletes')
      .doc(uid)
      .update({ training_hub: hub });
    return { type: SET_TRAINING_HUB, hub: hub };
  }
}

// get all groups (all data)
export function getAllGroups() {
  let uid = getUid();
  if (uid) {
    return async (dispatch) => {
      const groups = await firebase.firestore().collection('groups').get();
      return groups.forEach((group) =>
        dispatch({ type: ADD_GROUP, id: group.id, group: group.data() })
      );
    };
  }
}

// get all teams within group (all data)
export function getGroupTeams(group_id) {
  let uid = getUid();
  if (uid) {
    return async (dispatch) => {
      const teams = await firebase
        .firestore()
        .collection('groups')
        .doc(group_id)
        .collection('teams')
        .get();
      return teams.forEach((team) =>
        dispatch({
          type: ADD_TEAM_TO_GROUP,
          group_id: group_id,
          id: team.id,
          team: team.data(),
        })
      );
    };
  }
}

// get all tdc members
export function getAllAthletes(force = false) {
  let uid = getUid();
  if (uid) {
    return (dispatch, getState) => {
      if (!force && getState().firestore.athletes.length > 0)
        return new Promise((resolve) => resolve());
      return firebase
        .firestore()
        .collection('athletes')
        .get()
        .then((results) => {
          Promise.all(
            results.docs.map((doc) => {
              // if (!doc.data().firstname || !doc.data().lastname) console.warn('Nameless athlete', doc.data())
              return createValidProfileImage(
                doc.data().id,
                doc.data().profile_medium
              ).then((validImages) => {
                return {
                  ...doc.data(),
                  ...validImages,
                  athlete_key: doc.id,
                };
              });
            })
          ).then((athletes) =>
            dispatch({ type: SET_ATHLETES, athletes: athletes })
          );
        });
    };
  }
}

export function getAllRoles(force = false) {
  let uid = getUid();
  if (uid) {
    return (dispatch, getState) => {
      if (!force && Object.keys(getState().firestore.roles).length > 0) return;
      return firebase
        .firestore()
        .collection('roles')
        .get()
        .then((results) => {
          const roles_dict = {};
          results.docs.map((role) => (roles_dict[role.id] = role.data()));
          dispatch({ type: SET_ROLES, roles: roles_dict });
        });
    };
  }
}

export function getViewingAthleteFiles(id) {
  let uid = getUid();
  if (uid) {
    return (dispatch) => {
      return firebase
        .firestore()
        .collection('files')
        .where('athleteId', '==', id)
        .get()
        .then((results) => {
          const files_dict = {};
          results.docs.map((file) => (files_dict[file.id] = file.data()));
          dispatch({
            type: SET_VIEWING_ATHLETE_FILES,
            viewing_athlete_files: files_dict,
          });
        })
        .catch((error) => {
          console.error('Error writing document: ', error);
        });
    };
  }
}

export function getAllFiles(force = false) {
  let uid = getUid();
  if (uid) {
    return (dispatch, getState) => {
      if (!force && getState().firestore.athlete_files.length > 0)
        return new Promise((resolve) => resolve());
      return firebase
        .firestore()
        .collection('files')
        .where('athleteId', '==', uid)
        .get()
        .then((results) => {
          const files_dict = {};
          results.docs.map((file) => (files_dict[file.id] = file.data()));
          dispatch({ type: SET_FILES, athlete_files: files_dict });
        })
        .catch((error) => {
          console.error('Error writing document: ', error);
        });
    };
  }
}

function reloadExternalAthlete(athlete_key) {
  let uid = getUid();
  if (uid) {
    return async (dispatch) => {
      return firebase
        .firestore()
        .collection('athletes')
        .doc(athlete_key)
        .get()
        .then((doc) =>
          createValidProfileImage(
            doc.data().id,
            doc.data().profile_medium
          ).then((validImages) => {
            return {
              ...doc.data(),
              ...validImages,
              athlete_key: doc.id,
            };
          })
        )
        .then((athlete) => {
          if (athlete_key === uid) {
            const validImages = createValidProfileImage(
              athlete.id,
              athlete.profile_medium
            ).then(() =>
              dispatch({
                type: SET_ATHLETE,
                athlete: { ...athlete, ...validImages },
              })
            );
          }
          return dispatch({
            type: REPLACE_ATHLETE,
            athlete: athlete,
          });
        });
    };
  }
}

export function updateExternalAthlete(updates, athlete_key) {
  let uid = getUid();
  if (uid) {
    return async (dispatch) => {
      return firebase
        .firestore()
        .collection('athletes')
        .doc(athlete_key)
        .update(
          updates.manual_training_rides
            ? {
                ...updates,
                manual_training_rides: firebase.firestore.FieldValue.arrayUnion(
                  ...updates.manual_training_rides
                ),
              }
            : updates
        )
        .then(() => dispatch(reloadExternalAthlete(athlete_key)));
    };
  }
}

export function reloadUserRole(athlete_key) {
  let uid = getUid();
  if (uid) {
    return async (dispatch) => {
      return firebase
        .firestore()
        .collection('roles')
        .doc(athlete_key)
        .get()
        .then((role) => {
          if (athlete_key === uid)
            dispatch({ type: 'SET_ROLE', role: role.data() });
          return dispatch({
            type: REPLACE_ROLE,
            id: role.id,
            role: role.data(),
          });
        });
    };
  }
}

export function updateUserRoles(updates, athlete_key) {
  let uid = getUid();
  if (uid) {
    return async (dispatch) => {
      return firebase
        .firestore()
        .collection('roles')
        .doc(athlete_key)
        .set(updates, { merge: true })
        .then(() => dispatch(reloadUserRole(athlete_key)));
    };
  }
}

// set group (name)
export function setGroup(group_id, group) {
  let uid = getUid();
  if (uid) {
    return async (dispatch, getState) => {
      const teams = group.teams;
      const group_update = group;
      delete group_update.teams;
      Promise.all([
        firebase
          .firestore()
          .collection('groups')
          .doc(group_id)
          .set(group_update, { merge: true })
          .then(() =>
            dispatch({
              type: UPDATE_GROUP,
              id: group_id,
              group: group,
            })
          ),
        ...Object.keys(teams)
          .map((key, i) =>
            firebase
              .firestore()
              .collection('groups')
              .doc(group_id)
              .collection('teams')
              .doc(key)
              .set(teams[key])
              .then(() =>
                dispatch({
                  type: ADD_TEAM_TO_GROUP,
                  group_id: group_id,
                  id: key,
                  team: teams[key],
                })
              )
          )
          .flat(),
      ]);
    };
  }
}

// set team (logo_url, name)
export function setTeam(group_id, team_id, event_id, team) {
  let uid = getUid();
  if (uid) {
    return async (dispatch) => {
      const team_ref = firebase
        .firestore()
        .collection('groups')
        .doc(group_id)
        .collection('teams')
        .doc(team_id);
      return team_ref
        .set(team, { merge: true })
        .then(() => team_ref.get())
        .then((team_doc) =>
          dispatch({
            type: ADD_TEAM,
            event_id: event_id,
            id: team_id,
            team: team_doc.data(),
          })
        );
    };
  }
}

export function addTrainingHub(training_hub) {
  let uid = getUid();
  if (uid) {
    return async (dispatch) => {
      return firebase
        .firestore()
        .collection('settings')
        .doc('app')
        .update({
          training_hubs: firebase.firestore.FieldValue.arrayUnion(training_hub),
        })
        .then(() => dispatch(getAppSettings()));
    };
  }
}

export function removeTrainingHub(training_hub) {
  let uid = getUid();
  if (uid) {
    return async (dispatch) => {
      return firebase
        .firestore()
        .collection('settings')
        .doc('app')
        .update({
          training_hubs:
            firebase.firestore.FieldValue.arrayRemove(training_hub),
        })
        .then(() => dispatch(getAppSettings()));
    };
  }
}

export function addEventRole(role) {
  let uid = getUid();
  if (uid) {
    return async (dispatch) => {
      return firebase
        .firestore()
        .collection('settings')
        .doc('app')
        .update({
          event_roles: firebase.firestore.FieldValue.arrayUnion({
            id: generateUid(),
            ...role,
          }),
        })
        .then(() => dispatch(getAppSettings()));
    };
  }
}

export function removeEventRole(role) {
  let uid = getUid();
  if (uid) {
    return async (dispatch) => {
      return firebase
        .firestore()
        .collection('settings')
        .doc('app')
        .update({
          event_roles: firebase.firestore.FieldValue.arrayRemove(role),
        })
        .then(() => dispatch(getAppSettings()));
    };
  }
}

export function addSettingsAthleteFile(file) {
  let uid = getUid();
  if (uid) {
    const genId = generateUid();
    return async (dispatch) => {
      return firebase
        .firestore()
        .collection('settings')
        .doc('app')
        .update({
          athlete_files: firebase.firestore.FieldValue.arrayUnion({
            fileId: genId,
            ...file,
          }),
        })
        .then(() => dispatch(getAppSettings()));
    };
  }
}

export function removeSettingsAthleteFile(file) {
  let uid = getUid();
  if (uid) {
    return async (dispatch) => {
      return firebase
        .firestore()
        .collection('settings')
        .doc('app')
        .update({
          athlete_files: firebase.firestore.FieldValue.arrayRemove(file),
        })
        .then(() => dispatch(getAppSettings()));
    };
  }
}

export function addAthleteFile(file) {
  let uid = getUid();
  if (uid) {
    const genId = generateUid();
    return async (dispatch) => {
      return firebase
        .firestore()
        .collection('files')
        .doc(genId)
        .set(
          {
            ...file,
            id: genId,
          },
          { merge: true }
        )
        .then(() => dispatch(getAllFiles(true)))
        .catch((error) => {
          console.error('Error writing document: ', error);
        });
    };
  }
}

export function updateAthleteFile(file_id, file) {
  let uid = getUid();
  if (uid) {
    return async (dispatch) => {
      return firebase
        .firestore()
        .collection('files')
        .doc(file_id)
        .set(
          {
            ...file,
          },
          { merge: true }
        )
        .then(() => dispatch(getAllFiles(true)))
        .catch((error) => {
          console.error('Error writing document: ', error);
        });
    };
  }
}

export function deleteAthlete(athlete_id) {
  let uid = getUid();
  if (uid) {
    return async (dispatch) => {
      return firebase
        .firestore()
        .collection('athletes')
        .doc(athlete_id)
        .delete()
        .then(() => dispatch(getAllAthletes(true)));
    };
  }
}

export function updateEventRoles(athlete_id, event_id, role_id, add = true) {
  let uid = getUid();
  if (uid) {
    return async (dispatch) => {
      let op = firebase.firestore.FieldValue.arrayUnion(role_id);
      if (!add) op = firebase.firestore.FieldValue.arrayRemove(role_id);
      return firebase
        .firestore()
        .collection('events')
        .doc(event_id)
        .update({
          ['event_roles.' + athlete_id]: op,
        })
        .then(() => dispatch(getEvent(event_id)));
    };
  }
}

export function addEffortContribution(athlete_name, event_id, amount) {
  let uid = getUid();
  if (uid) {
    return async (dispatch) => {
      return firebase
        .firestore()
        .collection('effort_contributions')
        .doc(event_id)
        .set(
          {
            [athlete_name]: amount,
          },
          { merge: true }
        )
        .then(() => dispatch(getEffortContributions(event_id)));
    };
  }
}

export function getEffortContributions(event_id) {
  let uid = getUid();
  if (uid) {
    return async (dispatch) => {
      return firebase
        .firestore()
        .collection('effort_contributions')
        .doc(event_id)
        .get()
        .then((doc) =>
          dispatch({
            type: ADD_EFFORT_CONTRIBUTIONS,
            event_id: event_id,
            data: doc.data(),
          })
        );
    };
  }
}

export function downloadAthleteReadinessReport(athlete_keys) {
  let uid = getUid();
  if (uid) {
    return async (dispatch, getState) => {
      const min_fitness = getState().firestore.selected_event.required_fitness;
      const min_skill = getState().firestore.selected_event.required_skill;
      const min_training_rides =
        getState().firestore.selected_event.required_training_rides;
      const rows = await Promise.all(
        getState()
          .firestore.athletes.filter(
            (a) =>
              athlete_keys === undefined || athlete_keys.includes(a.athlete_key)
          )
          .map(async (a) => {
            const files = {};
            await firebase
              .firestore()
              .collection('files')
              .where('athleteId', '==', a?.athlete_key)
              .get()
              .then((results) =>
                results.docs.map(
                  (doc) =>
                    new Promise((resolve) => {
                      const file = doc.data();
                      const title = file?.fileTitle;
                      const expiry = file?.expiry?.toDate();
                      if (title && expiry) {
                        if (!(title in files) || files[title] === 'Expired') {
                          if (new Date() > expiry) {
                            files[title] = 'Expired';
                          } else {
                            files[title] = 'Valid';
                          }
                        }
                      }
                      resolve();
                    })
                )
              )
              .catch((error) => {
                console.warn('No files for ' + a?.athlete_key);
              });
            const event_roles = getState()
              .firestore.app_settings.event_roles.filter((role) =>
                (
                  getState().firestore.selected_event.event_roles[
                    a?.athlete_key
                  ] || []
                ).includes(role.id)
              )
              .map((r) => r.text);
            const row = {
              ID: a?.athlete_key,
              Rider: event_roles.includes('Rider'),
              'Support Crew': event_roles.includes('Support Crew'),
              'No Event Role': event_roles.length === 0,
              'First Name': a?.firstname?.trim(),
              'Last Name': a?.lastname?.trim(),
              'Full Name': `${a?.firstname?.trim()} ${a?.lastname?.trim()}`,
              Email: getState().firestore.policies.view_email_and_mobile
                ? a?.email
                : '',
              State: a?.athlete_state,
              'Training Hub': a?.training_hub,
              'Grassrootz ID': a?.grassrootz_athlete_id,
              'Grassrootz Connection': a?.grassrootz_athlete_id
                ? 'Connected'
                : 'Not Connected',
              'Strava URL': a?.id
                ? `https://www.strava.com/athletes/${a?.id}`
                : '',
              'Strava Connection': a?.id ? 'Connected' : 'Not Connected',
              'Self-nominated Skill Level': a?.self_nominated_rider_level || 0,
              'Assessed Skill Level': a?.skill_level || 0,
              'Assessed Fitness Level': a?.rider_level || 0,
              'Predicted Fitness Level': a?.predicted_rider_level || 0,
              'Number of Detected Training Rides':
                a?.detected_training_rides?.length || 0,
              'Number of Manual Training Rides':
                a?.manual_training_rides?.length || 0,
              'Number of Distinct Training Rides': Array.from(
                new Set([
                  ...(a?.detected_training_rides || []),
                  ...(a?.manual_training_rides || []),
                ])
              ).length,
              'Number of Recent Training Rides': a?.calcTrainingRides(
                getState().firestore.app_settings.training_ride_params
                  .ride_history
              ),
            };
            return {
              ...row,
              ...files,
              'Ready for Tour':
                row?.['Support Crew'] ||
                (row?.['Assessed Skill Level'] >= min_skill &&
                  (row?.['Assessed Fitness Level'] >= min_fitness ||
                    (!row?.['Assessed Fitness Level'] &&
                      row?.['Predicted Fitness Level'] >= min_fitness)) &&
                  (row?.['Number of Recent Training Rides'] >=
                    min_training_rides ||
                    a?.training_ride_exempt))
                  ? 'Yes'
                  : 'No',
            };
          })
      );
      const csv = Papa.unparse(rows, {
        encoding: 'utf8',
        columns: [
          'ID',
          'Rider',
          'Support Crew',
          'No Event Role',
          'First Name',
          'Last Name',
          'Full Name',
          'Email',
          'State',
          'Training Hub',
          'Grassrootz ID',
          'Grassrootz Connection',
          'Strava URL',
          'Strava Connection',
          'Self-nominated Skill Level',
          'Assessed Skill Level',
          'Assessed Fitness Level',
          'Predicted Fitness Level',
          'Number of Detected Training Rides',
          'Number of Manual Training Rides',
          'Number of Distinct Training Rides',
          'Number of Recent Training Rides',
          'Bike service check',
          'Medical Form',
          'Medical NDA',
          'Working with Children',
          'Ready for Tour',
        ],
      });
      const event_name =
        athlete_keys === undefined
          ? 'Hub'
          : getState().firestore.selected_event.name;
      const uri = 'data:text/csv,' + encodeURIComponent(csv);
      const link = document.createElement('a');
      link.href = uri;
      link.download =
        event_name.replace(/ /g, '_') +
        new Date()
          .toLocaleString('en-AU')
          .replace(',', '')
          .replace(/:/g, '_')
          .replace(/ /g, '_') +
        '.csv';
      link.click();
    };
  }
}
