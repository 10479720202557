import React, { useEffect, useState } from 'react';

const images = [
  require('assets/sponsors/fluid.svg'),
  require('assets/sponsors/skyline.svg'),
];

export const SponsorLogos = () => {
  const [img, setImg] = useState(images[0]);
  useEffect(() => {
    const id = setInterval(() => {
      const i = images.indexOf(img) + 1;
      setImg(images[i % images.length]);
    }, 3000);
    return () => clearInterval(id);
  });
  return <img 
    style={{
      width: '80px',
      height: '80px',
      objectFit: 'contain',
    }}
    alt={'SponsorLogo'}  
    src={img} 
  />;
};
