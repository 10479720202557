import React from 'react';
import { useSelector } from 'react-redux';

import { Input, Button, Badge } from 'reactstrap';

import { SettingsList } from 'components/SettingsList';
import Dropdown from 'components/Dropdown';

import { createSharePointFolder } from 'store/storage/actions';

export const FormTypes = ['Medical', 'Working with children'];

export const AthleteFilesList = (props) => {
  const defaultItem = {
    fileTitle: '',
    isEventLocked: false,
    eventRoleId: null,
  };

  const [newItem, setNewItem] = React.useState(defaultItem);

  const genIcon = (c, d) => {
    const role = event_roles
      .filter((r) => r.id === c || r.id === false)
      .slice(-1)[0];
    return (
      <>
        <i className={role.icon + role.color + d}></i>
      </>
    );
  };

  const generateFileId = (length) => {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const event_roles = [
    {
      id: false,
      text: 'None',
    },
    ...useSelector((state) => state.firestore.app_settings.event_roles),
  ];

  const removeItem = (i) => {
    if (props.isEvent) {
      props.onChange(props.list.splice(i, 1));
    } else {
      props.remove(props.list[i]);
    }
  };

  return (
    <SettingsList
      {...props}
      list={
        props.list.length
          ? props.list.map((i) => (
              <>
                {genIcon(i.eventRoleId, 'fa-lg mr-2')}
                {i.fileTitle}
                {i.isSensitive && (
                  <Badge className="ml-2 text-dark bg-yellow">Sensitive</Badge>
                )}
                {i.eventRoleId &&
                  !event_roles.map((er) => er.id).includes(i.eventRoleId) && (
                    <Badge color="warning" className="ml-2">
                      Invalid event role
                    </Badge>
                  )}
              </>
            ))
          : {}
      }
      remove={removeItem}
    >
      <Input
        className="text-dark"
        value={newItem.fileTitle}
        onChange={(e) => setNewItem({ ...newItem, fileTitle: e.target.value })}
      />
      <label className="text-dark mb-2 btn btn-outline-transparent shadow-none btn "></label>
      <Dropdown
        list={FormTypes}
        value={`Form type: ${newItem.form_type ? newItem.form_type : 'None'}`}
        onClick={(v) => {
          setNewItem({ ...newItem, form_type: v });
        }}
      />
      {!props.isEvent && (
        <label className="text-dark mb-2 btn btn-outline-transparent shadow-none btn ">
          <input
            className="mr-2"
            type="checkbox"
            defaultValue={newItem.isEventLocked}
            onChange={(e) =>
              setNewItem({
                ...newItem,
                isEventLocked: e.target.checked,
              })
            }
          />
          Required for event role
        </label>
      )}
      {(props.isEvent || newItem.isEventLocked) && (
        <Dropdown
          list={event_roles}
          labels={event_roles.map((r) => (
            <>
              <i className={r.icon + r.color + ' mr-2'} />
              {r.text}
            </>
          ))}
          value={
            'Event role: ' +
            [
              ...event_roles
                .filter((r) => r.id === newItem.eventRoleId || r.id === false)
                .map((r) => r.text),
            ].slice(-1)[0]
          }
          onClick={(r) => {
            setNewItem({ ...newItem, eventRoleId: r.id });
          }}
        />
      )}
      <Button
        className="float-right shadow-none py-0 btn-transparent px-3 mr--2"
        color="white"
        onClick={() => {
          if (newItem.fileTitle && newItem.fileTitle !== '') {
            if (props.isEvent) {
              newItem.fileId = generateFileId(12);
              newItem.isEventLocked = props.isEvent ? props.isEvent : false;
              props.onChange(
                props.list.length
                  ? props.list.push(newItem)
                  : new Array(newItem)
              );
            } else {
              createSharePointFolder(newItem.fileTitle, newItem.form_type);
              props.add(newItem);
            }
            setNewItem(defaultItem);
          }
        }}
      >
        <i className="fas fa-plus"></i>
      </Button>
    </SettingsList>
  );
};
