/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import Index from "views/Index.js";
import Profile from 'views/Profile.js';
import Challenges from 'views/Challenges.js';
// import Maps from "views/examples/Maps.js";
import Register from 'views/Register.js';
import EmailVerification from 'views/EmailVerification.js';
import Login from 'views/Login.js';
// import Tables from "views/examples/Tables.js";
// import Icons from "views/examples/Icons.js";
import AppSettings from 'views/AppSettings.js';
import ManageEvents from 'views/ManageEvents.js';
import ManageGroups from 'views/ManageGroups.js';
import ChallengeLeaderboard from 'views/ChallengeLeaderboard';
import ParticipantLeaderboard from 'views/ParticipantLeaderboard';
import FundraisingLeaderboard from 'views/FundraisingLeaderboard';
import ManageAthletes from 'views/ManageAthletes';
import ProfileSettings from 'views/ProfileSettings';

var routes = [
  {
    path: '/login',
    name: 'Login',
    icon: 'ni ni-key-25 text-info',
    component: Login,
    layout: '/auth',
    policy: 'default',
  },
  {
    path: '/register',
    name: 'Register',
    icon: 'ni ni-circle-08 text-pink',
    component: Register,
    layout: '/auth',
    policy: 'default',
  },
  {
    path: '/emailverify',
    name: 'Email Verification',
    icon: 'ni ni-circle-08 text-pink',
    component: EmailVerification,
    layout: '/signup',
    policy: 'default',
  },
  {
    path: '/index',
    name: 'Home',
    icon: 'ni ni-circle-08 text-dark',
    component: Profile,
    layout: '/admin',
    policy: 'default',
  },
  {
    path: '/challenges',
    name: 'Challenges',
    icon: 'ni ni-user-run text-red',
    component: Challenges,
    layout: '/admin',
    policy: 'default',
  },
  {
    path: '/challenge-leaderboard',
    name: 'Challenge Leaderboard',
    icon: 'ni ni-trophy text-yellow',
    component: ChallengeLeaderboard,
    layout: '/admin',
    policy: 'default',
  },
  {
    path: '/participant-leaderboard',
    name: 'Participant Leaderboard',
    icon: 'fa fa-users text-green',
    component: ParticipantLeaderboard,
    layout: '/admin',
    policy: 'default',
  },
  {
    path: '/fundraising-leaderboard',
    name: 'Fundraising Leaderboard',
    icon: 'fa fa-hand-holding-usd text-blue',
    component: FundraisingLeaderboard,
    layout: '/admin',
    policy: 'default',
  },
  {
    path: '/manage-events',
    name: 'Manage Events',
    icon: 'ni ni-notification-70 text-orange',
    component: ManageEvents,
    layout: '/admin',
    policy: 'edit_events',
  },
  {
    path: '/manage-groups',
    name: 'Manage Groups',
    icon: 'fa fa-users text-indigo',
    component: ManageGroups,
    layout: '/admin',
    policy: 'edit_teams',
  },
  {
    path: '/manage-athletes',
    name: 'Manage Athletes',
    icon: 'fas fa-user-cog text-dark',
    component: ManageAthletes,
    layout: '/admin',
    policy: 'view_athletes',
  },
  {
    path: '/profile-settings',
    name: 'Profile Settings',
    icon: 'fas fa-address-card text-dark',
    component: ProfileSettings,
    layout: '/admin',
    policy: 'default',
  },
  {
    path: '/app-settings',
    name: 'App Settings',
    icon: 'ni ni-settings-gear-65 text-dark',
    component: AppSettings,
    layout: '/admin',
    policy: 'edit_app_settings',
  },
  // {
  //   path: '/rider-level-dashboard',
  //   name: 'Rider Level Dashboard',
  //   icon: 'fas fa-tachometer-alt text-dark',
  //   component: RiderLevelDashboard,
  //   layout: '/admin',
  //   policy: 'view_power_bi',
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   layout: "/admin"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin"
  // }
];
export default routes;
