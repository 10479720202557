/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

// reactstrap components
import {
  Card,
  Container,
  Row,
  Col,
  Table,
  CardBody,
  Button,
  Modal,
  CardHeader,
  Label,
  Input,
} from 'reactstrap';
// react plugin used to create charts

// core components
import Header from 'components/Headers/Header.js';

import { connect } from 'react-redux';
import {
  selectEvent,
  getGrassrootzFundraisingLeaderboard,
  addEffortContribution,
  getEffortContributions,
} from 'store/firestore/actions.js';

const fmtr = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const mapState = (state) => state;

const mapDispatch = {
  selectEvent,
  getGrassrootzFundraisingLeaderboard,
  addEffortContribution,
  getEffortContributions,
};

class FundraisingLeaderboard extends React.Component {
  constructor(props) {
    super(props);
    const event_id = this.props.firestore.selected_event.id;
    props.getGrassrootzFundraisingLeaderboard(event_id);
    props.getEffortContributions(event_id);
    this.state = {};
  }

  render() {
    const event_id = this.props.firestore.selected_event.id;
    const fund_raising_data =
      this.props.firestore.grassrootz_fundraising_leaderboard[event_id]?.data;
    const effort_contributions =
      this.props.firestore.effort_contributions?.[event_id];
    const complete_fund_raising_data = fund_raising_data
      ?.map((d, i) => ({
        ...d,
        effort_contribution: effort_contributions?.[d.name] || 0,
        total:
          parseFloat(effort_contributions?.[d.name] || 0) +
          parseFloat(d.raisedAmount),
      }))
      .sort((a, b) => b.total - a.total);
    const total_effort_contributions = Object.values(
      this.props.firestore.effort_contributions?.[event_id] || {}
    ).reduce((psum, a) => psum + a, 0);
    const show_effort_contributions =
      total_effort_contributions > 0 ||
      this.props.firestore.policies.add_effort_contribution;
    return (
      <>
        <Header
          title="Fundraising Leaderboard"
          dropdown
          selected={this.props.firestore.selected_event.name}
          onSelect={(key) => this.props.selectEvent(key)}
          items={this.props.firestore.events}
          syncAction={() =>
            this.props.getGrassrootzFundraisingLeaderboard(
              this.props.firestore.selected_event.id
            )
          }
        />
        <Container className="" style={{ maxWidth: '1200px' }} fluid>
          <Row>
            <>
              {fund_raising_data ? (
                fund_raising_data.length > 0 ? (
                  <Col xs="12">
                    <Card className="mt-2" align="center">
                      <CardBody>
                        <img
                          src={require('assets/img/grassrootz/gr-logo.png')}
                          alt="Grassrootz logo"
                          style={{
                            height: '36px',
                            width: '200px',
                            marginBottom: '10px',
                          }}
                        />
                        <br />
                        Leaderboard generated from Grassrootz
                      </CardBody>
                    </Card>
                    <Card className="mt-2" align="center">
                      <h2 className="mb-2 mt-2 text-dark">
                        {this.props.firestore.selected_event.name}
                      </h2>
                    </Card>
                    <Card className="mt-2">
                      <Table
                        className="align-items-center table-flush text-center"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Rank</th>
                            <th scope="col">Photo</th>
                            <th scope="col">Member</th>
                            <th scope="col">Donations</th>
                            {show_effort_contributions && (
                              <>
                                <th scope="col">Effort Contribution</th>
                                <th scope="col">Raised Amount</th>
                              </>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {complete_fund_raising_data.map((fundraiser, i) => (
                            <tr key={i} className="text-dark">
                              <td>{i + 1}</td>
                              <td>
                                <img
                                  src={
                                    fundraiser.imageUrl
                                      ? fundraiser.imageUrl
                                      : 'assets/img/strava/user.png'
                                  }
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = require('assets/img/strava/user.png');
                                  }}
                                  alt="Invalid logo"
                                  style={{
                                    height: '30px',
                                    width: '30px',
                                    borderRadius: '15px',
                                  }}
                                />
                              </td>
                              <td>{fundraiser.name}</td>
                              <td>{fmtr.format(fundraiser.raisedAmount)}</td>
                              {show_effort_contributions && (
                                <>
                                  <td>
                                    {this.props.firestore.policies
                                      .add_effort_contribution ? (
                                      <Button
                                        className="btn btn-outline-transparent shadow-none bg-lighter"
                                        color="white"
                                        onClick={(e) =>
                                          this.setState({
                                            edit_effort_contribution_athlete:
                                              fundraiser.name,
                                            current_effort_contribution:
                                              effort_contributions?.[
                                                fundraiser.name
                                              ],
                                          })
                                        }
                                        style={{ fontWeight: 'normal' }}
                                      >
                                        {fmtr.format(
                                          fundraiser.effort_contribution
                                        )}
                                      </Button>
                                    ) : (
                                      fmtr.format(
                                        fundraiser.effort_contribution
                                      )
                                    )}
                                  </td>
                                  <td>{fmtr.format(fundraiser.total)}</td>
                                </>
                              )}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Card>
                  </Col>
                ) : (
                  <Col xs="12" align="center">
                    <Card className="mt-2">
                      <CardBody>
                        It looks like the admins are still connecting this event
                        with Grassrootz, check back again soon to see a
                        fundraising leaderboard!
                      </CardBody>
                    </Card>
                  </Col>
                )
              ) : (
                <Col xs="12" align="center">
                  <Card className="mt-2">
                    <CardBody>
                      Hold tight! We're crunching the numbers from Grassrootz to
                      see who is in the lead...
                    </CardBody>
                  </Card>
                </Col>
              )}
            </>
          </Row>
        </Container>
        <Modal isOpen={Boolean(this.state.edit_effort_contribution_athlete)}>
          <Card>
            <CardHeader>
              <h2 className="text-dark mb-0">{`Effort contribution for ${this.state.edit_effort_contribution_athlete}`}</h2>
            </CardHeader>
            <CardBody>
              <Label className="text-dark">Effort contribution ($)</Label>
              <Input
                type="number"
                placeholder="0"
                className="text-dark"
                onChange={(e) =>
                  this.setState({
                    current_effort_contribution: e.target.value,
                  })
                }
              />
            </CardBody>
            <Button
              onClick={(e) => {
                this.setState({ edit_effort_contribution_athlete: undefined });
                this.props.addEffortContribution(
                  this.state.edit_effort_contribution_athlete,
                  event_id,
                  this.state.current_effort_contribution
                );
                this.setState({
                  current_effort_contribution: undefined,
                });
              }}
            >
              Save
            </Button>
          </Card>
        </Modal>
      </>
    );
  }
}

export default connect(mapState, mapDispatch)(FundraisingLeaderboard);
