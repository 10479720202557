/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from 'reactstrap';
import { login, resetPassword } from 'store/auth/actions';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      forgot_password: false,
    };
  }

  handleEmailChange(e) {
    e.preventDefault();
    this.setState({ email: e.target.value });
  }

  handlePasswordChange(e) {
    e.preventDefault();
    this.setState({ password: e.target.value });
  }

  loginUser() {
    login(this.state.email, this.state.password);
  }

  forgotPassword() {
    this.setState({ forgot_password: !this.state.forgot_password });
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      autoComplete="email"
                      value={this.state.email}
                      onChange={(e) => this.handleEmailChange(e)}
                    />
                  </InputGroup>
                </FormGroup>
                {this.state.forgot_password ? null : (
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        autoComplete="password"
                        value={this.state.password}
                        onChange={(e) => this.handlePasswordChange(e)}
                      />
                    </InputGroup>
                  </FormGroup>
                )}
                <div className="text-center">
                  {this.state.forgot_password ? (
                    <Button
                      className="my-4"
                      color="dark"
                      type="button"
                      onClick={(e) =>
                        resetPassword(this.state.email).then(() =>
                          this.forgotPassword()
                        )
                      }
                    >
                      Reset my password
                    </Button>
                  ) : (
                    <Button
                      className="my-4"
                      color="dark"
                      type="button"
                      onClick={(e) => this.loginUser()}
                    >
                      Sign in
                    </Button>
                  )}
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <Button
                className="text-light bg-transparent p-0"
                color="transparent"
                styles={{ color: 'green' }}
                onClick={(e) => this.forgotPassword()}
              >
                <small>Forgot password?</small>
              </Button>
            </Col>
            <Col className="text-right" xs="6">
              <Button
                className="text-light bg-transparent p-0"
                color="transparent"
                styles={{ color: 'green' }}
                onClick={(e) => this.props.history.push('/auth/register')}
              >
                <small>Register</small>
              </Button>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export default Login;
