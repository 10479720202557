/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  CardFooter,
  InputGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Label,
  Alert,
} from 'reactstrap';
// core components

import { Link } from 'react-router-dom';

import { DateTimePicker } from 'react-widgets';

import Header from 'components/Headers/Header.js';

import { connect } from 'react-redux';
import {
  setEvent,
  getAllGroups,
  generateUid,
  isValidGrassrootzId,
} from 'store/firestore/actions';
import { defaultEvent, defaultChallenge } from 'store/firestore/reducers';
import { uploadFile } from 'store/storage/actions';

import { MultiFileUploader } from 'components/MultiFileUploader.js';
import { AthleteFilesList } from 'components/AthleteFilesList';

const mapState = (state) => state;

const mapDispatch = {
  setEvent,
  getAllGroups,
};

class ManageEvents extends React.Component {
  constructor(props) {
    super(props);
    // const [dropdownOpen, setOpen] = useState(false);
    // const toggle = () => setOpen(!dropdownOpen);
    props.getAllGroups();
    this.state = {
      selected_event: null,
      selected_event_key: null,
      uploading_training: false,
      metric_dropdown: {},
      metric_dropdown_toggle: (key) =>
        this.setState({
          metric_dropdown: {
            ...this.state.metric_dropdown,
            [key]: !this.state.metric_dropdown[key],
          },
        }),
      scope_dropdown: {},
      scope_dropdown_toggle: (key) =>
        this.setState({
          scope_dropdown: {
            ...this.state.scope_dropdown,
            [key]: !this.state.scope_dropdown[key],
          },
        }),
      group_dropdown: false,
      group_dropdown_toggle: () =>
        this.setState({ group_dropdown: !this.state.group_dropdown }),
      uploadTraining: (e) => {
        uploadFile(
          'events/' + this.state.selected_event_key + '/training_program',
          '.pdf',
          e.target.files[0]
        ).then((url) => {
          this.setState({
            uploading_training: false,
            selected_event: {
              ...this.state.selected_event,
              training_program: url,
            },
          });
        });
        this.setState({ uploading_training: true });
      },
      newChallenge: () =>
        this.setState({
          selected_event: {
            ...this.state.selected_event,
            challenges: {
              [generateUid()]: defaultChallenge,
              ...this.state.selected_event.challenges,
            },
          },
        }),
      newEvent: () =>
        this.setState({
          selected_event: { ...defaultEvent, name: '' },
          selected_event_key: null,
        }),
      selectEvent: (key) =>
        this.setState({
          selected_event: key ? this.props.firestore.events[key] : null,
          selected_event_key: key,
        }),
      saveEvent: (key) => {
        if (this.state.selected_event.name === '') {
          alert('Please enter a valid event name');
          return null;
        }
        if (
          !(
            this.state.selected_event.start_date <
            this.state.selected_event.end_date
          )
        ) {
          alert('Please enter a valid start and end date');
          return null;
        }
        if (!this.state.selected_event.group_id) {
          alert('Please select a group for the event');
          return null;
        }
        if (
          !isValidGrassrootzId(this.state.selected_event.grassrootz_event_id) &&
          !isValidGrassrootzId(this.state.selected_event.grassrootz_campaign_id)
        )
          return null;
        for (const challenge in this.state.selected_event.challenges) {
          const challenge_data =
            this.state.selected_event.challenges[challenge];
          if (challenge_data.name === '') {
            alert('Please name all challenges');
            return null;
          }
          if (challenge_data.description === '') {
            alert('Please give ' + challenge_data.name + ' a description');
            return null;
          }
          if (!/^[\d.]+$/.test(challenge_data.multiplier)) {
            alert(
              'Please give ' +
                challenge_data.name +
                ' a valid multiplier. Multipliers must be a decimal or integer number'
            );
            return null;
          }
        }
        this.props.setEvent(
          this.state.selected_event,
          this.state.selected_event.id
        );
        this.state.selectEvent(null);
      },
      setGroupId: (group_id) =>
        this.setState({
          selected_event: {
            ...this.state.selected_event,
            group_id: group_id,
          },
        }),
      selectMetric: (key, metric) =>
        this.setState({
          selected_event: {
            ...this.state.selected_event,
            challenges: {
              ...this.state.selected_event.challenges,
              [key]: {
                ...this.state.selected_event.challenges[key],
                metric: metric,
              },
            },
          },
        }),
      setScope: (key, scope) =>
        this.setState({
          selected_event: {
            ...this.state.selected_event,
            challenges: {
              ...this.state.selected_event.challenges,
              [key]: {
                ...this.state.selected_event.challenges[key],
                scope: {
                  ...this.state.selected_event.challenges[key].scope,
                  ...scope,
                },
              },
            },
          },
        }),
      setActivityTypes: (key, activity_types) =>
        this.setState({
          selected_event: {
            ...this.state.selected_event,
            challenges: {
              ...this.state.selected_event.challenges,
              [key]: {
                ...this.state.selected_event.challenges[key],
                activity_types: {
                  ...this.state.selected_event.challenges[key].activity_types,
                  ...activity_types,
                },
              },
            },
          },
        }),
    };
  }

  render() {
    return (
      <>
        <Header title="Manage Events" />
        {/* Page content */}
        <Container className="" style={{ maxWidth: '1200px' }} fluid>
          <Row>
            {this.state.selected_event ? (
              <Col xs="12" className="pt-2">
                <Card className="shadow">
                  <CardHeader className="d-flex">
                    <h2 className="mr-auto pt-2 text-dark">Manage Event</h2>
                    <Button
                      color="danger"
                      className="btn-outline-danger shadow-none"
                      onClick={() => {
                        this.props.setEvent(
                          {
                            ...this.state.selected_event,
                            deleted: true,
                          },
                          this.state.selected_event.id
                        );
                        this.state.selectEvent(null);
                      }}
                    >
                      Archive
                    </Button>
                  </CardHeader>
                  <CardBody>
                    <h4 className="text-dark"> Name </h4>
                    <InputGroup className="mb-4">
                      <Input
                        className="text-dark p-2"
                        value={this.state.selected_event.name}
                        onChange={(e) => {
                          this.setState({
                            selected_event: {
                              ...this.state.selected_event,
                              name: e.target.value,
                            },
                          });
                        }}
                      ></Input>
                    </InputGroup>
                    <h4 className="text-dark"> Start Date </h4>
                    <DateTimePicker
                      className="mb-4"
                      value={this.state.selected_event.start_date}
                      onChange={(val) => {
                        this.setState({
                          selected_event: {
                            ...this.state.selected_event,
                            start_date: val,
                          },
                        });
                      }}
                    />

                    <h4 className="text-dark"> End Date </h4>
                    <DateTimePicker
                      className="mb-4"
                      value={this.state.selected_event.end_date}
                      onChange={(val) => {
                        this.setState({
                          selected_event: {
                            ...this.state.selected_event,
                            end_date: val,
                          },
                        });
                      }}
                    />

                    <h4 className="text-dark"> Event group </h4>
                    <UncontrolledDropdown
                      className="mr-auto mb-4"
                      isOpen={this.state.group_dropdown}
                      toggle={() => this.state.group_dropdown_toggle()}
                    >
                      <DropdownToggle
                        className="btn btn-outline-transparent shadow-none float-right"
                        color="white"
                      >
                        <span className="font-weight-normal text-dark ml--2">
                          {this.state.selected_event.group_id
                            ? this.props.firestore.groups[
                                this.state.selected_event.group_id
                              ].name
                            : 'No group selected'}
                          <i
                            className="ni ni-bold-down pl-2 mr--2"
                            style={{
                              transform: 'translate(0, 3px)',
                            }}
                          />
                        </span>
                      </DropdownToggle>
                      <DropdownMenu>
                        {Object.keys(this.props.firestore.groups)
                          .filter(
                            (key) =>
                              this.props.firestore.groups[key].deleted !== true
                          )
                          .map((group_id, i) => (
                            <DropdownItem
                              key={i}
                              onClick={(e) => this.state.setGroupId(group_id)}
                            >
                              {this.props.firestore.groups[
                                group_id
                              ].name.concat(
                                this.props.firestore.groups[group_id].deleted
                                  ? ' [archived]'
                                  : ''
                              )}
                            </DropdownItem>
                          ))}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <Row className="mb-4">
                      <Col>
                        <h4 className="text-dark"> Required skill level </h4>
                        <Input
                          className="text-dark p-2"
                          value={this.state.selected_event.required_skill}
                          onChange={(e) => {
                            this.setState({
                              selected_event: {
                                ...this.state.selected_event,
                                required_skill: e.target.value,
                              },
                            });
                          }}
                        ></Input>
                      </Col>
                      <Col>
                        <h4 className="text-dark">
                          {' '}
                          Required rider fitness level{' '}
                        </h4>
                        <Input
                          className="text-dark p-2"
                          value={this.state.selected_event.required_fitness}
                          onChange={(e) => {
                            this.setState({
                              selected_event: {
                                ...this.state.selected_event,
                                required_fitness: e.target.value,
                              },
                            });
                          }}
                        ></Input>
                      </Col>
                      <Col>
                        <h4 className="text-dark"> Required training rides </h4>
                        <Input
                          className="text-dark p-2"
                          value={
                            this.state.selected_event.required_training_rides
                          }
                          onChange={(e) => {
                            this.setState({
                              selected_event: {
                                ...this.state.selected_event,
                                required_training_rides: e.target.value,
                              },
                            });
                          }}
                        ></Input>
                      </Col>
                      <Col xs={12}>
                        <h4 className="text-dark mt-4">Grassrootz Event Id</h4>
                        <Input
                          className="text-dark p-2"
                          value={this.state.selected_event.grassrootz_event_id}
                          onChange={(e) =>
                            this.setState({
                              selected_event: {
                                ...this.state.selected_event,
                                grassrootz_event_id: e.target.value,
                              },
                            })
                          }
                        />
                      </Col>
                      <Col xs={12}>
                        <h4 className="text-dark mt-4">
                          Grassrootz Overall Campaign Id
                        </h4>
                        <p>
                          Each event will have their own specific id e.g.
                          "suncorp-spirit-to-cure-ride" but will be under a
                          campaign id e.g. "spirit-to-cure-2021", which we need
                          to filter results from Grassrootz
                        </p>
                        <Input
                          className="text-dark p-2"
                          value={
                            this.state.selected_event.grassrootz_campaign_id
                          }
                          onChange={(e) =>
                            this.setState({
                              selected_event: {
                                ...this.state.selected_event,
                                grassrootz_campaign_id: e.target.value,
                              },
                            })
                          }
                        />
                      </Col>
                    </Row>

                    <MultiFileUploader
                      title="ROLE specific files for this event"
                      files={this.state.selected_event.files || {}}
                      onChange={(files) => {
                        this.setState({
                          selected_event: {
                            ...this.state.selected_event,
                            files: files,
                          },
                        });
                      }}
                    />

                    <Row className="my-4">
                      <Col>
                        <AthleteFilesList
                          title="Files for ATHLETES TO UPLOAD"
                          isEvent={true}
                          list={
                            this.props.firestore.selected_event.athlete_files
                          }
                          onChange={(athlete_files) => {
                            this.setState({
                              selected_event: {
                                ...this.state.selected_event,
                                athlete_files: athlete_files,
                              },
                            });
                          }}
                        />
                      </Col>
                    </Row>
                    {this.props.firestore.policies.edit_challenges && (
                      <>
                        <div className="d-flex mt-5">
                          <h2 className="text-dark mr-auto pt-2">Challenges</h2>
                          <Button
                            onClick={(e) => this.state.newChallenge()}
                            className="btn btn-outline-transparent px-3"
                            color="success"
                            style={{ borderRadius: '50%' }}
                          >
                            <i className="fas fa-plus"></i>
                          </Button>
                        </div>
                        {Object.keys(this.state.selected_event.challenges).map(
                          (key, i) =>
                            this.state.selected_event.challenges[key]
                              .deleted ? null : (
                              <Card key={i} className="p-4 mt-4">
                                <h4 className="text-dark"> Name </h4>
                                <Input
                                  className="text-dark p-2 mb-4"
                                  value={
                                    this.state.selected_event.challenges[key]
                                      .name
                                  }
                                  onChange={(e) => {
                                    this.setState({
                                      selected_event: {
                                        ...this.state.selected_event,
                                        challenges: {
                                          ...this.state.selected_event
                                            .challenges,
                                          [key]: {
                                            ...this.state.selected_event
                                              .challenges[key],
                                            name: e.target.value,
                                          },
                                        },
                                      },
                                    });
                                  }}
                                ></Input>
                                <h4 className="text-dark"> Description </h4>
                                <Input
                                  className="text-dark p-2 mb-4"
                                  value={
                                    this.state.selected_event.challenges[key]
                                      .description
                                  }
                                  onChange={(e) => {
                                    this.setState({
                                      selected_event: {
                                        ...this.state.selected_event,
                                        challenges: {
                                          ...this.state.selected_event
                                            .challenges,
                                          [key]: {
                                            ...this.state.selected_event
                                              .challenges[key],
                                            description: e.target.value,
                                          },
                                        },
                                      },
                                    });
                                  }}
                                ></Input>
                                <Row>
                                  <Col xs="12" lg="6">
                                    <h4 className="text-dark"> Start Date </h4>
                                    <DateTimePicker
                                      className="mb-4"
                                      value={
                                        this.state.selected_event.challenges[
                                          key
                                        ].start_date
                                      }
                                      onChange={(val) => {
                                        this.setState({
                                          selected_event: {
                                            ...this.state.selected_event,
                                            challenges: {
                                              ...this.state.selected_event
                                                .challenges,
                                              [key]: {
                                                ...this.state.selected_event
                                                  .challenges[key],
                                                start_date: val,
                                              },
                                            },
                                          },
                                        });
                                      }}
                                    />
                                  </Col>
                                  <Col xs="12" lg="6">
                                    <h4 className="text-dark"> End Date </h4>
                                    <DateTimePicker
                                      className="mb-4"
                                      value={
                                        this.state.selected_event.challenges[
                                          key
                                        ].end_date
                                      }
                                      onChange={(val) => {
                                        this.setState({
                                          selected_event: {
                                            ...this.state.selected_event,
                                            challenges: {
                                              ...this.state.selected_event
                                                .challenges,
                                              [key]: {
                                                ...this.state.selected_event
                                                  .challenges[key],
                                                end_date: val,
                                              },
                                            },
                                          },
                                        });
                                      }}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <h4 className="text-dark"> Metric </h4>
                                    <UncontrolledDropdown
                                      className="mr-auto"
                                      isOpen={this.state.metric_dropdown[key]}
                                      toggle={() =>
                                        this.state.metric_dropdown_toggle(key)
                                      }
                                    >
                                      <DropdownToggle
                                        className="btn btn-outline-transparent shadow-none float-right"
                                        color="white"
                                      >
                                        <span className="font-weight-normal text-dark ml--2">
                                          {
                                            this.state.selected_event
                                              .challenges[key].metric
                                          }
                                          <i
                                            className="ni ni-bold-down pl-2 mr--2"
                                            style={{
                                              transform: 'translate(0, 3px)',
                                            }}
                                          />
                                        </span>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        {this.props.firestore.app_settings.tracked_strava_metrics.map(
                                          (metric) => (
                                            <DropdownItem
                                              key={metric}
                                              onClick={(e) =>
                                                this.state.selectMetric(
                                                  key,
                                                  metric
                                                )
                                              }
                                            >
                                              {metric}
                                            </DropdownItem>
                                          )
                                        )}
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </Col>
                                  <Col>
                                    <h4 className="text-dark"> Multiplier </h4>
                                    <Input
                                      className="text-dark p-2 mb-4"
                                      value={
                                        this.state.selected_event.challenges[
                                          key
                                        ].multiplier
                                      }
                                      onChange={(e) => {
                                        this.setState({
                                          selected_event: {
                                            ...this.state.selected_event,
                                            challenges: {
                                              ...this.state.selected_event
                                                .challenges,
                                              [key]: {
                                                ...this.state.selected_event
                                                  .challenges[key],
                                                multiplier: e.target.value,
                                              },
                                            },
                                          },
                                        });
                                      }}
                                    ></Input>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col className="text-dark">
                                    <h4 className="text-dark">
                                      {' '}
                                      Activity Types{' '}
                                    </h4>
                                    <Row className="ml-4">
                                      <Col>
                                        <Label check>
                                          <Input
                                            type="checkbox"
                                            checked={
                                              this.state.selected_event
                                                .challenges[key].activity_types
                                                .any
                                            }
                                            onChange={(e) =>
                                              this.state.setActivityTypes(key, {
                                                any: e.target.checked,
                                              })
                                            }
                                          />{' '}
                                          Any
                                        </Label>
                                        <br />
                                        <Label check>
                                          <Input
                                            type="checkbox"
                                            checked={
                                              this.state.selected_event
                                                .challenges[key].activity_types
                                                .bike
                                            }
                                            onChange={(e) =>
                                              this.state.setActivityTypes(key, {
                                                bike: e.target.checked,
                                              })
                                            }
                                          />{' '}
                                          Bike
                                        </Label>
                                        <br />
                                        <Label check>
                                          <Input
                                            type="checkbox"
                                            checked={
                                              this.state.selected_event
                                                .challenges[key].activity_types
                                                .foot
                                            }
                                            onChange={(e) =>
                                              this.state.setActivityTypes(key, {
                                                foot: e.target.checked,
                                              })
                                            }
                                          />{' '}
                                          Foot
                                        </Label>
                                        <br />
                                        <Label check>
                                          <Input
                                            type="checkbox"
                                            checked={
                                              this.state.selected_event
                                                .challenges[key].activity_types
                                                .virtual
                                            }
                                            onChange={(e) =>
                                              this.state.setActivityTypes(key, {
                                                virtual: e.target.checked,
                                              })
                                            }
                                          />{' '}
                                          Virtual
                                        </Label>
                                        <br />
                                        <Label check>
                                          <Input
                                            type="checkbox"
                                            checked={
                                              this.state.selected_event
                                                .challenges[key].activity_types
                                                .swim
                                            }
                                            onChange={(e) =>
                                              this.state.setActivityTypes(key, {
                                                swim: e.target.checked,
                                              })
                                            }
                                          />{' '}
                                          Swim
                                        </Label>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col className="text-dark">
                                    <h4 className="text-dark"> Scope </h4>
                                    <Row className="ml-4">
                                      <Col>
                                        <Label check>
                                          <Input
                                            type="checkbox"
                                            checked={
                                              this.state.selected_event
                                                .challenges[key].scope.public
                                            }
                                            onChange={(e) =>
                                              this.state.setScope(key, {
                                                public: e.target.checked,
                                              })
                                            }
                                          />{' '}
                                          Public
                                        </Label>
                                        <br />
                                        <Label check>
                                          <Input
                                            type="checkbox"
                                            checked={
                                              this.state.selected_event
                                                .challenges[key].scope.manual
                                            }
                                            onChange={(e) =>
                                              this.state.setScope(key, {
                                                manual: e.target.checked,
                                              })
                                            }
                                          />{' '}
                                          Manual
                                        </Label>
                                        <br />
                                        <Label check>
                                          <Input
                                            type="checkbox"
                                            checked={
                                              this.state.selected_event
                                                .challenges[key].scope.private
                                            }
                                            onChange={(e) =>
                                              this.state.setScope(key, {
                                                private: e.target.checked,
                                              })
                                            }
                                          />{' '}
                                          Private
                                        </Label>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col xs="3" className="d-flex">
                                    <Button
                                      color="danger"
                                      className="btn-outline-danger shadow-none ml-auto mt-auto"
                                      onClick={() => {
                                        this.setState({
                                          selected_event: {
                                            ...this.state.selected_event,
                                            challenges: {
                                              ...this.state.selected_event
                                                .challenges,
                                              [key]: {
                                                ...this.state.selected_event
                                                  .challenges[key],
                                                deleted: true,
                                              },
                                            },
                                          },
                                        });
                                      }}
                                    >
                                      <i className="fas fa-trash"></i>
                                    </Button>
                                  </Col>
                                </Row>
                              </Card>
                            )
                        )}
                      </>
                    )}
                  </CardBody>
                  <CardFooter>
                    <Button onClick={(e) => this.state.selectEvent(null)}>
                      Cancel
                    </Button>
                    <Button
                      color="success"
                      className="float-right"
                      onClick={(e) => {
                        this.state.saveEvent();
                      }}
                    >
                      Save
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            ) : (
              <Col xs="12" className="pt-2">
                <Card className="shadow">
                  <CardBody>
                    <div className="d-flex align-middle py-4">
                      <Alert color="success">
                        <h4 className="alert-heading">Creating events</h4>
                        <p>
                          Before creating an event, please make sure a group
                          exists to be used for the event. This can be done via
                          the{' '}
                          <Link
                            to="/admin/manage-groups"
                            className="alert-link"
                          >
                            Manage Groups
                          </Link>{' '}
                          menu. Once the group exists and teams have been
                          allocated, you may create a new event using the button
                          below.
                        </p>
                        <Button
                          onClick={(e) => this.state.newEvent()}
                          className="m-auto btn btn-outline-transparent float-right"
                          color="white"
                        >
                          Create a new event
                        </Button>
                      </Alert>
                    </div>
                    {Object.keys(this.props.firestore.events).map((key, i) => (
                      <Card key={i} className="p-4 mb-4">
                        <div className="d-flex align-middle">
                          <p className="mr-auto my-auto pt-2 pr-2 text-dark">
                            {this.props.firestore.events[key].name}
                          </p>
                          <Button
                            onClick={(e) => this.state.selectEvent(key)}
                            className="btn btn-outline-transparent float-right"
                            color="white"
                          >
                            Manage
                          </Button>
                        </div>
                      </Card>
                    ))}
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </>
    );
  }
}

export default connect(mapState, mapDispatch)(ManageEvents);
