/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

// reactstrap components
import { Button, Card, CardBody, Container, Row, Col, Input } from 'reactstrap';
// core components
import Dropdown from 'components/Dropdown';
import Header from 'components/Headers/Header.js';
import { AthleteFilesList } from 'components/AthleteFilesList.js';
import { SettingsList } from 'components/SettingsList.js';
import { MultiFileUploader } from 'components/MultiFileUploader.js';

import { connect } from 'react-redux';
import {
  updateAppSettings,
  addTrainingHub,
  removeTrainingHub,
  addEventRole,
  removeEventRole,
  addSettingsAthleteFile,
  removeSettingsAthleteFile,
} from 'store/firestore/actions';

const mapState = (state) => state;

const mapDispatch = {
  updateAppSettings,
  addTrainingHub,
  removeTrainingHub,
  addEventRole,
  removeEventRole,
  addSettingsAthleteFile,
  removeSettingsAthleteFile,
};

// MAYBE TRY STORE THE WHOLE THING AS A STRING INSTEAD OF STORING AN OBJECT

const EventRolesList = (props) => {
  const font_awesome_icons = [
    'compass ',
    'heartbeat ',
    'wrench ',
    'shipping-fast ',
    'car ',
    'coffee ',
    'medal ',
    'user-friends ',
    'users ',
    'biking ',
    'thumbs-up',
  ].map((item) => 'fas fa-' + item);
  const colors = [
    ' text-dark ',
    ' text-green ',
    ' text-red ',
    ' text-blue ',
    ' text-purple ',
    ' text-orange ',
    ' text-pink ',
    ' text-yellow ',
    ' text-cyan ',
    ' text-teal ',
  ];
  const defaultItem = {
    text: '',
    icon: font_awesome_icons[0],
    color: colors[0],
  };
  const [newItem, setNewItem] = React.useState(defaultItem);
  const genIcon = (c) => <i className={c}></i>;
  const removeItem = (i) => props.remove(props.list[i]);
  return (
    <SettingsList
      {...props}
      list={props.list.map((i) => (
        <>
          {genIcon(i.icon + i.color + ' fa-lg mr-2')}
          {i.text}{' '}
        </>
      ))}
      remove={removeItem}
    >
      <Input
        className="text-dark"
        value={newItem.text}
        onChange={(e) => setNewItem({ ...newItem, text: e.target.value })}
      />
      <Dropdown
        onClick={(value) => setNewItem({ ...newItem, icon: value })}
        labels={font_awesome_icons.map((item) => genIcon(item))}
        list={font_awesome_icons}
        value={genIcon(newItem.icon + newItem.color)}
      ></Dropdown>
      <Dropdown
        onClick={(value) => setNewItem({ ...newItem, color: value })}
        list={colors}
        labels={colors.map((color) => genIcon(newItem.icon + color))}
        value={genIcon(newItem.icon + newItem.color)}
      ></Dropdown>
      <Button
        className="float-right shadow-none py-0 btn-transparent px-3 mr--2"
        color="white"
        onClick={() => {
          if (newItem.text && newItem.text !== '') {
            props.add(newItem);
            setNewItem(defaultItem);
          }
        }}
      >
        <i className="fas fa-plus"></i>
      </Button>
    </SettingsList>
  );
};

const TrainingHubList = (props) => {
  const [newItem, setNewItem] = React.useState('');
  const removeItem = (i) => props.remove(props.list[i]);
  return (
    <SettingsList {...props} remove={removeItem}>
      <Input
        className="text-dark"
        value={newItem}
        onChange={(e) => setNewItem(e.target.value)}
      ></Input>
      <Button
        className="float-right shadow-none py-0 btn-transparent px-3 mr--2"
        color="white"
        onClick={() => {
          if (newItem !== '') {
            props.add(newItem);
            setNewItem('');
          }
        }}
      >
        <i className="fas fa-plus"></i>
      </Button>
    </SettingsList>
  );
};

class AppSettings extends React.Component {
  constructor(props) {
    super(props);
    // const [dropdownOpen, setOpen] = useState(false);
    // const toggle = () => setOpen(!dropdownOpen);
    this.state = {
      training_ride_params:
        this.props.firestore.app_settings.training_ride_params || {},
      uploading_rider_etiquette: false,
      uploading_rider_levels: false,
    };
  }

  render() {
    return (
      <>
        <Header title="App Settings" />
        {/* Page content */}
        <Container className="" style={{ maxWidth: '1200px' }} fluid>
          <Row>
            <Col xs="12">
              <Card className="mt-2 shadow">
                <CardBody className="pt-0">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>
                          <MultiFileUploader
                            title="ROLE specific files accross all events"
                            files={
                              this.props.firestore.app_settings.public_files ||
                              {}
                            }
                            onChange={(files) =>
                              this.props.updateAppSettings({
                                public_files: files,
                              })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <AthleteFilesList
                            title="Files for ATHLETES TO UPLOAD"
                            list={
                              this.props.firestore.app_settings.athlete_files
                            }
                            add={this.props.addSettingsAthleteFile}
                            remove={this.props.removeSettingsAthleteFile}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <TrainingHubList
                            title="Training Hubs"
                            list={
                              this.props.firestore.app_settings.training_hubs
                            }
                            add={this.props.addTrainingHub}
                            remove={this.props.removeTrainingHub}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <EventRolesList
                            title="Event Roles"
                            list={this.props.firestore.app_settings.event_roles}
                            add={this.props.addEventRole}
                            remove={this.props.removeEventRole}
                          />
                        </td>
                      </tr>
                      {this.props.firestore.policies
                        .edit_training_ride_parameters ? (
                        <tr>
                          <td>
                            <h4 className="text-dark float-left">
                              Training Ride Parameters
                            </h4>
                            <Button
                              className="btn btn-outline-transparent float-right mr--1"
                              color="white"
                              onClick={() =>
                                this.props.updateAppSettings({
                                  training_ride_params:
                                    this.state.training_ride_params,
                                })
                              }
                            >
                              Save
                            </Button>
                            <Row className="mt-5">
                              <Col>
                                <h5 className="mt-2 float-left text-dark">
                                  Training rides history (weeks)
                                </h5>
                                <Input
                                  className="float-left text-dark"
                                  placeholder={
                                    this.props.firestore.app_settings
                                      .training_ride_params.ride_history
                                  }
                                  value={
                                    this.state.training_ride_params
                                      .ride_history || ''
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      training_ride_params: {
                                        ...this.state.training_ride_params,
                                        ride_history:
                                          parseInt(e.target.value) || undefined,
                                      },
                                    })
                                  }
                                />
                                <h5 className="mt-2 float-left text-dark">
                                  Window radius (hours)
                                </h5>
                                <Input
                                  className="float-left text-dark"
                                  placeholder={
                                    this.props.firestore.app_settings
                                      .training_ride_params.window_radius
                                  }
                                  value={
                                    this.state.training_ride_params
                                      .window_radius || ''
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      training_ride_params: {
                                        ...this.state.training_ride_params,
                                        window_radius:
                                          parseInt(e.target.value) || undefined,
                                      },
                                    })
                                  }
                                />
                                <h5 className="mt-2 float-left text-dark">
                                  Minimum ride distance
                                </h5>
                                <Input
                                  className="float-left text-dark"
                                  placeholder={
                                    this.props.firestore.app_settings
                                      .training_ride_params.min_distance
                                  }
                                  value={
                                    this.state.training_ride_params
                                      .min_distance || ''
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      training_ride_params: {
                                        ...this.state.training_ride_params,
                                        min_distance:
                                          parseInt(e.target.value) || undefined,
                                      },
                                    })
                                  }
                                />
                                <h5 className="mt-2 float-left text-dark">
                                  Minimum segments
                                </h5>
                                <Input
                                  className="float-left text-dark"
                                  placeholder={
                                    this.props.firestore.app_settings
                                      .training_ride_params.min_segments
                                  }
                                  value={
                                    this.state.training_ride_params
                                      .min_segments || ''
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      training_ride_params: {
                                        ...this.state.training_ride_params,
                                        min_segments:
                                          parseInt(e.target.value) || undefined,
                                      },
                                    })
                                  }
                                />
                                <h5 className="mt-2 float-left text-dark">
                                  Minimum supervisors
                                </h5>
                                <Input
                                  className="float-left text-dark"
                                  placeholder={
                                    this.props.firestore.app_settings
                                      .training_ride_params.min_supervisors
                                  }
                                  value={
                                    this.state.training_ride_params
                                      .min_supervisors || ''
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      training_ride_params: {
                                        ...this.state.training_ride_params,
                                        min_supervisors:
                                          parseInt(e.target.value) || undefined,
                                      },
                                    })
                                  }
                                />
                                <h5 className="mt-2 float-left text-dark">
                                  Minimum riders
                                </h5>
                                <Input
                                  className="float-left text-dark"
                                  placeholder={
                                    this.props.firestore.app_settings
                                      .training_ride_params.min_riders
                                  }
                                  value={
                                    this.state.training_ride_params
                                      .min_riders || ''
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      training_ride_params: {
                                        ...this.state.training_ride_params,
                                        min_riders:
                                          parseInt(e.target.value) || undefined,
                                      },
                                    })
                                  }
                                />
                                <h5 className="mt-2 float-left text-dark">
                                  Maximum time window for each segment (minutes)
                                </h5>
                                <Input
                                  className="float-left text-dark"
                                  placeholder={
                                    this.props.firestore.app_settings
                                      .training_ride_params
                                      .max_segment_time_window_mins
                                  }
                                  value={
                                    this.state.training_ride_params
                                      .max_segment_time_window_mins || ''
                                  }
                                  onChange={(e) =>
                                    this.setState({
                                      training_ride_params: {
                                        ...this.state.training_ride_params,
                                        max_segment_time_window_mins:
                                          parseInt(e.target.value) || undefined,
                                      },
                                    })
                                  }
                                />
                              </Col>
                            </Row>
                          </td>
                        </tr>
                      ) : null}
                      <tr hidden>
                        <td>
                          <h4 className="text-dark">Sponsor icons</h4>
                        </td>
                      </tr>
                      <tr hidden>
                        <td>
                          <h4 className="text-dark">Skill level description</h4>
                        </td>
                      </tr>
                      <tr hidden>
                        <td>
                          <h4 className="text-dark">
                            VO2max fitness level description
                          </h4>
                        </td>
                      </tr>
                      <tr hidden>
                        <td>
                          <h4 className="text-dark">
                            Fitness level description
                          </h4>
                        </td>
                      </tr>
                      <tr hidden>
                        <td>
                          <h4 className="text-dark">
                            Recent training rides description
                          </h4>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default connect(mapState, mapDispatch)(AppSettings);
