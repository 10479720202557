import React, { useState } from 'react';

import {
  Card,
  CardBody,
  Badge,
  Input,
  Button,
  Modal,
  Spinner,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  FormGroup,
  Form,
  Label,
} from 'reactstrap';

import { uploadFileToSharePoint } from 'store/storage/actions';
import 'react-datepicker/dist/react-datepicker.css';
import Moment from 'moment';
import { useEffect } from 'react';

Moment.relativeTimeThreshold('d', 1825); //5 years in days

/* 
Generic:
  First Name, Last Name > string
  Email > string
  Mobile > string
  Tour / event > string (already from Hub, pre-populated)
  DOB> integer (we should get his already from profile?)
  Sex > string (we should get his already from profile?)
  Rider fitness level > integer
  State
  Role
  Expiration date

Medical Forms:
  Medication – yes/no > Boolean (this an indicator to filter out priority during assessing process)
  Medical condition – yes/no > Boolean (this an indicator to filter out priority during assessing process)
  Allergy – yes/no > Boolean (this an indicator to filter out priority during assessing process)
  Tetnus – date > date string (this an indicator to filter out priority during assessing process)
  Covid vaccinated > Boolean (this an indicator to filter out priority during assessing process)
  Changes from last assessment > yes/no

Working with Children:
  WWC Reference ID > string
*/

const YesNo = ({ title, onChange }) => {
  useEffect(() => {
    onChange({ [title]: 'No' });
  }, [title]);
  return (
    <FormGroup tag="fieldset">
      <h4 className="mt-2 text-dark">{title}</h4>
      <FormGroup check>
        <Label check>
          <Input
            type="radio"
            name={title}
            onChange={() => onChange({ [title]: 'Yes' })}
          />{' '}
          Yes
        </Label>
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input
            type="radio"
            name={title}
            onChange={() => onChange({ [title]: 'No' })}
            defaultChecked
          />{' '}
          No
        </Label>
      </FormGroup>
    </FormGroup>
  );
};

const forms = (onChange) => ({
  /*
    Medication – yes/no > Boolean (this an indicator to filter out priority during assessing process)
    Medical condition – yes/no > Boolean (this an indicator to filter out priority during assessing process)
    Allergy – yes/no > Boolean (this an indicator to filter out priority during assessing process)
    Tetnus – date > date string (this an indicator to filter out priority during assessing process)
    Covid vaccinated > Boolean (this an indicator to filter out priority during assessing process)
    Changes from last assessment > yes/no
    Medical Assessment date > date string
  */
  Medical: (
    <Form>
      <YesNo title={'Medication'} onChange={onChange} />
      <YesNo title={'Medical condition'} onChange={onChange} />
      <YesNo title={'Allergy'} onChange={onChange} />
      <YesNo
        title={'Tetanus booster within the past 6 months'}
        onChange={onChange}
      />
      <YesNo title={'Changed since last assessment'} onChange={onChange} />
    </Form>
  ),
  'Working with children': (
    /*
      WWC Reference ID
    */
    <Form>
      <FormGroup>
        <Label className="mt-2" for="Working with children reference ID">
          Working with Children Reference ID
        </Label>
        <Input
          type="text"
          name="Working with children reference ID"
          placeholder="WWC Reference ID"
          onChange={(e) =>
            onChange({
              'Working with children reference ID': e.target.value,
            })
          }
        />
        <Label className="mt-2" for="Date of birth">
          Date of Birth
        </Label>
        <Input
          name="Date of birth"
          type="date"
          onChange={(e) => {
            try {
              const date = new Date(e.target.value);
              console.log(e.target.value);
              const dob = new Date(
                date.getUTCFullYear(),
                date.getUTCMonth(),
                date.getUTCDate()
              );
              onChange({
                'Date of birth': dob.toISOString(),
              });
            } catch (e) {
              console.warn(e);
            }
          }}
        />
      </FormGroup>
    </Form>
  ),
});

export const FileUploaderUser = (props) => {
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [fileTags, setFileTags] = useState({});
  const [popup, setPopup] = useState(props.isNew);
  const [saving, setSaving] = useState(false);
  const [newFileInfo, setNewFileInfo] = useState({ ...props.fileInfo });
  const fileDisabled = newFileInfo.form_type === 'Working with children';
  const expiryDate =
    newFileInfo.expiry instanceof Date
      ? newFileInfo.expiry
      : new Date(newFileInfo.expiry?.toDate() || new Date());
  const saveUrl = (fullTitle) => {
    const tmpFileInfo = { ...props.fileInfo };
    if (props.fileInfo.modified_on) {
      props.updateFile(props.fileInfo.id, {
        ...props.fileInfo,
        deleted: true,
      });
    }
    tmpFileInfo.eventId = tmpFileInfo.isEventLocked ? props.event.id : null;
    tmpFileInfo.modified_on = new Date();
    tmpFileInfo.expiry = tmpFileInfo.isEventLocked
      ? props.event.end_date
      : expiryDate;
    tmpFileInfo.athleteId = props.athlete.athlete_key;
    tmpFileInfo.title = fullTitle;
    tmpFileInfo.url = '#';
    props.addFile(tmpFileInfo);
    setPopup(false);
    setSaving(false);
  };

  const save = () => {
    if (newFileInfo.title === '') alert('Please enter a name for the file.');
    else if (
      !(newFileInfo.expiry instanceof Date) &&
      !props.fileInfo.isEventLocked
    )
      alert('Enter the date that this document will expire.');
    else if (
      newFileInfo.expiry instanceof Date &&
      newFileInfo.expiry < new Date()
    )
      alert('Please provide an expiry date in the future.');
    else if (
      newFileInfo.form_type === 'Working with children' &&
      !fileTags['Date of birth']
    )
      alert('Please provide your date of birth');
    else if (
      newFileInfo.form_type === 'Working with children' &&
      !fileTags['Working with children reference ID']
    )
      alert('Please provide your working with children reference ID');
    else {
      if (props.fileInfo.isEventLocked)
        newFileInfo.expiry =
          props.event.end_date instanceof Date
            ? props.event.end_date
            : props.event.end_date.toDate();
      setSaving(true);
      const uploadOneFile = (name, file = undefined, idx = 0) => {
        const timestamp = new Date()
          .toLocaleString()
          .replace(/,/gi, '')
          .replace(/[^A-Z0-9]/gi, '_');
        const athlete_name = props.athlete.name.replace(/\s/gi, '_');
        const title = newFileInfo.fileTitle.replace(' ', '_');
        const fileType = name.split('.').pop();
        const fileName =
          athlete_name + '_' + title + '_' + timestamp + '_' + idx.toString();
        const tags = {
          'First name': props?.athlete?.firstname,
          'Last name': props?.athlete?.lastname,
          Email: props?.athlete?.email,
          Mobile: props?.athlete?.mobile,
          Event: props?.event?.name,
          Gender: props?.athlete?.gender,
          'Predicted fitness level': props?.athlete?.predicted_rider_level,
          State: props?.athlete?.athlete_state,
          'Athlete type': props?.athlete?.user_types?.rider
            ? 'Rider'
            : 'Non-Rider',
          Expiry: newFileInfo?.expiry?.toISOString(),
          ...fileTags,
        };
        uploadFileToSharePoint(
          newFileInfo.fileTitle,
          fileName + '.' + fileType,
          file
            ? file
            : new Blob(
                [
                  Object.entries(tags)
                    .map(([k, v]) => `${k}: ${v ? v.toString() : ''}`)
                    .join('\n'),
                ],
                {
                  type: 'text/plain',
                }
              ),
          tags
        )
          .then(() => saveUrl(fileName + '.' + fileType))
          .catch((err) => console.error(err));
      };
      if (!fileDisabled) {
        if (selectedFiles?.length > 0) {
          selectedFiles.map((selectedFile, idx) =>
            uploadOneFile(selectedFile.name, selectedFile, idx)
          );
        } else {
          alert('Please select a file to upload.');
          setSaving(false);
        }
      } else {
        uploadOneFile('tags.txt');
      }
    }
  };

  const cancel = () => {
    if (props.onDiscard) {
      if (!newFileInfo.modified_on) {
        props.onDiscard();
      }
    }
    setPopup(false);
  };

  return (
    <>
      <Card className="text-dark px-2 mb-2 border-0">
        <div className="py-2 d-flex flex-row flex-wrap">
          <div className="flex-grow-1 d-flex flex-column">
            <label className="mr-auto align-self-center text-dark">
              {' '}
              {props.fileInfo.fileTitle}{' '}
            </label>
            <small className="mr-auto align-self-center text-dark mb-2 mt--2">
              {' '}
              {props.fileInfo.title}{' '}
            </small>
            {props.fileInfo.url ? (
              <>
                <Badge
                  color={
                    props.fileInfo.expiry && new Date() > new Date(expiryDate)
                      ? 'warning'
                      : 'primary'
                  }
                  className="mb-2 mr-auto"
                >
                  {props.fileInfo.expiry && new Date() > new Date(expiryDate)
                    ? 'Expired '
                    : 'Expires '}{' '}
                  {Moment(expiryDate).fromNow()}
                </Badge>
              </>
            ) : (
              <Badge color="warning" className="mb-2 mr-auto">
                Not Received
              </Badge>
            )}
          </div>

          <Button
            className="my-auto"
            color="white"
            onClick={() => setPopup(true)}
          >
            Upload
          </Button>
        </div>
      </Card>
      <Modal isOpen={popup}>
        <Card className="shadow p-4">
          <CardBody className="p-2 text-dark">
            <div className="d-flex flex-row">
              <h2 className="mr-auto pt-2 text-dark flex-grow-1">
                {'Upload ' + newFileInfo.fileTitle}
              </h2>
            </div>
            {
              forms((t) => setFileTags((p) => ({ ...p, ...t })))[
                newFileInfo.form_type
              ]
            }
            {!fileDisabled && (
              <>
                <label
                  className="btn btn-secondary mt-2"
                  htmlFor="newfileuplod"
                >
                  Upload file
                </label>
                <Input
                  type="file"
                  name={newFileInfo.title}
                  id="newfileuplod"
                  accept=".pdf,image/*"
                  multiple
                  onChange={(e) => {
                    setSelectedFiles(Array.from(e.target.files));
                  }}
                  style={{
                    display: 'none',
                  }}
                />
                <label>{selectedFiles?.map((f) => f?.name).join(', ')}</label>
              </>
            )}

            {newFileInfo.isEventLocked === false && (
              <>
                <h4 className="text-dark mt-3">Expiry Date</h4>
                {newFileInfo.form_type === 'Medical' && (
                  <i className="mt-3 mb-4 small text-danger font-weight-bold">
                    *Expiry date for medical forms is 1 year from GP visit
                  </i>
                )}

                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="date"
                    onChange={(e) => {
                      try {
                        setNewFileInfo({
                          ...newFileInfo,
                          expiry: new Date(e.target.value),
                        });
                      } catch (e) {
                        console.warn(e);
                      }
                    }}
                  />
                </InputGroup>
              </>
            )}

            <div className="d-flex flex-row flex-wrap mt-4">
              <Button
                className="mr-auto"
                onClick={(e) => cancel()}
                disabled={saving}
              >
                Cancel
              </Button>
              <Button
                className="ml-auto"
                color="success"
                onClick={(e) => save(e)}
                disabled={saving}
              >
                {saving ? (
                  <Spinner size="sm" color="grey" className="" />
                ) : (
                  'Save'
                )}
              </Button>
            </div>
          </CardBody>
        </Card>
      </Modal>
    </>
  );
};
