/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  Alert,
} from 'reactstrap';
// core components
import { Link } from 'react-router-dom';
import UserHeader from 'components/Headers/UserHeader.js';
import { MultiFileUploaderUserDocs } from 'components/MultiFileUploaderUserDocs.js';
import PerformanceCard from 'components/PerformanceCard.js';
import { connect } from 'react-redux';
import { authStrava, getTokens } from 'store/strava/actions.js';
import {
  updateAthlete,
  setTrainingHub,
  selectEvent,
  addAthleteFile,
  updateAthleteFile,
  getGrassrootzData,
  getEffortContributions,
} from 'store/firestore/actions.js';
import { defaultAthlete } from 'store/firestore/reducers';

const JOIN_A_TOUR_LINK = 'https://tourdecure.com.au/tours/join-a-tour/';

const mapState = (state) => state;

const mapDispatch = {
  authStrava,
  updateAthlete,
  setTrainingHub,
  selectEvent,
  addAthleteFile,
  updateAthleteFile,
  getGrassrootzData,
  getEffortContributions,
};

class Profile extends React.Component {
  constructor(props) {
    super(props);
    // const [dropdownOpen, setOpen] = useState(false);
    // const toggle = () => setOpen(!dropdownOpen);
    let params = new URLSearchParams(props.location.search);
    if (params.get('code')) {
      getTokens(
        params.get('code'),
        params.get('scope'),
        (athlete) => athlete && props.updateAthlete(athlete)
      );
      props.history.push(props.location.pathname);
    }
    this.state = {
      training_hub_dropdown_open: false,
      event_dropdown_open: false,
      explain_skill_level: false,
      explain_fitness_level: false,
      explain_recent_training_rides: false,
      toggleEvent: () => {
        this.setState({ event_dropdown_open: !this.state.event_dropdown_open });
      },
      toggleTrainingHub: () => {
        this.setState({
          training_hub_dropdown_open: !this.state.training_hub_dropdown_open,
        });
      },
      year_of_birth: this.props.firestore.athlete.year_of_birth,
      gender: this.props.firestore.athlete.gender,
      tmp_grassrootz_url: '',
      explain_grassrootz: false,
    };
    props.getEffortContributions(this.props.firestore.selected_event.id);
  }

  shouldDisplayFile(role_id) {
    return (
      !role_id ||
      (this.props.firestore.app_settings.event_roles
        .map((r) => r.id)
        .includes(role_id) &&
        (
          this.props.firestore.selected_event.event_roles[
            this.props.firestore.athlete.athlete_key
          ] || []
        ).includes(role_id))
    );
  }

  genIcon(role_id, classes) {
    const role = this.props.firestore.app_settings.event_roles.filter(
      (r) => r.id === role_id
    );
    if (this.shouldDisplayFile(role_id) && role.length !== 0) {
      return <i className={role[0].icon + role[0].color + ' ' + classes} />;
    } else {
      return null;
    }
  }

  roundNumber(num, scale) {
    if (!('' + num).includes('e')) {
      return +(Math.round(num + 'e+' + scale) + 'e-' + scale);
    } else {
      var arr = ('' + num).split('e');
      var sig = '';
      if (+arr[1] + scale > 0) {
        sig = '+';
      }
      return +(
        Math.round(+arr[0] + 'e' + sig + (+arr[1] + scale)) +
        'e-' +
        scale
      );
    }
  }

  formatCurrency(val) {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(val);
  }

  getRemainingTimeMessage(end_date) {
    const days = Math.floor((end_date - new Date()) / 86400000);
    const weeks = Math.floor(days / 7);
    const remainder = days % 7;
    if (days > 365 || days < 0) {
      return (
        <>
          <div
            style={{ width: '100%', height: '100%', userSelect: 'none' }}
            onClick={() => window.open(JOIN_A_TOUR_LINK)}
          >
            Click here to join a tour
          </div>
          <small>
            If you have already registered, you will be added shortly by our
            team.
          </small>
        </>
      );
    }
    let message = '';
    if (days === 0) {
      return '1 day remaining';
    } else if (days < 7) {
      message += remainder > 1 ? remainder + ' days ' : remainder + ' day ';
    } else if (days >= 7) {
      message += weeks;
      message += weeks > 1 ? ' weeks ' : ' week ';
      if (remainder > 1) {
        message +=
          remainder > 1
            ? 'and ' + remainder + ' days '
            : 'and ' + remainder + ' day ';
      }
    }
    return message + 'remaining';
  }

  render() {
    const grassrootz_data =
      this.props.firestore.grassrootz_data[
        this.props.firestore.selected_event.id
      ];
    const display_funds_raised = grassrootz_data && grassrootz_data.target > 0;
    let update_profile_settings = false;
    for (const k of [
      'firstname',
      'lastname',
      'nok_firstname',
      'nok_lastname',
    ]) {
      if (this.props.firestore.athlete[k] === defaultAthlete[k]) {
        update_profile_settings = true;
      }
    }
    const effort_contributions =
      this.props.firestore.effort_contributions?.[
        this.props.firestore.selected_event.id
      ];
    const name = `${this.props.firestore.athlete.firstname} ${this.props.firestore.athlete.lastname}`;
    const effort_contribution = parseFloat(effort_contributions?.[name] || 0);

    const ensure_int = (val) => (val ? parseInt(val.toString()) : val);

    const skill = ensure_int(this.props.firestore.athlete.skill_level);
    const required_skill = ensure_int(
      this.props.firestore.selected_event.required_skill
    );
    const fitness = ensure_int(this.props.firestore.athlete.rider_level);
    const predicted_fitness = ensure_int(
      this.props.firestore.athlete.predicted_rider_level
    );
    const required_fitness = ensure_int(
      this.props.firestore.selected_event.required_fitness
    );
    const training_rides = ensure_int(
      this.props.firestore.athlete.calcTrainingRides(
        this.props.firestore.app_settings.training_ride_params.ride_history
      )
    );
    const required_training_rides = ensure_int(
      this.props.firestore.selected_event.required_training_rides
    );
    const training_ride_exempt =
      this.props.firestore.athlete.training_ride_exempt;
    const ready_for_tour =
      skill >= required_skill &&
      (fitness || predicted_fitness || 1) >= required_fitness &&
      (training_rides >= required_training_rides || training_ride_exempt);
    return (
      <>
        {update_profile_settings && (
          <Modal isOpen={true}>
            <Card className="p-4">
              <h2 className="text-dark">
                Please update your profile information
              </h2>
              <p className="text-dark">
                We have updated our profile information requirements and need
                you to update them on our behalf. Please click the button below
                to open your profile settings and update your details.
              </p>
              <Link to="/admin/profile-settings" className="btn btn-secondary">
                Profile Settings
              </Link>
            </Card>
          </Modal>
        )}
        <UserHeader />
        {this.state.loading ? null : (
          <>
            <Container className="mt--7" style={{ maxWidth: '1200px' }} fluid>
              <Row>
                <Col className="pl-4" xs="8">
                  <h1
                    className="text-white"
                    style={{ transform: 'translate(0, -50%)' }}
                  >
                    {this.props.firestore.athlete.name}
                  </h1>
                  {/* <p
                    className="text-white mt--4"
                  >
                    You have helped raise <b>$24,130</b> for cancer
                  </p> */}
                </Col>
                <Col className="pr-4" xs="4">
                  <img
                    alt="..."
                    className="rounded-circle float-right"
                    style={{
                      width: '100px',
                      transform: 'translate(0, -50%)',
                      height: '100px',
                    }}
                    src={this.props.firestore.athlete.profile}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = require('assets/img/strava/user.png');
                    }}
                  />
                </Col>
                {this.props.firestore.athlete.expires_at ===
                'invalid_refresh_token' ? (
                  <Col xs="12">
                    <Alert
                      className="mt-1 mb-1"
                      color="danger"
                      fade={false}
                      onClick={(e) => this.props.authStrava()}
                    >
                      An error has occured with your Strava account
                      authorization. Please click{' '}
                      <b>
                        <u>here</u>
                      </b>{' '}
                      to reconnect your Strava account.
                    </Alert>
                  </Col>
                ) : null}

                <Col xs="12" hidden>
                  <Card className="shadow mt-1">
                    <CardBody className="p-2">
                      <div className="d-flex justify-content-between px-2">
                        <img
                          src={require('assets/img/sponsors/CommBank.jpg')}
                          alt="Sponsor Logo"
                          style={{
                            maxHeight: '30px',
                          }}
                        />
                        <img
                          src={require('assets/img/sponsors/Optus.jpg')}
                          alt="Sponsor Logo"
                          style={{
                            maxHeight: '30px',
                          }}
                        />
                        <img
                          src={require('assets/img/sponsors/Woolworths.jpg')}
                          alt="Sponsor Logo"
                          style={{
                            maxHeight: '30px',
                          }}
                        />
                        <img
                          src={require('assets/img/sponsors/Fluid.jpg')}
                          alt="Sponsor Logo"
                          style={{
                            maxHeight: '30px',
                          }}
                        />
                        <img
                          src={require('assets/img/sponsors/Yellow.jpg')}
                          alt="Sponsor Logo"
                          style={{
                            maxHeight: '30px',
                          }}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xs="12">
                  <Card className="my-2 card-profile shadow">
                    <CardBody className="">
                      <Row className="">
                        <Col className="d-flex flex-column" xs="6" lg="3">
                          <span className="h3 text-dark mx-lg-auto">
                            Upcoming Event
                            <br />
                          </span>
                          <UncontrolledDropdown
                            className="mx-lg-auto"
                            isOpen={this.state.event_dropdown_open}
                            toggle={this.state.toggleEvent}
                          >
                            <DropdownToggle
                              className="btn btn-outline-white shadow-none pl-0 pr-0 text-left text-lg-center"
                              color="white"
                            >
                              <span
                                className="font-weight-normal text-dark mx-lg-auto"
                                style={{
                                  wordWrap: 'break-word',
                                  whiteSpace: 'normal',
                                }}
                              >
                                {this.props.firestore.selected_event.name}
                                <i
                                  className="ni ni-bold-down pl-2 mr--4"
                                  style={{ transform: 'translate(0, 3px)' }}
                                />
                              </span>
                            </DropdownToggle>
                            <DropdownMenu>
                              {Object.keys(this.props.firestore.events).map(
                                (key, i) => (
                                  <DropdownItem
                                    key={i}
                                    onClick={(e) => this.props.selectEvent(key)}
                                  >
                                    {this.props.firestore.events[key].name}
                                  </DropdownItem>
                                )
                              )}
                              <DropdownItem
                                color="success"
                                onClick={(e) => window.open(JOIN_A_TOUR_LINK)}
                              >
                                {'Join a tour'}
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </Col>
                        <Col className="mb-4 d-flex flex-column" xs="6" lg="3">
                          <span className="h3 text-dark mx-lg-auto text-right">
                            Event Team
                            <br />
                          </span>
                          <div className="d-flex align-items-middle justify-content-end justify-content-lg-center mt-2 text-right text-lg-center">
                            <span className="h4 font-weight-normal text-dark">
                              {this.props.firestore.selected_event
                                .member_team_id &&
                              this.props.firestore.selected_event.group_data
                                .teams
                                ? this.props.firestore.selected_event.group_data
                                    .teams[
                                    this.props.firestore.selected_event
                                      .member_team_id
                                  ].name
                                : 'Awaiting your next signup'}
                            </span>
                          </div>
                        </Col>
                        <Col className="mb-4 d-flex flex-column" xs="6" lg="3">
                          <span className="h3 text-dark mx-lg-auto">
                            Hub Locale
                            <br />
                          </span>
                          <UncontrolledDropdown
                            className="mx-lg-auto"
                            isOpen={this.state.training_hub_dropdown_open}
                            toggle={this.state.toggleTrainingHub}
                          >
                            <DropdownToggle
                              className="btn btn-outline-white shadow-none pl-0"
                              color="white"
                            >
                              <span className="font-weight-normal text-dark">
                                {this.props.firestore.athlete.training_hub}
                                <i
                                  className="ni ni-bold-down pl-2 mr--4"
                                  style={{ transform: 'translate(0, 3px)' }}
                                />
                              </span>
                            </DropdownToggle>
                            <DropdownMenu>
                              {this.props.firestore.app_settings.training_hubs.map(
                                (hub) => (
                                  <DropdownItem
                                    key={hub}
                                    onClick={(e) =>
                                      this.props.setTrainingHub(hub)
                                    }
                                  >
                                    {hub}
                                  </DropdownItem>
                                )
                              )}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </Col>
                        <Col className="mb-4 d-flex flex-column" xs="6" lg="3">
                          <span className="h3 text-dark mx-lg-auto text-right">
                            Points
                            <br />
                          </span>
                          <div className="d-flex align-items-middle justify-content-end justify-content-lg-center mt-2">
                            <span className="h4 font-weight-normal text-dark">
                              {this.props.firestore.selected_event.user_points.toFixed(
                                0
                              )}
                            </span>
                          </div>
                        </Col>
                      </Row>
                      <hr className="mt-0" />
                      <Row>
                        <Col className="" xs="12">
                          {/* <div className="icon icon-shape bg-warning text-white rounded-circle shadow float-right">
                          <i className="fas fa-smile" />
                        </div> */}
                          <Card className="bg-gray p-3 ml--2 mr--2">
                            <h2
                              className="text-white pt-2 text-center"
                              style={{ userSelect: 'none' }}
                            >
                              {this.getRemainingTimeMessage(
                                this.props.firestore.selected_event.start_date
                              )}
                            </h2>
                          </Card>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  {!this.props.firestore.athlete.access_token && (
                    <Card className="mt-2 pt-4 card-profile shadow">
                      <CardBody className="pt-0">
                        <Row>
                          <Col className="mb-2" xs="12">
                            <h2 className="text-dark">
                              Are you riding on this tour?
                            </h2>
                          </Col>
                          <Col className="mb-2 text-dark" xs="12">
                            To help us build your profile and understand your
                            riding ability please connect your Strava account.
                          </Col>
                          <Col className="text-left pt-2" xs="12">
                            <Button
                              className="btn-outline"
                              onClick={(e) => this.props.authStrava()}
                            >
                              Connect to Strava
                            </Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  )}
                  <div className="mb-2">
                    {display_funds_raised && (
                      <PerformanceCard
                        relative
                        currency="USD"
                        info={(e) =>
                          this.setState({ explain_grassrootz: true })
                        }
                        max={grassrootz_data.target}
                        value={grassrootz_data.raised + effort_contribution}
                        title="Your funds raised for this event"
                        description={
                          <>
                            <b>{`${this.formatCurrency(
                              grassrootz_data.raised + effort_contribution
                            )}`}</b>
                            {` out of ${this.formatCurrency(
                              grassrootz_data.target
                            )}`}
                          </>
                        }
                      />
                    )}
                  </div>
                  {this.props.firestore.athlete.access_token && (
                    <Card
                      className={`bg-${
                        ready_for_tour ? 'green' : 'orange'
                      } p-3 ml--2 mr--2 `}
                    >
                      <h1 className="text-white">
                        {ready_for_tour
                          ? `Congratulations! You are ready for tour 🎉 `
                          : `Nearly there! Keep riding to get ready for tour 🚲 `}
                      </h1>

                      <PerformanceCard
                        increment="0.5"
                        info={(e) =>
                          this.setState({ explain_skill_level: true })
                        }
                        max={this.props.firestore.app_settings.max_skill_level}
                        value={this.props.firestore.athlete.skill_level || 1}
                        required={
                          this.props.firestore.selected_event.required_skill
                        }
                        icon="fa-bicycle"
                        title="Your TDC assessed skill level"
                      />

                      <PerformanceCard
                        increment="0.5"
                        info={(e) =>
                          this.setState({ explain_fitness_level: true })
                        }
                        max={
                          this.props.firestore.app_settings.max_fitness_level
                        }
                        value={
                          this.props.firestore.athlete.rider_level ||
                          this.props.firestore.athlete.predicted_rider_level ||
                          1
                        }
                        required={
                          this.props.firestore.selected_event.required_fitness
                        }
                        icon="fa-bicycle"
                        title="Your TDC assessed fitness level"
                      />

                      <PerformanceCard
                        increment="1"
                        info={(e) =>
                          this.setState({ explain_recent_training_rides: true })
                        }
                        max={Math.max(
                          this.props.firestore.selected_event
                            .required_training_rides,
                          this.props.firestore.athlete.calcTrainingRides(
                            this.props.firestore.app_settings
                              .training_ride_params.ride_history
                          )
                        )}
                        value={this.props.firestore.athlete.calcTrainingRides(
                          this.props.firestore.app_settings.training_ride_params
                            .ride_history
                        )}
                        required={
                          this.props.firestore.selected_event
                            .required_training_rides
                        }
                        icon="fa-bicycle"
                        title="TDC training rides attended in 3 months"
                      />
                    </Card>
                  )}
                  <Card className="mt-2">
                    <Row className="p-4 pb-0">
                      <Col className="pb-3" xs="12">
                        <h2 className="text-dark align-middle">
                          Tour information
                        </h2>
                        <p className="text-dark align-middle">
                          This is where you can find files and information about
                          this tour
                        </p>
                        <hr></hr>
                      </Col>
                      {[
                        {
                          ...this.props.firestore.selected_event.files,
                        },
                      ].map((files) =>
                        Object.keys(files)
                          .filter(
                            (key) =>
                              !files[key].deleted &&
                              this.shouldDisplayFile(files[key].role_id)
                          )
                          .sort((a, b) => {
                            if (!files[b].modified_on) return 1;
                            else if (!files[a].modified_on) return -1;
                            else
                              return (
                                files[a].modified_on.toDate() -
                                files[b].modified_on.toDate()
                              );
                          })
                          .map((key, i) => (
                            <Button
                              key={i}
                              className="m-2 px-3 pb-1"
                              color="white"
                              onClick={(e) => window.open(files[key].url)}
                            >
                              <h4 className="text-dark">
                                {this.genIcon(files[key].role_id, 'mr-2')}
                                {files[key].title}
                              </h4>
                            </Button>
                          ))
                      )}
                    </Row>
                  </Card>
                  <Card className="mt-2">
                    <Row className="p-4 pb-0">
                      <Col xs="12">
                        <h2 className="text-dark align-middle">
                          Information to support your role on tour
                        </h2>
                        <p className="text-dark align-middle">
                          This is where you can find files and information
                          across all tours
                        </p>
                        <hr></hr>
                      </Col>
                      {[
                        {
                          ...this.props.firestore.app_settings.public_files,
                        },
                      ].map((files) =>
                        Object.keys(files)
                          .filter(
                            (key) =>
                              !files[key].deleted &&
                              this.shouldDisplayFile(files[key].role_id)
                          )
                          .sort((a, b) => {
                            if (!files[b].modified_on) return 1;
                            else if (!files[a].modified_on) return -1;
                            else
                              return (
                                files[a].modified_on.toDate() -
                                files[b].modified_on.toDate()
                              );
                          })
                          .map((key, i) => (
                            <Button
                              key={i}
                              color="white"
                              className={'m-2 px-3 pb-1'}
                              onClick={(e) => window.open(files[key].url)}
                            >
                              <h4 className="text-dark">
                                {this.genIcon(files[key].role_id, 'mr-2')}
                                {files[key].title}
                              </h4>
                            </Button>
                          ))
                      )}
                    </Row>
                  </Card>
                  <Card className="mt-2">
                    <Row className="p-4 pb-0">
                      <Col className="pb-3" xs="12">
                        <h2 className="text-dark align-middle">
                          {' '}
                          Your documents
                        </h2>
                      </Col>
                      <Col className="pb-3" xs="12">
                        <MultiFileUploaderUserDocs
                          event={this.props.firestore.selected_event}
                          athlete={this.props.firestore.athlete}
                          addFile={this.props.addAthleteFile}
                          updateFile={this.props.updateAthleteFile}
                          files={Object.fromEntries(
                            [
                              ...Object.entries(
                                this.props.firestore.app_settings.athlete_files
                              ),
                              ...Object.entries(
                                this.props.firestore.selected_event
                                  .athlete_files
                              ),
                              ...Object.entries(
                                this.props.firestore.athlete_files
                              ),
                            ]
                              .filter(([key, value], pos, arr) => {
                                return (
                                  !value.deleted &&
                                  this.shouldDisplayFile(
                                    value.eventRoleId
                                  ) /* event roles filter */ &&
                                  ((!value.url &&
                                    !arr.some(([innerKey, innerValue]) => {
                                      /* filter where templates where file uploaded */
                                      return (
                                        innerValue.url &&
                                        !innerValue.deleted &&
                                        innerValue.fileId === value.fileId
                                      );
                                    })) ||
                                    value.url) &&
                                  arr.some(([innerKey, innerValue]) => {
                                    /* filter where file template was deleted */
                                    return (
                                      !innerValue.url &&
                                      innerValue.fileId === value.fileId
                                    );
                                  })
                                );
                              })
                              .sort(([keya, valuea], [keyb, valueb]) => {
                                if (!valueb.expiry) return 1;
                                else if (!valuea.expiry) return -1;
                                else
                                  return (
                                    valuea.expiry.toDate() -
                                    valueb.expiry.toDate()
                                  );
                              })
                          )}
                        />
                      </Col>
                    </Row>
                  </Card>
                  {this.props.firestore.athlete.access_token && (
                    <Button
                      className="text-dark bg-transparent shadow-none"
                      color="transparent"
                      onClick={(e) => this.props.authStrava()}
                    >
                      <small>Switch Strava account?</small>
                    </Button>
                  )}
                </Col>
              </Row>
              <Modal isOpen={this.state.explain_grassrootz}>
                <Card>
                  <CardHeader>
                    <h2 className="text-dark mb-0">Grassrootz Fundraising</h2>
                  </CardHeader>
                  <CardBody>
                    <p>Your current fundraising progress on Grassrootz.</p>
                  </CardBody>
                  <Button
                    onClick={(e) =>
                      this.setState({ explain_grassrootz: false })
                    }
                  >
                    Okay
                  </Button>
                </Card>
              </Modal>
              <Modal isOpen={this.state.explain_skill_level}>
                <Card>
                  <CardHeader>
                    <h2 className="text-dark mb-0">Skill level</h2>
                  </CardHeader>
                  <CardBody>
                    <p>
                      {
                        this.props.firestore.app_settings
                          .skill_level_description
                      }
                    </p>
                  </CardBody>
                  <Button
                    onClick={(e) =>
                      this.setState({ explain_skill_level: false })
                    }
                  >
                    Okay
                  </Button>
                </Card>
              </Modal>
              <Modal isOpen={this.state.explain_fitness_level}>
                <Card>
                  <CardHeader>
                    <h2 className="text-dark mb-0">Fitness level</h2>
                  </CardHeader>
                  <CardBody>
                    <p>
                      {
                        this.props.firestore.app_settings
                          .fitness_level_description
                      }
                    </p>
                  </CardBody>
                  <Button
                    onClick={(e) =>
                      this.setState({ explain_fitness_level: false })
                    }
                  >
                    Okay
                  </Button>
                </Card>
              </Modal>
              <Modal isOpen={this.state.explain_recent_training_rides}>
                <Card>
                  <CardHeader>
                    <h2 className="text-dark mb-0">Recent training rides</h2>
                  </CardHeader>
                  <CardBody>
                    <p>
                      {
                        this.props.firestore.app_settings
                          .recent_training_rides_description
                      }
                    </p>
                  </CardBody>
                  <Button
                    onClick={(e) =>
                      this.setState({ explain_recent_training_rides: false })
                    }
                  >
                    Okay
                  </Button>
                </Card>
              </Modal>
            </Container>
          </>
        )}
      </>
    );
  }
}

export default connect(mapState, mapDispatch)(Profile);
