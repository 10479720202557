/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

// reactstrap components
import { Card, Container, Row, Col, Table, CardBody, Badge } from 'reactstrap';
// react plugin used to create charts

// core components
import Header from 'components/Headers/Header.js';

import { connect } from 'react-redux';
import {} from 'store/strava/actions.js';
import { selectEvent, getEvent } from 'store/firestore/actions.js';

const mapState = (state) => state;

const mapDispatch = {
    selectEvent,
    getEvent,
};

class ChallengeLeaderboard extends React.Component {
    prettyDate(d) {
        const mo = d.toLocaleString('en', { month: 'short' });
        const da = d.toLocaleString('en', { day: '2-digit' });
        //const tm = new Intl.DateTimeFormat('en', { timeStyle: 'short' }).format(d);
        const tm = d.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
        return `${tm} ${da} ${mo}`;
    }

    orderChallenges(challenges) {
        const chronological = Object.keys(challenges)
            .map((key) => challenges[key])
            .sort((a, b) => a.end_date - b.end_date);
        const categories = {
            completed: chronological
                .filter((challenge) => new Date() >= challenge.end_date)
                .map((challenge) => {
                    return { ...challenge, status: 'completed' };
                }),
            upcoming: chronological
                .filter((challenge) => new Date() < challenge.start_date)
                .map((challenge) => {
                    return { ...challenge, status: 'upcoming' };
                }),
            active: chronological
                .filter(
                    (challenge) =>
                        challenge.start_date <= new Date() &&
                        new Date() < challenge.end_date
                )
                .map((challenge) => {
                    return { ...challenge, status: 'active' };
                }),
        };
        const ordered_challenges = [].concat(
            categories.active,
            categories.upcoming,
            categories.completed
        );
        return ordered_challenges;
    }
    generateLeaderboardStats() {
        // for team in teams, gen list of users
        const leaderboard = {};
        const challenges = this.orderChallenges(
            this.props.firestore.selected_event.challenges
        );
        const team =
            this.props.firestore.selected_event.group_data.teams[
                this.props.firestore.selected_event.member_team_id
            ];
        for (const challenge_id in challenges) {
            const challenge = challenges[challenge_id];
            const athletes = [];
            for (const athlete in challenge.progress) {
                // for athlete in progress
                if (athlete in team.members) {
                    const athlete_data = {
                        points: 0,
                        name: team.members[athlete].name,
                        logo: team.members[athlete].profile_image,
                    };
                    // for activity in athlete
                    for (const activity in challenge.progress[athlete]) {
                        athlete_data.points +=
                            challenge.progress[athlete][activity].points;
                    }
                    athletes.push(athlete_data);
                }
            }
            leaderboard[challenge_id] = {
                name: challenge.name,
                start_date: challenge.start_date,
                end_date: challenge.end_date,
                description: challenge.description,
                status: challenge.status,
                athletes: athletes
                    .sort((a, b) => b.points - a.points)
                    .map((a) => ({ ...a, points: a.points.toFixed(0) }))
                    .slice(0, 10),
            };
        }
        return leaderboard;
    }

    render() {
        return (
            <>
                <Header
                    title="Challenge Leaderboard"
                    dropdown
                    selected={this.props.firestore.selected_event.name}
                    onSelect={(key) => this.props.selectEvent(key)}
                    items={this.props.firestore.events}
                    syncAction={() =>
                        this.props.getEvent(
                            this.props.firestore.selected_event.id
                        )
                    }
                />
                <Container className="" style={{ maxWidth: '1200px' }} fluid>
                    <Row>
                        {this.props.firestore.selected_event.member_team_id &&
                        this.props.firestore.selected_event.group_data.teams ? (
                            <>
                                <Col xs="12">
                                    <Card className="mt-2 p-4">
                                        <Row>
                                            <Col align="center">
                                                <img
                                                    className="mr-3"
                                                    src={
                                                        this.props.firestore
                                                            .selected_event
                                                            .group_data.teams[
                                                            this.props.firestore
                                                                .selected_event
                                                                .member_team_id
                                                        ].logo_url
                                                    }
                                                    alt="Invalid logo"
                                                    style={{
                                                        maxHeight: '50px',
                                                        borderRadius: '25px',
                                                    }}
                                                />
                                                <span className="text-dark">
                                                    {
                                                        this.props.firestore
                                                            .selected_event
                                                            .group_data.teams[
                                                            this.props.firestore
                                                                .selected_event
                                                                .member_team_id
                                                        ].name
                                                    }
                                                </span>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                {Object.values(
                                    this.generateLeaderboardStats()
                                ).map((challenge, key) => (
                                    <Col xs="12" key={key}>
                                        <Card className="card-profile shadow mt-2">
                                            <CardBody className="pt-4">
                                                <Row>
                                                    <Col md="6" xs="12">
                                                        <h2 className="text-dark">
                                                            {challenge.name}
                                                        </h2>
                                                    </Col>
                                                    <Col
                                                        md="6"
                                                        xs="12"
                                                        className="d-flex align-items-center justify-content-end"
                                                    >
                                                        <small className="text-dark ml-auto">
                                                            {this.prettyDate(
                                                                challenge.start_date
                                                            )}{' '}
                                                            -{' '}
                                                            {this.prettyDate(
                                                                challenge.end_date
                                                            )}
                                                        </small>
                                                        <small className="ml-2">
                                                            {challenge.status ===
                                                            'completed' ? (
                                                                <Badge className="badge-info">
                                                                    completed
                                                                </Badge>
                                                            ) : null}
                                                            {challenge.status ===
                                                            'active' ? (
                                                                <Badge className="badge-success">
                                                                    active
                                                                </Badge>
                                                            ) : null}
                                                            {challenge.status ===
                                                            'upcoming' ? (
                                                                <Badge className="badge-warning">
                                                                    upcoming
                                                                </Badge>
                                                            ) : null}
                                                        </small>
                                                    </Col>
                                                    <Col xs="12">
                                                        <p className="text-dark">
                                                            {
                                                                challenge.description
                                                            }
                                                        </p>
                                                    </Col>
                                                </Row>
                                                <Table
                                                    className="align-items-center table-flush text-center"
                                                    responsive
                                                >
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col">
                                                                Rank
                                                            </th>
                                                            <th scope="col">
                                                                Photo
                                                            </th>
                                                            <th scope="col">
                                                                Member
                                                            </th>
                                                            <th scope="col">
                                                                Points
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {challenge.athletes.map(
                                                            (stat, i) => (
                                                                <tr key={i}>
                                                                    <td>
                                                                        {i + 1}
                                                                    </td>
                                                                    <td>
                                                                        <img
                                                                            src={
                                                                                stat.logo
                                                                            }
                                                                            onError={(
                                                                                e
                                                                            ) => {
                                                                                e.target.onerror =
                                                                                    null;
                                                                                e.target.src = require('assets/img/strava/user.png');
                                                                            }}
                                                                            alt="Invalid logo"
                                                                            style={{
                                                                                height: '30px',
                                                                                width: '30px',
                                                                                borderRadius:
                                                                                    '15px',
                                                                            }}
                                                                        />
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            stat.name
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            stat.points
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                            </>
                        ) : (
                            <Col xs="12">
                                <Card className="mt-2">
                                    <CardBody>
                                        Please come back when data is available.
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </Container>
            </>
        );
    }
}

export default connect(mapState, mapDispatch)(ChallengeLeaderboard);
