import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Card,
  CardBody,
  Badge,
  Input,
  Button,
  Modal,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import { uploadFile } from 'store/storage/actions';
import SimpleDropdown from './Dropdown';

export const FileUploader = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [fileType, setFileType] = useState('pdf');
  const [inputUrl, setInputUrl] = useState('');
  const dropdownToggle = () => setDropdownOpen((prevState) => !prevState);
  const [selectedFile, setSelectedFile] = useState(null);
  const [popup, setPopup] = useState(props.isNew);
  const [saving, setSaving] = useState(false);
  const [tmpFileInfo, setTmpFileInfo] = useState({});
  const newFileInfo = { role_id: false, ...props.fileInfo, ...tmpFileInfo };
  const [roleId, setRoleId] = useState(newFileInfo.role_id);

  const event_roles = [
    {
      id: false,
      text: 'None',
    },
    ...useSelector((state) => state.firestore.app_settings.event_roles),
  ];

  const mutateFileInfo = (key, val) => {
    setTmpFileInfo({
      ...tmpFileInfo,
      [key]: val,
    });
  };

  const discardFile = () => {
    props.onChange({ ...props.fileInfo, deleted: true });
    setPopup(false);
  };

  const saveUrl = (url) => {
    props.onChange({
      ...newFileInfo,
      url: url,
      modified_on: new Date(),
    });
    setPopup(false);
    setSaving(false);
  };

  const save = () => {
    if (newFileInfo.title === '') alert('Please enter a name for the file.');
    else {
      setSaving(true);
      if (fileType === 'url') {
        saveUrl(inputUrl);
      } else if (selectedFile) {
        const timestamp = new Date()
          .toLocaleString()
          .replace(/,/gi, '')
          .replace(/[^A-Z0-9]/gi, '_');
        uploadFile(
          'file_uploads/' + timestamp,
          '.' + fileType,
          selectedFile
        ).then((url) => saveUrl(url));
      } else {
        props.onChange(newFileInfo);
        setPopup(false);
        setSaving(false);
      }
    }
  };

  const cancel = () => {
    if (props.onDiscard) {
      if (!newFileInfo.modified_on) {
        props.onDiscard();
      }
    }
    setPopup(false);
  };

  return (
    <>
      <Card className="text-dark px-2 border-0">
        <div className="py-2 d-flex flex-row flex-wrap">
          <div className="flex-grow-1 d-flex flex-column">
            <label className="mr-auto align-self-center text-dark">
              {' '}
              {props.fileInfo.title}{' '}
            </label>
            <div className="d-flex flex-row flex-wrap justify-content-start">
              {props.fileInfo.url ? (
                <Badge color="primary" className="mr-2">
                  Last modified on{' '}
                  {props.fileInfo.modified_on instanceof Date
                    ? props.fileInfo.modified_on.toLocaleString()
                    : props.fileInfo.modified_on.toDate().toLocaleString()}
                </Badge>
              ) : (
                <Badge color="warning" className="mr-2">
                  Not Received
                </Badge>
              )}
              {props.fileInfo.role_id &&
              !event_roles.map((r) => r.id).includes(props.fileInfo.role_id) ? (
                <Badge color="warning" className="mr-2">
                  Invalid role
                </Badge>
              ) : null}
            </div>
          </div>
          <Button
            onClick={() => window.open(props.fileInfo.url, '_blank')}
            hidden={!props.fileInfo.url}
            className="p-2 shadow-none"
            color="white"
          >
            <i className="fas fa-file-download fa-lg" />
          </Button>
          <Button
            className="p-2 shadow-none"
            color="white"
            onClick={() => setPopup(true)}
          >
            <i className="fas fa-ellipsis-h fa-lg" />
          </Button>
        </div>
      </Card>
      <Modal isOpen={popup}>
        <Card className="shadow p-4">
          <CardBody className="p-2 text-dark">
            <div className="d-flex flex-row">
              <h2 className="mr-auto pt-2 text-dark flex-grow-1">
                {'File settings'}
              </h2>
              <Button
                className="btn-outline-danger shadow-none"
                color="danger"
                onClick={() => discardFile()}
              >
                Remove
              </Button>
            </div>
            <h4 className="text-dark"> Name </h4>
            <Input
              className="text-dark mb-2"
              defaultValue={newFileInfo.title}
              onChange={(e) => mutateFileInfo('title', e.target.value)}
            />
            <SimpleDropdown
              list={event_roles}
              labels={event_roles.map((r) => (
                <>
                  <i className={r.icon + r.color + ' mr-2'} />
                  {r.text}
                </>
              ))}
              value={
                'Event role: ' +
                [
                  ...event_roles
                    .filter((r) => r.id === roleId || r.id === false)
                    .map((r) => r.text),
                ].slice(-1)[0]
              }
              onClick={(r) => {
                setRoleId(r.id);
                mutateFileInfo('role_id', r.id);
              }}
            />
            <Dropdown isOpen={dropdownOpen} toggle={dropdownToggle}>
              <DropdownToggle
                className="btn btn-outline-transparent shadow-none"
                color="white"
              >
                File type: {fileType}
                <i
                  className="ni ni-bold-down pl-2 mr--2"
                  style={{ transform: 'translate(0, 3px)' }}
                />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => setFileType('url')}>
                  url
                </DropdownItem>
                <DropdownItem onClick={() => setFileType('pdf')}>
                  pdf
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            {fileType === 'url' && (
              <Input
                className="text-dark mt-2"
                value={inputUrl}
                onChange={(e) => setInputUrl(e.target.value)}
                type="text"
                placeholder="paste url here..."
              />
            )}
            {fileType !== 'url' && (
              <>
                <label
                  className="btn btn-secondary mt-2"
                  htmlFor="newfileuplod"
                >
                  Upload file
                </label>
                <Input
                  type="file"
                  name={newFileInfo.title + '.pdf'}
                  id="newfileuplod"
                  accept=".pdf"
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                  style={{
                    display: 'none',
                  }}
                />
                <label>{selectedFile?.name}</label>
              </>
            )}
            <div className="d-flex flex-row flex-wrap mt-4">
              <Button
                className="mr-auto"
                onClick={(e) => cancel()}
                disabled={saving}
              >
                Cancel
              </Button>
              <Button
                className="ml-auto"
                color="success"
                onClick={(e) => save(e)}
                disabled={saving}
              >
                {saving ? (
                  <Spinner size="sm" color="grey" className="" />
                ) : (
                  'Save'
                )}
              </Button>
            </div>
          </CardBody>
        </Card>
      </Modal>
    </>
  );
};
