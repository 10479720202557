/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// reactstrap components
import { Container } from 'reactstrap';
// core components
import AdminFooter from 'components/Footers/AdminFooter.js';
import Sidebar from 'components/Sidebar/Sidebar.js';

import LoadingLogo from 'assets/img/gifs/loading.gif';

import routes from 'routes.js';

import { connect } from 'react-redux';
const mapState = (state) => state;
const mapDispatch = {};

class Admin extends React.Component {
  componentDidUpdate(e) {
    // document.documentElement.scrollTop = 0;
    // document.scrollingElement.scrollTop = 0;
    // this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        return (
          this.props.firestore.policies &&
          this.props.firestore.policies[prop.policy] && (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          )
        );
      } else {
        return null;
      }
    });
  };
  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: '/admin/index',
            imgSrc: require('assets/img/tdc/logo_black.png'),
            imgAlt: '...',
          }}
        />
        <div className="main-content" ref="mainContent">
          {this.props.firestore.loading ? (
            <div
              style={{
                overflow: 'hidden',
                position: 'absolute',
                top: '0',
                left: '0',
                height: '100vh',
                width: '100%',
                backgroundColor: '#f1f1f1',
                zIndex: -2,
              }}
            >
              <img
                alt="..."
                style={{
                  position: 'absolute',
                  top: '42%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: -1,
                }}
                src={LoadingLogo}
              />
            </div>
          ) : (
            <>
              <Switch>
                {this.getRoutes(routes)}
                <Route
                  component={({ location }) => (
                    <Redirect
                      from="*"
                      to={{
                        ...location,
                        pathname: '/admin/index',
                      }}
                    />
                  )}
                />
              </Switch>
              <Container fluid>
                <AdminFooter />
              </Container>
            </>
          )}
        </div>
      </>
    );
  }
}

export default connect(mapState, mapDispatch)(Admin);
