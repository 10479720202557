import * as firebase from 'firebase/app';
import 'firebase/auth';

/*
 * action types
 */

/*
 * action creators
 */

export function logout() {
  return (dispatch) =>
    firebase
      .auth()
      .signOut()
      .then(() => dispatch({ type: 'USER_LOGOUT' }))
      .catch(function (error) {
        alert(error.message);
      });
}

export function login(email, password) {
  return firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then(() => {
      return firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((user) => {
          if (!user.user.emailVerified) {
            firebase
              .auth()
              .currentUser.sendEmailVerification()
              .catch(function (error) {
                alert(error.message);
              });
          }
        })
        .catch(function (error) {
          alert(
            'Invalid login details. Please check your details and try again.'
          );
        });
    })
    .catch(() => {
      alert('Error logging in. Please try again.');
    });
}

export function register(email, password) {
  return firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then(() => {
      firebase
        .auth()
        .currentUser.sendEmailVerification()
        .catch(function (error) {
          alert(error.message);
        });
    })
    .catch(function (error) {
      alert(error.message);
    });
}

export function resendEmailVerification() {
  return firebase
    .auth()
    .currentUser.sendEmailVerification()
    .then(function () {
      alert('Email verification link sent.');
    })
    .catch(function (error) {
      alert('Failed to send verification link.');
    });
}

export function changeEmailAddress(newEmail) {
  return firebase
    .auth()
    .currentUser.updateEmail(newEmail)
    .then(function () {
      alert('Email Address changed.');
      return true;
    })
    .catch(function (error) {
      alert(`Failed to change email address. ${error.message}`);
      return false;
    });
}

export function resetPassword(email) {
  return firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => alert('Please check your inbox for our password reset email.'))
    .catch(function (error) {
      alert(error.message);
    });
}
