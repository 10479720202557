import {
  SET_ATHLETE,
  ADD_TEAM,
  SET_APP_SETTINGS,
  SET_TRAINING_HUB,
  ADD_EVENT,
  ADD_CHALLENGE,
  SELECT_EVENT,
  SET_LOADING,
  SET_ROLE,
  SET_GROUP,
  ADD_GROUP,
  UPDATE_GROUP,
  SET_ATHLETES,
  ADD_TEAM_TO_GROUP,
  ADD_MEMBER_TO_TEAM,
  REPLACE_ATHLETE,
  REMOVE_MEMBER_FROM_TEAM,
  SET_ROLES,
  REPLACE_ROLE,
  SET_FILES,
  SET_GRASSROOTZ_DATA,
  SET_GRASSROOTZ_FUNDRAISING_LEADERBOARD,
  ADD_EFFORT_CONTRIBUTIONS,
  SET_VIEWING_ATHLETE_FILES,
} from './actions';

// policies are also enforced remotely
// please update the firestore access policy to reflect these policies
export const policies = {
  view_athletes: 'view_athletes',
  view_email_and_mobile: 'view_email_and_mobile',
  edit_training_rides: 'edit_training_rides',
  edit_skill_and_fitness: 'edit_skill_and_fitness',
  view_next_of_kin: 'view_next_of_kin',
  edit_event_roles: 'edit_event_roles',
  delete_athlete: 'delete_athlete',
  edit_training_ride_parameters: 'edit_training_ride_parameters',
  edit_events: 'edit_events',
  view_all_events: 'view_all_events',
  edit_teams: 'edit_teams',
  edit_challenges: 'edit_challenges',
  edit_app_settings: 'edit_app_settings',
  view_power_bi: 'view_power_bi',
  edit_access_roles: 'edit_access_roles',
  auto_approve_training_rides: 'auto_approve_training_rides',
  add_effort_contribution: 'add_effort_contribution',
  view_athlete_files: 'view_athlete_files',
};

export const getPoliciesFromRoles = (roles) => {
  const policies = {};
  for (const r of Object.keys(roles).filter((r) => roles[r])) {
    for (const p of access_policies[r] || []) {
      policies[p] = true;
    }
  }
  return policies;
};

export const access_policies = {
  trainee: [policies['view_athletes']],
  team_leader: [policies['view_athletes'], policies['view_email_and_mobile']],
  ride_leader: [policies['view_athletes'], policies['edit_training_rides']],
  supervisor: [
    policies['view_athletes'],
    policies['view_email_and_mobile'],
    policies['edit_skill_and_fitness'],
    policies['view_next_of_kin'],
    policies['auto_approve_training_rides'],
    policies['view_all_events'],
  ],
  state_manager: [
    policies['view_athletes'],
    policies['view_email_and_mobile'],
    policies['edit_skill_and_fitness'],
    policies['edit_training_rides'],
    policies['edit_event_roles'],
    policies['edit_teams'],
    policies['view_all_events'],
  ],
  administrator: [
    policies['view_athletes'],
    policies['view_email_and_mobile'],
    policies['edit_skill_and_fitness'],
    policies['view_next_of_kin'],
    policies['edit_training_rides'],
    policies['edit_event_roles'],
    policies['edit_events'],
    policies['edit_teams'],
    policies['edit_challenges'],
    policies['view_all_events'],
    policies['view_athlete_files'],
  ],
  tdc_staff: [
    policies['view_athletes'],
    policies['view_email_and_mobile'],
    policies['view_next_of_kin'],
    policies['delete_athlete'],
    policies['edit_event_roles'],
    policies['edit_events'],
    policies['edit_teams'],
    policies['view_all_events'],
    policies['view_athlete_files'],
  ],
  developer: [
    policies['view_athletes'],
    policies['view_email_and_mobile'],
    policies['delete_athlete'],
    policies['edit_event_roles'],
    policies['edit_events'],
    policies['edit_teams'],
    policies['edit_challenges'],
    policies['edit_app_settings'],
    policies['edit_training_ride_parameters'],
    policies['edit_access_roles'],
    policies['view_power_bi'],
    policies['view_all_events'],
    policies['view_athlete_files'],
  ],
  super_admin: [
    policies['view_athletes'],
    policies['view_email_and_mobile'],
    policies['edit_skill_and_fitness'],
    policies['view_next_of_kin'],
    policies['edit_training_rides'],
    policies['delete_athlete'],
    policies['edit_event_roles'],
    policies['edit_events'],
    policies['edit_teams'],
    policies['edit_challenges'],
    policies['edit_app_settings'],
    policies['edit_training_ride_parameters'],
    policies['edit_access_roles'],
    policies['view_power_bi'],
    policies['view_all_events'],
    policies['add_effort_contribution'],
    policies['view_athlete_files'],
  ],
};

export const defaultTeam = {
  name: '',
  logo_url: '',
  members: {},
};

export const defaultGroup = {
  name: '',
  teams: {},
};

export const defaultEvent = {
  id: null,
  name: 'No events available',
  grassrootz_event_id: '',
  grassrootz_campaign_id: '',
  start_date: new Date(),
  end_date: new Date('2999-01-01'),
  challenges: {},
  required_skill: 3,
  required_fitness: 3,
  required_training_rides: 5,
  user_points: 0,
  training_program: '',
  group_data: defaultGroup,
  files: {},
  event_roles: {},
  athlete_files: {},
  viewing_athlete_files: {},
};

export const defaultChallenge = {
  name: '',
  description: '',
  metric: 'distance',
  multiplier: '1',
  start_date: new Date(),
  end_date: new Date(),
  progress: {},
  scope: {
    public: true,
    manual: true,
    private: true,
  },
  activity_types: {
    any: true,
  },
};

export const defaultAthlete = {
  skill_level: 0,
  rider_level: 0,
  training_rides: 0,
  training_hub: 'None',
  exists: false,
  name: 'Welcome rider',
  profile_image: null,
  year_of_birth: null,
  vo2max: null,
  vo2max_fitness_level: 0,
  gender: null,
  athlete_key: '',
  grassrootz_athlete_id: '',
  athlete_state: 'None',
  recently_rode_with: [],
  detected_training_rides: [],
  manual_training_rides: [],
  listTrainingRides: function (history_limit_weeks, additional_rides = []) {
    const min_date = new Date();
    min_date.setDate(min_date.getDate() - history_limit_weeks * 7);
    return [
      ...new Set(
        [
          ...this.detected_training_rides,
          ...this.manual_training_rides,
          ...additional_rides,
        ].filter(
          (date) => date >= min_date.toISOString().split('.').shift() + 'Z'
        )
      ),
    ].sort();
  },
  calcTrainingRides: function (history_limit_weeks) {
    return this.listTrainingRides(history_limit_weeks).length;
  },
  firstname: 'Welcome',
  lastname: 'Rider',
  athlete_created_on_hub_date: {
    toDate: () => new Date(2021, 1, 1),
  },
  mobile: '',
  nok_firstname: '',
  nok_lastname: '',
  nok_mobile: '',
  grassrootz_urls: {},
};

const defaultAppSettings = {
  training_ride_params: {
    ride_history: 16,
    window_radius: 5,
    min_distance: 50000,
    min_segments: 5,
    min_supervisors: 1,
    min_riders: 8,
  },
  event_roles: [],
  training_hubs: [],
  athlete_files: {},
};

const defaultPolicies = {
  default: true,
};

const defaultGrassrootzData = {};

export const initialState = {
  athlete: defaultAthlete,
  events: {},
  groups: {},
  selected_event: defaultEvent,
  app_settings: defaultAppSettings,
  loading: true,
  policies: defaultPolicies,
  athletes: [],
  roles: {},
  athlete_files: {},
  grassrootz_fundraising_leaderboard: {},
};

export function firestoreReducer(state = initialState, action) {
  let new_state = {};
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case SET_ROLE:
      console.warn(action);
      return {
        ...state,
        role: {
          default: true,
          ...action.role,
        },
        policies: {
          ...defaultPolicies,
          ...getPoliciesFromRoles(action.role),
        },
      };
    case SET_ATHLETE:
      return {
        ...state,
        athlete: {
          ...defaultAthlete,
          ...action.athlete,
          exists: true,
          name:
            action.athlete &&
            action.athlete.firstname &&
            action.athlete.lastname
              ? action.athlete.firstname + ' ' + action.athlete.lastname
              : defaultAthlete.name,
          skill_level: action.athlete
            ? action.athlete.skill_level
              ? action.athlete.skill_level
              : action.athlete.self_nominated_rider_level
            : 0,
        },
      };
    case SET_TRAINING_HUB: {
      return {
        ...state,
        athlete: {
          ...state.athlete,
          training_hub: action.hub,
        },
      };
    }
    case SET_APP_SETTINGS:
      return {
        ...state,
        app_settings: {
          ...defaultAppSettings,
          ...action.app_settings,
        },
      };
    case ADD_EVENT:
      if (action.event.deleted) {
        if (action.event.deleted && state.selected_event.id === action.event_id)
          state.selected_event = defaultEvent;
        if (action.id in state.events) delete state.events[action.id];
        return { ...state };
      }
      const new_event = {
        ...defaultEvent,
        ...action.event,
        id: action.id,
        start_date: action.event.start_date
          ? action.event.start_date.toDate()
          : defaultEvent.start_date,
        end_date: action.event.end_date
          ? action.event.end_date.toDate()
          : defaultEvent.end_date,
        group_id: action.event.group
          ? action.event.group.id
          : defaultEvent.group_id,
      };
      if (state.selected_event === defaultEvent)
        state.selected_event = new_event;
      return {
        ...state,
        events: {
          ...state.events,
          [action.id]: new_event,
        },
      };
    case ADD_CHALLENGE:
      if (action.challenge.deleted) return state;
      new_state = {
        ...state,
        events: {
          ...state.events,
          [action.event_id]: {
            ...state.events[action.event_id],
            user_points:
              state.events[action.event_id].user_points +
              (action.challenge.user_points ? action.challenge.user_points : 0),
            challenges: {
              ...state.events[action.event_id].challenges,
              [action.id]: {
                ...defaultChallenge,
                ...action.challenge,
                start_date: action.challenge.start_date
                  ? action.challenge.start_date.toDate()
                  : defaultChallenge.start_date,
                end_date: action.challenge.end_date
                  ? action.challenge.end_date.toDate()
                  : defaultChallenge.end_date,
              },
            },
          },
        },
      };
      if (state.selected_event.id === action.event_id)
        new_state.selected_event = new_state.events[action.event_id];
      return new_state;
    case SET_GRASSROOTZ_DATA:
      return {
        ...state,
        grassrootz_data: {
          ...defaultGrassrootzData,
          ...state.grassrootz_data,
          [action.event_id]: action.data,
        },
      };
    case SET_GRASSROOTZ_FUNDRAISING_LEADERBOARD:
      return {
        ...state,
        grassrootz_fundraising_leaderboard: {
          ...defaultGrassrootzData,
          ...state.grassrootz_fundraising_leaderboard,
          [action.event_id]: action.data,
        },
      };
    case SET_GROUP:
      // if (action.group.deleted) return state;
      new_state = {
        ...state,
        events: {
          ...state.events,
          [action.event_id]: {
            ...state.events[action.event_id],
            group_data: {
              ...defaultGroup,
              ...(action.event_id in state.events
                ? state.events[action.event_id].group_data
                : {}),
              ...action.group,
            },
          },
        },
      };
      if (state.selected_event.id === action.event_id)
        new_state.selected_event = new_state.events[action.event_id];
      return new_state;
    case ADD_TEAM:
      if (action.team.deleted) return state;
      new_state = {
        ...state,
        events: {
          ...state.events,
          [action.event_id]: {
            ...state.events[action.event_id],
            group_data: {
              ...state.events[action.event_id].group_data,
              teams: {
                ...state.events[action.event_id].group_data.teams,
                [action.id]: {
                  ...defaultTeam,
                  ...action.team,
                },
              },
            },
          },
        },
      };
      if (state.selected_event.id === action.event_id)
        new_state.selected_event = new_state.events[action.event_id];
      return new_state;
    case SELECT_EVENT:
      return {
        ...state,
        selected_event: state.events[action.id],
      };
    case ADD_GROUP:
      // if (action.group.deleted) return state;
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.id]: {
            ...defaultGroup,
            ...action.group,
            id: action.id,
          },
        },
      };
    case UPDATE_GROUP:
      // if (action.group.deleted) {
      //   const new_groups = state.groups;
      //   delete new_groups[action.id];
      //   return {
      //     ...state,
      //     groups: {
      //       ...new_groups
      //     }
      //   }
      // }
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.id]: {
            ...defaultGroup,
            ...action.group,
            id: action.id,
          },
        },
      };
    case ADD_TEAM_TO_GROUP:
      if (action.team.deleted) return state;
      // if (!state.groups[action.group_id]) return state;
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.group_id]: {
            ...(state.groups[action.group_id] || {}),
            teams: {
              ...(state.groups[action.group_id]?.teams || {}),
              [action.id]: {
                ...defaultTeam,
                ...action.team,
              },
            },
          },
        },
      };
    case SET_ATHLETES:
      return {
        ...state,
        athletes: action.athletes.map((athlete) => ({
          ...defaultAthlete,
          ...athlete,
        })),
      };
    case SET_ROLES:
      return {
        ...state,
        roles: action.roles,
      };
    case REPLACE_ROLE:
      return {
        ...state,
        roles: {
          ...state.roles,
          [action.id]: action.role,
        },
      };
    case REPLACE_ATHLETE:
      var foundIndex = state.athletes.findIndex(
        (x) => x.athlete_key === action.athlete.athlete_key
      );
      const updated_athletes = state.athletes;
      updated_athletes[foundIndex] = {
        ...defaultAthlete,
        ...action.athlete,
      };
      return {
        ...state,
        athletes: updated_athletes,
      };
    case ADD_MEMBER_TO_TEAM:
      return {
        ...state,
        groups: {
          [action.group_id]: {
            ...state.groups[action.group_id],
            teams: {
              ...state.groups[action.group_id].teams,
              [action.team_id]: {
                ...state.groups[action.group_id].teams[action.team_id],
                [action.member_id]: action.member,
              },
            },
          },
        },
      };
    case REMOVE_MEMBER_FROM_TEAM:
      const new_team = state.groups[action.group_id].teams[action.team_id];
      delete new_team[action.member_id];
      return {
        ...state,
        groups: {
          [action.group_id]: {
            ...state.groups[action.group_id],
            teams: {
              ...state.groups[action.group_id].teams,
              [action.team_id]: {
                ...new_team,
              },
            },
          },
        },
      };
    case SET_FILES:
      return {
        ...state,
        athlete_files: action.athlete_files,
      };
    case SET_VIEWING_ATHLETE_FILES:
      return {
        ...state,
        viewing_athlete_files: action.viewing_athlete_files,
      };
    case ADD_EFFORT_CONTRIBUTIONS:
      return {
        ...state,
        effort_contributions: {
          [action.event_id]: action.data,
        },
      };
    default:
      return state;
  }
}
