/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  CardHeader,
  CardFooter,
  InputGroup,
  Input,
  Table,
  Alert,
  Modal,
} from 'reactstrap';
// core components

import Header from 'components/Headers/Header.js';

import { connect } from 'react-redux';
import {
  generateUid,
  toBase64,
  getGroupTeams,
  getAllGroups,
  getAllAthletes,
  setGroup,
} from 'store/firestore/actions';
import { defaultGroup, defaultTeam } from 'store/firestore/reducers';

const mapState = (state) => state;

const mapDispatch = {
  getGroupTeams,
  getAllGroups,
  getAllAthletes,
  setGroup,
};

class ManageGroups extends React.Component {
  constructor(props) {
    super(props);
    props.getAllGroups();
    this.state = {
      selected_group_key: null,
      selected_team_key: null,
      selected_group: {},
      selected_team: {},
      display_add_members_modal: false,
      add_members_text_area: '',
      newGroup: () => {
        this.setState({
          selected_group: defaultGroup,
          selected_group_key: generateUid(),
        });
      },
      newTeam: () => {
        this.props.getAllAthletes();
        this.setState({
          selected_team: defaultTeam,
          selected_team_key: generateUid(),
        });
      },
      selectGroup: async (key) => {
        if (key) await this.props.getGroupTeams(key);
        this.setState({
          selected_group: key ? this.props.firestore.groups[key] : {},
          selected_group_key: key ? key : null,
        });
      },
      saveGroup: () => {
        if (this.state.selected_group.name === '') {
          alert('Please enter a group name');
          return null;
        }
        this.props.setGroup(
          this.state.selected_group_key,
          this.state.selected_group
        );
        this.state.selectGroup(null);
      },
      saveTeam: () => {
        if (this.state.selected_team.name === '') {
          alert('Please name your team');
          return null;
        }
        if (this.state.selected_team.logo_url === '') {
          alert('Please upload a team logo');
          return null;
        }
        this.setState({
          selected_group: {
            ...this.state.selected_group,
            teams: {
              ...this.state.selected_group.teams,
              [this.state.selected_team_key]: this.state.selected_team,
            },
          },
        });
        this.state.selectTeam(null);
      },
      selectTeam: (key) => {
        // if (key) this.props.getAllAthletes();
        this.setState({
          selected_team: key ? this.state.selected_group.teams[key] : {},
          selected_team_key: key ? key : null,
        });
      },
      closeAddMembersModal: () => {
        this.setState({
          display_add_members_modal: false,
          add_members_text_area: '',
        });
      },
      addMembers: () => {
        const available_athletes = this.props.firestore.athletes
          .filter(
            (athlete) =>
              !Object.keys(this.state.selected_group.teams)
                .map((team) =>
                  Object.keys(this.state.selected_group.teams[team].members)
                )
                .flat()
                .includes(athlete.athlete_key) &&
              !Object.keys(this.state.selected_team.members).includes(
                athlete.athlete_key
              )
          )
          .filter(
            (athlete) =>
              athlete.firstname && athlete.lastname && athlete.profile_medium
          );
        const names = this.state.add_members_text_area
          .toLowerCase()
          .split(/\r?\n/)
          .map((name) => name.trim());
        let team = this.state.selected_team;
        available_athletes.map((athlete) => {
          if (
            names.includes(
              (athlete.firstname + ' ' + athlete.lastname).toLowerCase()
            )
          ) {
            team = {
              ...team,
              members: {
                ...team.members,
                [athlete.athlete_key]: {
                  name:
                    (athlete.firstname !== '' ? athlete.firstname + ' ' : '') +
                    athlete.lastname,
                  profile_image: athlete.profile_medium,
                },
              },
            };
          }
          return null;
        });
        this.setState({ selected_team: team });
        this.state.closeAddMembersModal();
      },
      addAthlete: (athlete) => {
        this.setState({
          selected_team: {
            ...this.state.selected_team,
            members: {
              ...this.state.selected_team.members,
              [athlete.athlete_key]: {
                name:
                  (athlete.firstname !== '' ? athlete.firstname + ' ' : '') +
                  athlete.lastname,
                profile_image: athlete.profile_medium,
              },
            },
          },
        });
      },
    };
  }

  render() {
    return (
      <>
        <Header title="Manage Groups" />
        {/* Page content */}
        <Container className="" style={{ maxWidth: '1200px' }} fluid>
          <Row>
            {this.state.selected_group_key && this.state.selected_team_key ? (
              <Col xs="12" className="pt-2">
                <Card className="shadow">
                  <CardHeader className="d-flex">
                    <h2 className="mr-auto pt-2 text-dark">Manage Team</h2>
                    <Button
                      color="danger"
                      className="btn-outline-danger shadow-none"
                      onClick={(e) => {
                        this.setState({
                          selected_group: {
                            ...this.state.selected_group,
                            teams: {
                              ...this.state.selected_group.teams,
                              [this.state.selected_team_key]: {
                                ...this.state.selected_team,
                                deleted: true,
                              },
                            },
                          },
                        });
                        this.state.selectTeam(null);
                      }}
                    >
                      Archive
                    </Button>
                  </CardHeader>
                  <CardBody>
                    <Button onClick={(e) => this.state.selectTeam(null)}>
                      Cancel
                    </Button>
                    <Button
                      color="success"
                      className="float-right"
                      onClick={(e) => this.state.saveTeam()}
                    >
                      Save
                    </Button>
                    <h4 className="text-dark mt-4"> Team Name </h4>
                    <InputGroup className="mb-4">
                      <Input
                        className="text-dark p-2"
                        value={this.state.selected_team.name}
                        onChange={(e) =>
                          this.setState({
                            selected_team: {
                              ...this.state.selected_team,
                              name: e.target.value,
                            },
                          })
                        }
                      ></Input>
                    </InputGroup>
                    <Row className="mb-4">
                      <Col xs="6">
                        <h4 className="text-dark"> Logo </h4>
                        <img
                          style={{ maxWidth: '100%', maxHeight: '100px' }}
                          src={this.state.selected_team.logo_url}
                          alt="Invalid logo"
                        />
                      </Col>
                      <Col xs="6">
                        <InputGroup className="mb-4 mt-4 my-auto mr-auto">
                          <Input
                            type="file"
                            accept="image/*"
                            className="text-dark p-2"
                            onChange={(e) => {
                              const image = Array.from(e.target.files)[0];
                              toBase64(image).then((base64url) =>
                                this.setState({
                                  selected_team: {
                                    ...this.state.selected_team,
                                    logo_url: base64url,
                                  },
                                })
                              );
                            }}
                          ></Input>
                        </InputGroup>
                      </Col>
                    </Row>
                    <h4 className="text-dark" hidden>
                      {' '}
                      Team Members{' '}
                    </h4>
                    <Button
                      hidden
                      className="my-3"
                      onClick={() =>
                        this.setState({ display_add_members_modal: true })
                      }
                    >
                      Add members by list
                    </Button>
                    <Card className="mb-4" hidden>
                      <Table
                        className="align-items-center table-flush text-center"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Photo</th>
                            <th scope="col">Member</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.firestore.athletes
                            .filter((athlete) =>
                              Object.keys(
                                this.state.selected_team.members
                              ).includes(athlete.athlete_key)
                            )
                            .filter(
                              (athlete) =>
                                athlete.firstname &&
                                athlete.lastname &&
                                athlete.profile_medium
                            )
                            .sort((a, b) =>
                              a.firstname
                                .toLowerCase()
                                .concat(a.lastname.toLowerCase()) >
                              b.firstname
                                .toLowerCase()
                                .concat(b.lastname.toLowerCase())
                                ? 1
                                : -1
                            )
                            .map((athlete, i) => (
                              <tr key={i}>
                                <td>
                                  <img
                                    src={athlete.profile_medium}
                                    alt="Invalid logo"
                                    style={{
                                      height: '30px',
                                      width: '30px',
                                      borderRadius: '15px',
                                    }}
                                  />
                                </td>
                                <td>
                                  {(athlete.firstname !== ''
                                    ? athlete.firstname + ' '
                                    : '') + athlete.lastname}
                                </td>
                                <td>
                                  <Button
                                    className="btn-outline-danger shadow-none float-right"
                                    color="danger"
                                    onClick={(e) => {
                                      const new_team = this.state.selected_team;
                                      delete this.state.selected_team.members[
                                        athlete.athlete_key
                                      ];
                                      this.setState({
                                        selected_team: new_team,
                                      });
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </Card>
                    <h4 className="text-dark" hidden>
                      {' '}
                      Athletes without a team{' '}
                    </h4>
                    <Card hidden>
                      <Table
                        className="align-items-center table-flush text-center"
                        responsive
                      >
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Photo</th>
                            <th scope="col">Member</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.props.firestore.athletes
                            .filter(
                              (athlete) =>
                                !Object.keys(this.state.selected_group.teams)
                                  .map((team) =>
                                    Object.keys(
                                      this.state.selected_group.teams[team]
                                        .members
                                    )
                                  )
                                  .flat()
                                  .includes(athlete.athlete_key) &&
                                !Object.keys(
                                  this.state.selected_team.members
                                ).includes(athlete.athlete_key)
                            )
                            .filter(
                              (athlete) =>
                                athlete.firstname &&
                                athlete.lastname &&
                                athlete.profile_medium
                            )
                            .sort((a, b) =>
                              a.firstname
                                .toLowerCase()
                                .concat(a.lastname.toLowerCase()) >
                              b.firstname
                                .toLowerCase()
                                .concat(b.lastname.toLowerCase())
                                ? 1
                                : -1
                            )
                            .map((athlete, i) => (
                              <tr key={i}>
                                <td>
                                  <img
                                    src={athlete.profile_medium}
                                    alt="Invalid logo"
                                    style={{
                                      height: '30px',
                                      width: '30px',
                                      borderRadius: '15px',
                                    }}
                                  />
                                </td>
                                <td>
                                  {(athlete.firstname !== ''
                                    ? athlete.firstname + ' '
                                    : '') + athlete.lastname}
                                </td>
                                <td>
                                  <Button
                                    className="float-right shadow-none"
                                    onClick={(e) =>
                                      this.state.addAthlete(athlete)
                                    }
                                  >
                                    Add
                                  </Button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </Card>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              <>
                {this.state.selected_group_key &&
                !this.state.selected_team_key ? (
                  <Col xs="12" className="pt-2">
                    <Card className="shadow">
                      <CardHeader className="d-flex">
                        <Alert color="success">
                          <h4 className="alert-heading">Managing Group</h4>
                          <p>
                            Within the {`${this.state.selected_group.name}`}
                            group, you must create teams. We recommend creating
                            teams such as:
                            <b>
                              Rider and Support Team, Walker and Support Team
                            </b>
                            . For signature tours you could structure teams such
                            as: <b>Westpac Team, Commbank Team</b>, etc.
                          </p>
                          <p>
                            <b>
                              Do not create one team with the tour name. This
                              will create confusion.
                            </b>
                          </p>

                          <Button
                            color="secondary"
                            className="shadow-none"
                            onClick={(e) => {
                              this.props.setGroup(
                                this.state.selected_group_key,
                                {
                                  ...this.state.selected_group,
                                  deleted: true,
                                }
                              );
                              this.state.selectGroup(null);
                            }}
                          >
                            Archive{' '}
                            <i
                              className="fa fa-trash ml-1"
                              aria-hidden="true"
                            ></i>
                          </Button>
                        </Alert>
                      </CardHeader>
                      <CardBody>
                        <Button onClick={(e) => this.state.selectGroup(null)}>
                          Cancel
                        </Button>
                        <Button
                          color="success"
                          className="float-right"
                          onClick={(e) => this.state.saveGroup()}
                        >
                          Save
                        </Button>
                        <h4 className="text-dark mt-4"> Group Name </h4>
                        <InputGroup className="mb-4">
                          <Input
                            className="text-dark p-2"
                            value={this.state.selected_group.name}
                            onChange={(e) =>
                              this.setState({
                                selected_group: {
                                  ...this.state.selected_group,
                                  name: e.target.value,
                                },
                              })
                            }
                          ></Input>
                        </InputGroup>

                        <div className="d-flex mt-5 mb-4">
                          <h2 className="text-dark mr-auto pt-2">Teams</h2>
                          <Button
                            onClick={(e) => this.state.newTeam()}
                            className="btn btn-outline-transparent px-3"
                            color="success"
                            style={{ borderRadius: '50%' }}
                          >
                            <i className="fas fa-plus"></i>
                          </Button>
                        </div>
                        {Object.keys(this.state.selected_group.teams)
                          .filter(
                            (key) =>
                              this.state.selected_group.teams[key].deleted !==
                              true
                          )
                          .sort(
                            (a, b) =>
                              this.state.selected_group.teams[a].name >
                              this.state.selected_group.teams[b].name
                          )
                          .map((key, i) => (
                            <Card key={i} className="p-4 mb-4">
                              <div className="d-flex align-middle">
                                <img
                                  className="my-auto mr-3 pt-2 "
                                  style={{
                                    maxHeight: '50px',
                                    maxWidth: '50px',
                                  }}
                                  src={
                                    this.state.selected_group.teams[key]
                                      .logo_url
                                  }
                                  alt="Invalid logo"
                                />
                                <p className="mr-auto my-auto pt-2 pr-2 text-dark">
                                  {this.state.selected_group.teams[key].name}
                                </p>
                                <Button
                                  onClick={(e) => this.state.selectTeam(key)}
                                  className="btn btn-outline-transparent float-right"
                                  color="white"
                                >
                                  Manage Team
                                </Button>
                              </div>
                            </Card>
                          ))}
                      </CardBody>
                    </Card>
                  </Col>
                ) : (
                  <Col xs="12" className="pt-2">
                    <Card className="shadow">
                      <CardBody>
                        <div className="d-flex align-middle py-4">
                          <Alert color="success">
                            <h4 className="alert-heading">Creating groups</h4>
                            <p>
                              Before creating an event you must create a group.
                              Each group is composed of teams, each team can
                              contain team members. No person can belong to more
                              than one team. To create a new group, click the
                              button below.
                            </p>
                            <Button
                              onClick={(e) => this.state.newGroup()}
                              className="m-auto btn btn-outline-transparent float-right"
                              color="white"
                            >
                              Create a new group
                            </Button>
                          </Alert>
                        </div>
                        {Object.keys(this.props.firestore.groups)
                          .filter(
                            (key) => !this.props.firestore.groups[key].deleted
                          )
                          .map((key, i) => (
                            <Card key={i} className="p-4 mb-4">
                              <div className="d-flex align-middle">
                                <p className="mr-auto my-auto pr-2 text-dark">
                                  {this.props.firestore.groups[key].name}
                                </p>
                                <Button
                                  onClick={(e) => this.state.selectGroup(key)}
                                  className="btn btn-outline-transparent float-right"
                                  color="white"
                                >
                                  Manage
                                </Button>
                              </div>
                            </Card>
                          ))}
                      </CardBody>
                    </Card>
                  </Col>
                )}
              </>
            )}
          </Row>
        </Container>
        <Modal
          isOpen={this.state.display_add_members_modal}
          toggle={() => this.state.closeAddMembersModal()}
        >
          <Card style={{ minHeight: 'calc(100vh - 50px)' }}>
            <CardHeader>Add members by list</CardHeader>
            <CardBody className="d-flex" style={{ height: '100%' }}>
              <Input
                type="textarea"
                value={this.state.add_members_text_area}
                onChange={(e) =>
                  this.setState({ add_members_text_area: e.target.value })
                }
              />
            </CardBody>
            <CardFooter>
              <Button onClick={() => this.state.closeAddMembersModal()}>
                Cancel
              </Button>
              <Button
                color="success"
                className="float-right"
                onClick={() => this.state.addMembers()}
              >
                Submit
              </Button>
            </CardFooter>
          </Card>
        </Modal>
      </>
    );
  }
}

export default connect(mapState, mapDispatch)(ManageGroups);
